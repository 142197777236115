import {
  ActionPopUpSingleButton,
  IStyles,
  Loader,
  PasswordInputField,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  ProfileCreationModeEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { PasswordTypeEnum } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  ENTER_UID,
  REGISTRATION,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../routeHandling/RoutesNomenclature';
import { useAuthentication } from '../../../utils/customHooks';
import { ProfileVerifyStatusPopup } from '../SelfRegistration/components/ProfileVerifyStatusPopup';
import {
  setSelfRegistrationData,
  setToastInfo,
  setUserInfo,
} from '../reducer/auth.slice';
const styles: IStyles = {
  root: {
    height: { md: '100%' },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(30), md: pxTovW(57) },
    justifyContent: { xs: 'start', md: 'center' },
  },
  heading: {
    textAlign: { xs: 'start', md: 'center' },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(91), md: pxTovW(42) },
  },
  Passwordcontainer: {
    display: 'flex',
    flexDirection: 'column',
    // gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  buttonContainer: {
    width: { xs: '100%', md: pxTovW(517) },
    display: 'flex',
    alignSelf: 'center',
  },
};
export const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordRef = useRef<any | null>(null);

  const {
    user_validation_info,
    self_registration_flow,
    self_registration_data,
    school_data,
  } = deserify(useAppSelector((state) => state.auth));
  const { setSelectedFunction } = useGlobalContext();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [ErrorMessage, setErrorMessage] = useState<string | null>(null);
  const [actionPopupState, setActionPopupState] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useAuthentication();
  const [adminReminderPopup, setAdminReminderPopup] = useState(false);
  const { permissionGranted, requestPermission } =
    useNotificationPermission('teacher');
  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler,
  } = useCommonServiceClientContext();

  const confirmClickHandler = async () => {
    if (confirmPassword !== newPassword) {
      setPasswordError(true);
      return;
    }
    if (
      user_validation_info?.profileId ||
      self_registration_data?.user_profile_id
    ) {
      let accessKey;
      try {
        setLoading('loading');

        if (!self_registration_flow) {
          const profileVerification =
            await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
              {
                teacherProfileId: user_validation_info?.profileId,
                isProfileVerified: true,
              }
            );
          // console.log('Profile Verification status', profileVerification);
          if (profileVerification.data) {
            dispatch(setUserInfo(profileVerification.data));
            accessKey =
              profileVerification.data.schoolDetails[0]?.schoolId.toString()
                ? 'school-' +
                  profileVerification.data.schoolDetails[0].schoolId.toString()
                : 'geneo';
          } else {
            throw new Error('Profile Verification failed');
          }
        } else {
          accessKey =
            self_registration_data?.profile_verification_data?.schoolDetails[0]?.schoolId.toString()
              ? 'school-' +
                self_registration_data?.profile_verification_data?.schoolDetails[0].schoolId.toString()
              : 'geneo';
          // accessKey = self_registration_data?.access_key || '';
        }

        const settingPassword =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updatePassword({
            profileId:
              self_registration_flow && self_registration_data?.user_profile_id
                ? BigInt(self_registration_data?.user_profile_id)
                : user_validation_info?.profileId,
            newPassword: newPassword,
            role: self_registration_flow
              ? self_registration_data?.role
              : ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            passwordType: PasswordTypeEnum.PASSWORD_TYPE_SET,
          });

        if (settingPassword) {
          if (!self_registration_flow) {
            setLocalStorage(
              'userId',
              user_validation_info?.profileId.toString()
            );
            setLocalStorage('role', 'teacher');
            setLocalStorage('auth', 'true');

            await storeOfflineAccessKeyInCache(accessKey);
          }
          dispatch(
            setToastInfo({
              label: 'Password Updated Successfully',
              variant: 'success',
              open: true,
            })
          );
          if (settingPassword.activeSessionId) {
            setLocalStorage(
              'activeSession',
              settingPassword.activeSessionId.toString()
            );
            const currentTime = new Date().getTime();
            setLocalStorage('lastUpdatedTime', String(currentTime));
          }

          if (self_registration_flow) {
            // setAdminReminderPopup(true);
            // navigate(VERIFY_DETAILS);

            //! API Remind Admin
            try {
              if (self_registration_data?.user_profile_id) {
                const reminderToAdminResponse =
                  await UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler.sendReminderToAdmin(
                    {
                      teacherProfileId: BigInt(
                        self_registration_data.user_profile_id
                      ),
                      schoolId: school_data?.schoolId,
                    }
                  );

                if (reminderToAdminResponse.status === 200) {
                  dispatch(
                    setSelfRegistrationData({
                      ...self_registration_data,
                      last_reminder_sent: new Date(),
                    })
                  );

                  setAdminReminderPopup(true);
                }
              }
            } catch (error) {
              console.log('error:', error);
            }
          } else if (
            user_validation_info?.profileCreationMode ===
            ProfileCreationModeEnum.SELF_REGISTRATION
          ) {
            navigate(VERIFY_EMAIL);
          } else navigate(VERIFY_PHONE);
          await requestPermission();
        } else {
          throw new Error('Failed to set password please try again');
        }

        setLoading('completed');
      } catch (err) {
        console.log(err);
        setLoading('error');
        if (err instanceof Error) {
          dispatch(
            setToastInfo({
              label: `${err.message.replace(/\[.*?\]/g, '')}`,
              variant: 'error',
              open: true,
            })
          );
        }
        // console.log(error);
      }
    } else {
      navigate(REGISTRATION);
    }
  };

  const backButtonClick = async () => {
    if (self_registration_data) navigate(ENTER_UID);
    else navigate(-1);
  };

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1" paddingTop={1}>
          Protect Your Account
        </Typography>
        <Typography
          variant="h1"
          fontWeight={'bold'}
          color={'primary.main'}
          paddingTop={2}
        >
          {' '}
          Set a New Password
        </Typography>
      </Box>
      <Box sx={styles.body}>
        <Box sx={styles.Passwordcontainer}>
          <PasswordInputField
            topLabel="New Password"
            value={newPassword}
            onChange={(e) => {
              // console.log(e);
              setPasswordError(false);
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setNewPassword(e.target.value);
              }
            }}
            type="password"
            // helperTextvariant="error"
            // helperText={
            //   confirmPassword !== newPassword
            //     ? 'new password and confirm password must be same'
            //     : undefined
            // }
            onEnter={() => {
              if (passwordRef.current) {
                // console.log('hii');
                passwordRef.current.focus();
              }
            }}
          />
          <PasswordInputField
            topLabel="Confirm Password"
            value={confirmPassword}
            onChange={(e) => {
              setPasswordError(false);
              if (e.target.value !== ' ' && !e.target.value.includes(' ')) {
                setConfirmPassword(e.target.value);
              }
            }}
            type="password"
            helperTextvariant="error"
            helperText={passwordError ? 'Passwords does not match' : undefined}
            onEnter={confirmClickHandler}
            ref={passwordRef}
          />
        </Box>
        <Box sx={styles.buttonContainer}>
          <PrimaryButton
            ref={passwordRef}
            fullWidth
            onClick={confirmClickHandler}
            disabled={newPassword === '' || confirmPassword === ''}
          >
            <Typography variant="h2" sx={{ color: 'common.white' }}>
              Create Password
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
      <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName="costumer-service"
        popupText="Profile verification failed ! Please Contact Your School Admin for Registration"
        handleClose={() => setActionPopupState(false)}
        ctaHandler={() => setActionPopupState(false)}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      />

      <ProfileVerifyStatusPopup
        modalState={adminReminderPopup}
        closeModalState={(val) => {
          setAdminReminderPopup(val);
          navigate(ENTER_UID);
        }}
        profileId={self_registration_data?.user_profile_id || ''}
      />
    </Box>
  );
};
