import {
  IStyles,
  ImageWrapper,
  OnlineStatusTag,
  RemoteNumber,
  SquareImageAvatar,
  deserify,
  getLocalStorage,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useConnectedClassContext } from '../../../../app/Context/ConnectedClassContextProvider';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import { findClassSubjectInfoFromClassSection } from '../../../../utils/functions';

const styles: IStyles = {
  root: {
    width: { xs: '90vw', md: '100%' },
    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: {
      xs: `0px 0px ${pxToRem(11)} #00000029`,
      md: `0px 0px ${pxTovW(11)} #00000029`,
    },
    padding: {
      xs: `${pxToRem(12)} ${pxToRem(14)} ${pxToRem(12)} ${pxToRem(14)}`,
      md: `${pxTovW(0)} ${pxTovW(20)} ${pxTovW(20)} ${pxTovW(20)}`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: { md: pxTovW(566) },
    '&  > :nth-last-child(-n+1) ': {
      borderBottom: 'none',
    },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  marksWrapper: {
    padding: {
      xs: `${pxToRem(5)} ${pxToRem(13)}`,
      md: `${pxTovW(7)} ${pxTovW(11)}`,
    },
    borderRadius: {
      xs: pxToRem(16),
      md: pxTovW(35),
    },
    border: '1px solid #007CDC',
    marginLeft: '10px',
  },
  marks: {
    fontWeight: 700,
    color: '#007CDC',
  },
};

export const AttendeceList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const teacher_id = getLocalStorage('userId');
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const { subject_id } = useParams();
  const { students_data } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const class_id =
    new URLSearchParams(location.search).get('classId') || undefined;
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { baseStationData } = useConnectedClassContext();
  console.log('baseStationData:', baseStationData);

  const class_subject_info =
    deserify(
      useAppSelector((state) => state.homeDashboard.class_subject_info)
    ) ||
    findClassSubjectInfoFromClassSection({
      userInfo: user_info,
      classId: Number(class_id),
      sectionId: Number(sectionId),
      subjectId: Number(subject_id),
    });
  // useEffect(() => {
  //   getClassStudentList();
  // }, []);

  // const getClassStudentList = async () => {
  //   try {
  //     const response =
  //       await LmsConnectedClassAPIServiceV1Client.getClassStudentList({
  //         teacherId: teacher_id,
  //         classId: class_subject_info?.classId,
  //         sectionId: class_subject_info?.sectionId,
  //       });
  //     // console.log('getBaseStationConnectionStatus response', response);
  //     if (response.status === 200) {
  //       const list = response.studentListData?.studentClickerData || [];
  //       dispatch(setStudentsData(list));
  //     }
  //   } catch (error: any) {
  //     console.log(error.message);
  //   }
  // };

  const checkOnlineStatus = (clickerSerialNo: string) => {
    // baseStationData.keypads.keypadSn

    let returnData = false;
    baseStationData?.keyPads?.forEach((keypad: any) => {
      if (keypad.keypadSn === clickerSerialNo) {
        returnData = keypad.isOnline;
      }
    });

    return returnData ? 'online' : 'offline';
  };

  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: { xs: pxToRem(12), md: pxTovW(21) },
          paddingTop: { md: pxTovW(21) },
          borderBottom: '1px solid lightGrey',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: pxToRem(7), md: pxTovW(9) },
            position: 'relative',
          }}
        >
          <Typography variant="h3" fontWeight="bold">
            Names
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(11), md: pxTovW(20) },
            }}
          >
            {/* <Typography variant="h3" fontWeight="bold">
              Status
            </Typography> */}
            <Typography variant="h3" fontWeight="bold">
              Remote
            </Typography>
          </Box>
        </Box>
      </Box>
      {students_data?.map((elem, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: { xs: pxToRem(15), md: pxTovW(21) },
            paddingTop: { xs: pxToRem(15), md: pxTovW(21) },
            borderBottom: '1px solid lightGrey',
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: '80%', md: '70%' },
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(10), md: pxTovW(25), lg: pxTovW(12) },
            }}
          >
            {/* <ImageWrapper
              parentFolder="tempAssets"
              type="png"
              // name={elem.image}
              name={'user2'}
              styles={{
                height: { xs: pxToRem(36), md: pxTovW(48) },
                width: { xs: pxToRem(36), md: pxTovW(48) },
              }}
            /> */}

            <SquareImageAvatar
              size="small"
              name={elem.studentName}
              online={
                checkOnlineStatus(elem.clickerSerialNo) === 'online'
                  ? true
                  : false
              }
            />
            <Typography
              variant="cardText"
              sx={{
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxHeight: pxToRem(40),
                maxWidth: '90%',
              }}
            >
              {elem.studentName}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: { xs: pxToRem(5), md: pxTovW(5) } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: { xs: pxToRem(35), md: pxTovW(20) },
              }}
            >
              {/* <OnlineStatusTag
                status={checkOnlineStatus(elem.clickerSerialNo)}
              /> */}
              <RemoteNumber number={index + 1} size="small" />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
interface IPopupProps {
  open: boolean;
  handleClose?: () => void;
}
export const AttendencePopup = (props: IPopupProps) => {
  const { open, handleClose } = props;
  return (
    <Modal
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight: '90%',
          width: '90vw',
          gap: pxToRem(20),
          // backgroundColor: 'red',
          border: 'none',
        }}
      >
        <AttendeceList />
        <Box
          sx={{
            height: pxToRem(35),
            width: pxToRem(35),
            borderRadius: '50%',
          }}
          onClick={handleClose}
        >
          <ImageWrapper
            name="close2"
            parentFolder="icons"
            type="png"
            styles={{ height: pxToRem(35), width: pxToRem(35) }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
