import {
  ConnectedStudentsInfoBadge,
  IStyles,
  ImageWrapper,
  OnlineStatusTag,
  PrimaryButton,
  RemoteNumber,
  SquareImageAvatar,
  StepsBadge,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEACHING_FLOW } from '../../../routeHandling/RoutesNomenclature';
import { Steps } from './components/Steps';
const styles: IStyles = {
  root: {
    width: '100%',
    height: 'max-content',
    maxHeight: '90vh',
    display: { xs: 'unset', md: 'flex' },
    overflowY: 'hidden',
    // backgroundColor: '#f9f9f9',
    // backgroundColor: 'red',
    // '& > div': { backgroundColor: 'red' },
  },
  leftPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', md: pxTovW(960) },
    // height: { md: '97vh', lg: pxTovW(1099) },
    // justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: { xs: '#FDFBFB', md: '#F9F9F9' },
    // height: { xs: '100%', md: pxTovW(1099) },
    // height: 'max-content',
    overflowY: 'auto',
  },
  middleBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: { xs: pxTovW(0), md: pxTovW(34) },
    width: { xs: pxToRem(360), md: pxTovW(778) },
    // height: { xs: pxToRem(46), md: pxTovW(902) },
    gap: { md: pxTovW(22) },
    // backgroundColor: 'red',
  },
  stepsBox: {
    display: { xs: 'none', md: 'flex' },
    flexDirection: 'column',
    gap: { md: pxTovW(25) },
  },
  stepBox: {
    display: 'flex',
    gap: { md: pxTovW(10) },
  },
  stepsBadge: {
    width: { xs: pxToRem(121), md: pxTovW(141) },
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: { xs: '100%', md: pxTovW(960) },
    // height: { xs: '100%', md: '100vh' },
    alignItems: 'center',
    // background: '#FDFBFB',
  },
  cardContainer: {
    boxSizing: 'border-box',
    width: { xs: '100%', md: pxTovW(658) },
    // height: { xs: '100%', md: '100vh' },
    maxHeight: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(15), md: pxTovW(30) },
    overflowY: 'scroll',
    alignItems: 'center',
    marginBottom: { xs: pxToRem(65), md: 0 },
    paddingY: { xs: pxToRem(15), md: pxTovW(30) },
  },
  card: {
    boxSizing: 'border-box',
    width: { xs: '90%', md: pxTovW(627) },
    height: { xs: pxToRem(65), md: pxTovW(131) },
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    boxShadow: '0px 0px 9px #00000021',
    alignItems: 'center',
    padding: { xs: pxToRem(9), md: pxTovW(18) },
  },
  cardAvatarBox: {
    width: { xs: '80%', md: '80%' },
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
};
export const StartClassroom = () => {
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [openstepsPopup, setOpenStepsPopup] = useState(false);
  const { subject_id, topic_id, lesson_id } = useParams();
  const navigate = useNavigate();
  return (
    <Box sx={styles.root}>
      <Box sx={styles.leftPanel}>
        <Box sx={styles.middleBox}>
          {largeScreen ? (
            <Typography variant="g2">
              Follow these steps on your computer or mobile device for the best
              connected classroom experience
            </Typography>
          ) : (
            <Box>
              <Typography
                variant="h1"
                textAlign={'center'}
                marginBottom={{ xs: pxToRem(20) }}
              >
                Follow These Steps On Your Computer Or Mobile Device
              </Typography>
              <Box
                sx={{
                  marginX: 'auto',
                  bottom: { xs: pxToRem(10), md: '100%' },
                  width: { xs: pxToRem(321), md: 'max-content' },
                  // backgroundColor: 'red',
                }}
              >
                <PrimaryButton
                  onClick={() => {
                    setOpenStepsPopup(true);
                  }}
                >
                  Follow the Steps
                </PrimaryButton>
              </Box>
              <Steps
                open={openstepsPopup}
                handleClose={() => {
                  setOpenStepsPopup(false);
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              marginX: 'auto',
              marginTop: { xs: pxToRem(25), md: pxTovW(25) },
              marginBottom: { xs: pxToRem(9), md: pxTovW(15) },
            }}
          >
            <ConnectedStudentsInfoBadge
              connectedStudents={30}
              totalStudents={40}
            />
          </Box>
          <Box sx={styles.stepsBox}>
            <Box sx={styles.stepBox}>
              <Box sx={styles.stepsBadge}>
                <StepsBadge
                  number={1}
                  backgroundColor="#FFF4CA"
                  bordercolor="#F8C807"
                />
              </Box>
              <Box>
                <Typography variant="h3">
                  To check if a teacher has joined the connected classroom,
                </Typography>
                <Typography
                  fontSize={pxTovW(26)}
                  fontStyle={'poppins'}
                  fontWeight={'medium'}
                >
                  Turn ‘’ON” your receiver.
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.stepBox}>
              <Box sx={styles.stepsBadge}>
                <StepsBadge
                  number={2}
                  backgroundColor="#E8F5FF"
                  bordercolor="#007CDC"
                />
              </Box>
              <Box>
                <Typography variant="h3">
                  To check if all the students have their own clicker,
                </Typography>
                <Typography variant="h2">
                  “Distribute” clickers to all the students correctly.
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.stepBox}>
              <Box sx={styles.stepsBadge}>
                <StepsBadge
                  number={3}
                  backgroundColor="#FFE4E4"
                  bordercolor="#F54040"
                />
              </Box>
              <Box>
                <Typography variant="h3">
                  To let your students join the class,
                </Typography>
                <Typography variant="h2">
                  Press ‘’OK” on the clicker to join the class.
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.stepBox}>
              <Box sx={styles.stepsBadge}>
                <StepsBadge
                  number={4}
                  backgroundColor="#E7FDF1"
                  bordercolor="#0AA34F"
                />
              </Box>
              <Box>
                <Typography variant="h3">
                  To give correct answers with clickers,
                </Typography>
                <Typography variant="h2">
                  Please ‘’SELECT” the correct answer from“ A,B,C,D”.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            bottom: { xs: pxToRem(10), md: 0 },
            position: { xs: 'fixed', md: 'unset' },
            width: { xs: '90vw', md: 'max-content' },
            // paddingBottom: { xs: 0, md: pxTovW(20) },
            zIndex: '1000',
          }}
        >
          <PrimaryButton
            onClick={() =>
              navigate(
                `${TEACHING_FLOW}/${subject_id}/${topic_id}/${lesson_id}/connectedClass=true`
              )
            }
          >
            <Typography variant="h3" color={'common.white'}>
              START CONNECTED
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
      <Box sx={styles.rightPanel}>
        <Typography
          variant="h2"
          marginTop={{ xs: pxToRem(16), md: pxTovW(84) }}
        >
          Join or Connected Students
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyItems: 'center',
            gap: { xs: pxToRem(8), md: pxTovW(18) },
            marginTop: { xs: pxToRem(11), md: pxTovW(10) },
            marginBottom: { xs: pxToRem(10), md: pxTovW(20) },
          }}
        >
          <ImageWrapper
            name="science2"
            type="png"
            parentFolder="icons"
            styles={{
              width: { xs: pxToRem(36), md: pxTovW(53) },
              height: { xs: pxToRem(36), md: pxTovW(53) },
            }}
          />
          <Typography variant="h3" margin={'auto'}>
            8A Science
          </Typography>
        </Box>
        <Box sx={styles.cardContainer}>
          {studentList.map((elem, i) => (
            <Box sx={styles.card}>
              <Box sx={styles.cardAvatarBox}>
                <SquareImageAvatar size="large" name={elem.name} />

                <Box
                  sx={{
                    boxSizing: 'border-box',
                    maxWidth: '60%',
                    // backgroundColor: 'green',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: pxToRem(7), md: pxTovW(15) },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      textOverflow: 'ellipsis',

                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      maxHeight: { xs: pxToRem(20), md: pxToRem(40) },
                      maxWidth: '100%',
                    }}
                  >
                    {elem.name}
                  </Typography>
                  <OnlineStatusTag status={elem.status} />
                </Box>
              </Box>
              <Box>
                <RemoteNumber number={i + 1} size="large" />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const studentList = [
  {
    name: 'Ruturaj Atreeeeeeeeeeeeeeeeeeeee',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
  {
    name: 'Ruturaj Atre',
    image: 'student1',
    marks: 1,

    status: 'online',
  },
];
