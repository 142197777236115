import {
  AvatarImageWrapper,
  ImageWrapper,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import { AvatarGroup, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { StudentPerformanceInfo } from '@protos/learning_management/lms.hw.teacher.apis_pb';
// import AvatarImageWrapper from 'libs/shared-ui/src/components/composites/AvatarImageWrapper/AvatarImageWrapper';

interface IProps {
  nonSubmissionCount?: number;
  studentinfo?: StudentPerformanceInfo[];
}
export const SendReminder = (props: IProps) => {
  const { studentinfo, nonSubmissionCount } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        display: 'flex',
        height: { xs: '80%', lg: '70%' },
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',

          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: { xs: pxToRem(5), md: pxToRem(11) },
        }}
      >
        <Typography variant="h2" fontWeight="bold">
          {nonSubmissionCount +
            ' ' +
            `Student${nonSubmissionCount === 1 ? '' : 's'} not Submitted`}
        </Typography>
        <Box sx={{ width: 'max-content' }}>
          <AvatarGroup
            max={4}
            sx={{
              '& .MuiAvatarGroup-avatar': {
                //   backgroundColor: 'red',
                height: { xs: pxToRem(45), md: pxTovW(30) },
                width: { xs: pxToRem(45), md: pxTovW(30) },
                fontSize: pxToRem(14),
                marginLeft: { md: '0.1px' },
              },
            }}
          >
            {studentinfo?.map((student) => (
              <AvatarImageWrapper
                ImageSource={student.profileImgUrl}
                size={isMobile ? 'lg' : 'md'}
                name={student.name[0]}
              />
            ))}
          </AvatarGroup>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          //   backgroundColor: 'red',
          boxSizing: 'border-box',
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',

            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageWrapper
            styles={{
              height: { xs: pxToRem(66.67), md: pxTovW(75.76) },
              width: { xs: pxToRem(66.67), md: pxTovW(75.76) },
            }}
            parentFolder="icons"
            name="whatsapp2"
            type="png"
          />
          <Typography variant="cardText">Whatsapp</Typography>
        </Box> */}
        <Box
          sx={{
            display: 'flex',

            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageWrapper
            styles={{
              height: { xs: pxToRem(66.67), md: pxTovW(75.76) },
              width: { xs: pxToRem(66.67), md: pxTovW(75.76) },
            }}
            parentFolder="icons"
            name="copy-link2"
            type="png"
          />
          <Typography variant="cardText">Copy Link</Typography>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ImageWrapper
            styles={{
              height: { xs: pxToRem(66.67), md: pxTovW(75.76) },
              width: { xs: pxToRem(66.67), md: pxTovW(75.76) },
            }}
            parentFolder="icons"
            name="app-notification"
            type="png"
          />
          <Typography variant="cardText">App Notification</Typography>
        </Box> */}
      </Box>
    </Box>
  );
};
