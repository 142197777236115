import {
  clearHomeworkData,
  getHomeworkCloseEvent,
  getHomeworkCreateEvent,
  getHomeworkOpenEvent,
  getHomeworkViewEvent,
  homeworkClose,
  homeworkOpen,
  isHomeworkOpen,
} from '@events/teacher/eventsCreator/homework';
import { IQuestionInfo } from '@events/teacher/eventsCreator/inputArgs';
import { TeacherEventsAPIServiceV1Client } from '@geneo2-web/services-clients';
import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

//* --------------- Homework Events ---------------
export const aiHomeworkOpenEvent = async (homeworkId: number | undefined) => {
  try {
    if (homeworkId && !isHomeworkOpen({ homeworkId: homeworkId })) {
      homeworkOpen({
        openTimestamp: new Date(),
        homeworkId: homeworkId,
      });

      const chapterOpenEvent = getHomeworkOpenEvent({ homeworkId: homeworkId });
      if (chapterOpenEvent.timestamp) {
        await addEventRequest({
          key: chapterOpenEvent.timestamp?.toJsonString(),
          value: chapterOpenEvent.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(chapterOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiHomeworkCloseEvent = async (homeworkId: number | undefined) => {
  try {
    if (homeworkId && isHomeworkOpen({ homeworkId: homeworkId })) {
      homeworkClose({ closeTimestamp: new Date(), homeworkId: homeworkId });
      const chapterCloseEvent = getHomeworkCloseEvent({
        homeworkId: homeworkId,
      });
      if (chapterCloseEvent.timestamp) {
        await addEventRequest({
          key: chapterCloseEvent.timestamp?.toJsonString(),
          value: chapterCloseEvent.toJson(),
        });
      }

      clearHomeworkData({ homeworkId: homeworkId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(chapterCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiHomeworkViewEvent = async (homeworkId: number | undefined) => {
  try {
    if (homeworkId) {
      const viewData = getHomeworkViewEvent({ homeworkId: homeworkId });
      if (viewData.timestamp) {
        await addEventRequest({
          key: viewData.timestamp?.toJsonString(),
          value: viewData.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(viewData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface IHomeworkCreate {
  homeworkId: number | undefined;
  estimateTime: number;
  name: string;
  noOfQuestions: number | undefined;
  marks: number;
  startTimestamp: Date;
  endTimestamp: Date;
  dailyRemainder: Date;
  allowLateSubmission: boolean;
  questionInfo: IQuestionInfo[] | undefined;
}
export const aiHomeworkCreateEvent = async (props: IHomeworkCreate) => {
  const {
    homeworkId,
    estimateTime,
    name,
    noOfQuestions,
    marks,
    startTimestamp,
    endTimestamp,
    dailyRemainder,
    allowLateSubmission,
    questionInfo,
  } = props;

  try {
    if (homeworkId && noOfQuestions && questionInfo) {
      const createData = getHomeworkCreateEvent({
        homeworkId: homeworkId,
        estimateTime: estimateTime,
        name: name,
        noOfQuestions: noOfQuestions,
        marks: marks,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        dailyRemainder: dailyRemainder,
        allowLateSubmission: allowLateSubmission,
        questionInfo: questionInfo,
      });

      if (createData.timestamp) {
        await addEventRequest({
          key: createData.timestamp?.toJsonString(),
          value: createData.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(createData);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
