import { ImageWrapper, pxToRem, pxTovW } from '@geneo2-web/shared-ui';
import { Box, SxProps, Typography } from '@mui/material';

interface IProps {
  rootStyle?: SxProps;
}

export default function ConfigSelector(props: IProps) {
  const { rootStyle } = props;
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        backgroundColor: '#EEF6FD',
        border: '1.5px solid #007CDC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: {
          xs: `${pxToRem(4)} ${pxToRem(12)}`,
          md: `${pxTovW(4)} ${pxTovW(30)}`,
        },
        width: { md: pxTovW(1330), xs: `calc(100% - ${pxToRem(40)})` },
        maxWidth: '100%',
        borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
        margin: '0 auto',
        marginTop: { xs: pxToRem(23), md: 0 },
        ...rootStyle,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: pxTovW(15) }}>
        <ImageWrapper
          name="offline-icon"
          parentFolder="icons"
          type="png"
          styles={{
            height: {
              xs: pxToRem(48.47),
              md: pxTovW(81.4),
            },
            width: {
              xs: pxToRem(46.13),
              md: pxTovW(85.53),
            },
          }}
        ></ImageWrapper>
        <Typography variant="h3" fontWeight="bold">
          You are offline.
        </Typography>
      </Box>
      {/* <Box>
        <ImageWrapper
          onClick={() => {
            navigate(DOWNLOAD_SETTINGS);
          }}
          name="setting"
          parentFolder="icons"
          type="png"
          styles={{
            height: {
              xs: pxToRem(22.34),
              md: pxTovW(43.01),
            },
            width: {
              xs: pxToRem(22.34),
              md: pxTovW(43.01),
            },
            cursor: 'pointer',
          }}
        ></ImageWrapper>
      </Box> */}
    </Box>
  );
}
