import {
  FilePreviewCard,
  IStyles,
  Loader,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Grid, Typography } from '@mui/material';
import {
  FileEnum,
  FileExtensionEnum,
} from '@protos/content_management/content.db_pb';
import { HomeworkContent } from '@protos/learning_management/lms.hw.common.apis_pb';
import { TeacherStudentResponseFetch } from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { SubmissionStudentDetails } from './components/SubmissionStudentDetails';
import { useGlobalContext } from 'apps/teacher/src/app/Context/GlobalContextProvider';

const styles: IStyles = {
  root: {
    p: {
      xs: `${pxToRem(10)}  ${pxToRem(20)}`,
      md: `${pxToRem(20)} ${pxToRem(20)}`,
      lg: `${pxTovW(35)} ${pxTovW(240)}`,
    },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    // justifyContent: 'space-between',
    gap: { xs: pxToRem(10), lg: pxTovW(84) },
    boxSizing: 'border-box',
    // backgroundColor: 'neutral.paleGrey',
    height: '90vh',
  },

  detailsAndMarksBox: {
    // backgroundColor: 'red',
    width: { md: '50%', lg: 'unset' },
  },

  submissionBox: {
    flexGrow: { xs: 1, md: 1, lg: 1 },
    mt: { xs: pxToRem(20), md: pxTovW(0) },
    // backgroundColor: 'blue',
  },
  previewContainer: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // gap: {
    //   xs: `${pxToRem(12)} ${pxToRem(12)}`,
    //   md: `${pxTovW(20)} ${pxTovW(20)}`,
    // },
    mt: { xs: pxToRem(20), md: pxTovW(24) },

    // backgroundColor: 'green',
    // justifyContent: { xs: 'space-between', md: 'flex-start' },
  },
};

export const SubmissionCustomHw = () => {
  const { student_id } = useParams();
  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const { selected_hw_id } = useAppSelector((state) => state.manageHomework);
  const teacher_id = getLocalStorage('userId');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [hwContent, setHwContent] = useState<HomeworkContent | undefined>();
  const { isIpadOnly } = useGlobalContext();
  const [studentResponse, setStudentResponse] = useState<
    TeacherStudentResponseFetch | undefined
  >();
  useEffect(() => {
    // interactionEvent({
    //   url: 'url',
    //   context: 'context',
    //   name: 'name',
    //   pathSegments: pathSegments,
    // });
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const [studentResponse, hwContent] = await Promise.all([
        LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getStudentHWResponse(
          {
            teacherId: teacher_id,
            studentId: BigInt(Number(student_id)),
            homeworkId: selected_hw_id,
          }
        ),
        LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler.fetchHomeworkContent(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            homeworkId: selected_hw_id,
          }
        ),
      ]);
      // console.log(studentResponse.data);

      setStudentResponse(studentResponse.data);
      setHwContent(hwContent.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      console.error(err);
    }
  };
  useEffect(() => {
    if (student_id && selected_hw_id && teacher_id) {
      fetchData();
    }
  }, []);
  console.log(hwContent?.homework?.taskUploads, 'hwContent');
  return loading === true ? (
    <Loader />
  ) : error === true ? (
    <Typography>Error Occured</Typography>
  ) : (
    <Grid sx={styles.root}>
      <Box sx={styles.detailsAndMarksBox}>
        <SubmissionStudentDetails
          studentData={studentResponse}
          maxMarks={hwContent?.homework?.maxMarks}
        />
      </Box>

      <Grid sx={styles.submissionBox}>
        <Typography variant="h2" color="initial" fontWeight={'medium'}>
          Student's Submission
        </Typography>
        <Grid
          container
          columns={{ xs: 1, md: 1, lg: 2 }}
          rowGap={isIpadOnly ? 0 : 2}
          sx={styles.previewContainer}
        >
          {/* Add preview component */}
          {/* {Array.from({ length: 9 }).map(() => ( */}
          {studentResponse?.userUploads?.length &&
          studentResponse?.userUploads?.length > 0 ? (
            studentResponse?.userUploads
              ?.filter((e) => e.isDeleted === false)
              .map((uploadedFile) => (
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      md: pxToRem(400),
                      lg: pxTovW(369),
                    },
                    height: {
                      xs: pxToRem(100),
                      md: pxTovW(300),
                      lg: pxTovW(150),
                    },
                    // backgroundColor: 'red',
                    maxWidth: isIpadOnly ? '50vw' : 'unset',
                    flexGrow: 1,
                  }}
                >
                  <FilePreviewCard
                    element={{
                      fileExtensionType:
                        uploadedFile.fileExtensionType ||
                        FileExtensionEnum.FILE_EXTENSION_UNKNOWN,
                      downloadUrl: getMediaBasePath(
                        uploadedFile.downloadUrl,
                        'schoolnetCdnImagesBucket'
                      ),
                      fileSizeInMb: uploadedFile.fileSizeInMb || 0,
                      fileName: uploadedFile.fileName || '',
                      fileType:
                        uploadedFile.fileType || FileEnum.FILE_TYPE_UNKNOWN,
                      filePreviewUrl: getMediaBasePath(
                        uploadedFile.previewUrl,
                        'schoolnetCdnImagesBucket'
                      ),
                      gcpFileUrl: getMediaBasePath(
                        uploadedFile.gcpFileUrl,
                        'schoolnetCdnImagesBucket'
                      ),
                    }}
                  />
                </Box>
              ))
          ) : (
            <Typography variant="elementBodyText">
              No Uploads by Student
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
