import {
  AdminRejectPopup,
  IStyles,
  IconWrapper,
  ImageWrapper,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Modal,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  LoginTypeEnum,
  ProfileRolesEnum,
  ProfileVerificationStatusEnum,
} from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import {
  setSelfRegistrationData,
  setSelfRegistrationFlow,
} from '../../reducer/auth.slice';

const styles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    // pt: { xs: '0', md: pxTovW(300) },
    display: { xs: 'flex', md: 'flex' },
    alignItems: { xs: 'flex-end', md: 'center' },
  },

  root: {
    // border: '1px solid red',
    marginY: 'auto',
    backgroundColor: 'common.white',
    width: { xs: '80%', md: pxTovW(537) },
    height: { xs: 'max-content', md: 'max-content' },
    maxHeight: { xs: '60vh', md: '60vh' },
    margin: 'auto',
    p: {
      xs: `${pxToRem(50)} ${pxToRem(10)} ${pxToRem(15)}`,
      md: `${pxTovW(128)} ${pxTovW(45)} ${pxTovW(30)}`,
    },
    borderRadius: { xs: pxToRem(20), md: pxTovW(20) },
    position: 'relative',
  },

  iconBox: {
    backgroundColor: 'common.white',
    p: { xs: pxToRem(20), md: pxTovW(28) },
    borderRadius: '50%',
    position: 'absolute',
    top: { xs: '-7%', md: '-12%' },
    left: { xs: '38%', md: '36%' },
  },
  managerIcon: {
    height: { xs: pxToRem(50), md: pxTovW(116) },
    width: { xs: pxToRem(50), md: pxTovW(116) },
    margin: 'auto',
  },
  close: {
    width: { xs: pxToRem(10), md: pxTovW(20) },
    height: { xs: pxToRem(10), md: pxTovW(20) },
    // alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: { xs: '5%', md: '7%' },
    // left: { xs: '38%', md: '36%' },
    right: { xs: '5%', md: '5%' },
  },
  headingText: {
    p: {
      xs: `${pxToRem(0)} ${pxToRem(10)}`,
      md: `${pxTovW(0)} ${pxTovW(60)}`,
    },
    mt: { xs: pxToRem(20), md: pxTovW(0) },
  },

  reasonContainer: {
    backgroundColor: 'neutral.lightPink',
    border: '2px solid red',
    borderColor: 'neutral.crimson',
    borderRadius: { xs: pxToRem(15), md: pxTovW(15) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(0)}`,
      md: `${pxTovW(18)} ${pxTovW(0)}`,
    },
    mb: { xs: pxToRem(20), md: pxTovW(22) },
    maxHeight: { xs: '15vh', md: '15vh' },
    overflowY: 'scroll',
  },
  reasonListBox: {
    width: 'max-content',
    margin: 'auto',
    textAlign: 'center',
  },
  notesListBox: {
    maxHeight: { xs: '15vh', md: '15vh' },
    overflowY: 'scroll',
    mb: { xs: pxToRem(40), md: pxTovW(58) },
    pl: { xs: pxToRem(20), md: pxTovW(25) },
  },

  checkProfileButtonBox: {
    width: { xs: pxToRem(150), md: pxTovW(375) },
    margin: 'auto',
  },
  doubleButtonBox: {
    display: 'flex',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    justifyContent: 'center',
  },
  updateStatusButton: {
    backgroundColor: '#60F9A6',
    boxShadow: 'inset 0 0 8px #0AA34F63,0 7px 13px #0AA34F63',
    border: '2px solid #03D563',
    '&:hover': { backgroundColor: '#03D563' },
  },
};

interface IProps {
  modalState: boolean;
  closeModalState: (arg: boolean) => void;
  closeButtonHandler?: () => void;
  phoneNumber?: string;
  navigatePath?: string;
  profileId: string;
}

export const ProfileVerifyStatusPopup = (props: IProps) => {
  const {
    modalState,
    closeModalState,
    closeButtonHandler,
    phoneNumber,
    navigatePath,
    profileId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler,
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const { self_registration_flow, self_registration_data, school_data } =
    deserify(useAppSelector((state) => state.auth));
  const [radioValue, setRadioValue] = useState<string>('');
  const [notesValue, setNotesValue] = useState<string | undefined>(undefined);
  const [timeLeft, setTimeLeft] = useState(0);

  const verifiedProfileAction = (
    status: ProfileVerificationStatusEnum | undefined
  ) => {
    if (status === ProfileVerificationStatusEnum.PROFILE_VERIFIED) {
      closeModalState(false);
    }
  };

  const profileActionHandler = async () => {
    console.log('profileActionHandler:');
  };

  const remindAdminClickHandler = async () => {
    // todo: case-1 & case-2 (teacherProfileId can be different ?)
    if (profileId) {
      try {
        const response =
          await UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler.sendReminderToAdmin(
            {
              teacherProfileId: BigInt(profileId),
              schoolId: school_data?.schoolId,
            }
          );
        console.log('response:', response);

        if (response.status === 200) {
          console.log(new Date());
          dispatch(
            setSelfRegistrationData({
              ...self_registration_data,
              last_reminder_sent: new Date(),
            })
          );
        }
      } catch (error) {
        console.log('error:', error);
      }
    } else {
      console.error('Profile id not present');
    }
  };

  const statusUpdateClickHandler = async () => {
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.validateUser({
          userLoginInput: phoneNumber,
          loginType: LoginTypeEnum.LOGIN_TYPE_PHONE_NUMBER,
          role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        });
      console.log('response:', response);

      if (response.status === 200) {
        const user = response.data[0];

        if (
          user?.profileVerificationStatus ===
          ProfileVerificationStatusEnum.PROFILE_VERIFIED
        ) {
          dispatch(setSelfRegistrationFlow(false));
          dispatch(setSelfRegistrationData(undefined));
          navigatePath && navigate(navigatePath);
        } else {
          if (user?.lastReminderTime) {
            dispatch(
              setSelfRegistrationData({
                ...self_registration_data,
                last_reminder_sent: user?.lastReminderTime.toDate(),
                profile_verification_status: user?.profileVerificationStatus,
              })
            );
          }
        }

        verifiedProfileAction(user?.profileVerificationStatus);
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const convertTime = (milliseconds: number) => {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
      return [`${seconds}`, 'sec'];
    } else if (minutes < 60) {
      const remainingSeconds = seconds % 60;
      return [
        `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`,
        'min',
      ];
    } else {
      const remainingMinutes = minutes % 60;
      return [
        `${hours}:${remainingMinutes.toString().padStart(2, '0')}`,
        'hours',
      ];
    }
  };

  const getTimeLeftForTimer = (remainderSentTime: Date): number => {
    const currentTime = new Date().getTime();
    const endTime = new Date(
      remainderSentTime.getTime() + 24 * 60 * 60 * 1000
    ).getTime();
    return endTime - currentTime;
  };

  useEffect(() => {
    if (self_registration_data?.last_reminder_sent) {
      setTimeLeft(
        getTimeLeftForTimer(self_registration_data?.last_reminder_sent)
      );
    }

    verifiedProfileAction(self_registration_data?.profile_verification_status);
  }, [self_registration_data?.last_reminder_sent]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (
    self_registration_data?.profile_verification_status ===
    ProfileVerificationStatusEnum.PROFILE_REJECTED
  )
    // if (true)
    return (
      <AdminRejectPopup
        // isEditable
        modalState={modalState}
        closeModalState={closeModalState}
        profileActionHandler={profileActionHandler}
        //
        // editable values
        // radioValue={radioValue}
        // setRadioValue={setRadioValue}
        // notesValue={notesValue}
        // setNotesValue={setNotesValue}
        // reasonChoices={['Incorrect details', 'Details are not valid']}
        //
        // Non-editable values
        rejectionReason={self_registration_data?.rejection_remarks?.reason}
        reasonData={self_registration_data.rejection_remarks?.comments || ''}
      // reasonData={
      //   '<p>this is the reason.</p> <ul><li>one</li></ul>'
      // }
      />
    );

  return (
    <Modal
      open={modalState}
      onClose={() => closeModalState(false)}
      sx={styles.modal}
    >
      <Slide
        direction="up"
        in={modalState}
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <Box sx={styles.root}>
          <Box sx={styles.iconBox}>
            <IconWrapper
              name={'manager'}
              size="md"
              parentFolder="icons"
              type="png"
              customSx={styles.managerIcon}
            />
          </Box>

          <ImageWrapper
            name="close"
            type="png"
            parentFolder="icons"
            styles={styles.close}
            onClick={() => {
              closeModalState(false);
              closeButtonHandler && closeButtonHandler();
            }}
          />

          <Box
            sx={{
              ...styles.headingText,
              mb: {
                xs: pxToRem(timeLeft > 0 ? 40 : 30),
                md: pxTovW(timeLeft > 0 ? 50 : 97),
              },
            }}
          >
            <Typography variant="h2" textAlign="center">
              {timeLeft > 0
                ? 'Your request has been sent. You can remind again in'
                : 'You have been registered successfully. You can login once admin approves your registration.'}
            </Typography>
          </Box>

          {timeLeft > 0 && (
            <Box>
              <CircularProgressWithLabel
                variant="determinate"
                color="secondary"
                // timeLeft % wrt 24hrs in milliseconds
                value={(timeLeft * 100) / (24 * 60 * 60 * 1000)}
                time={convertTime(timeLeft)}
                size={isMobile ? pxToRem(150) : pxTovW(200)}
                sx={{
                  position: 'relative',
                }}
              />
            </Box>
          )}

          <Box sx={styles.doubleButtonBox}>
            <PrimaryButton
              disabled={timeLeft > 0}
              onClick={remindAdminClickHandler}
            >
              <Typography variant="h3" color="common.white" fontWeight="bold">
                REMIND ADMIN
              </Typography>
            </PrimaryButton>

            {!self_registration_flow && (
              <PrimaryButton
                onClick={statusUpdateClickHandler}
                sx={styles.updateStatusButton}
              >
                <Typography variant="h3" fontWeight="bold">
                  TRY AGAIN
                </Typography>
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

// -----------------------------------------------------------------

// Timer
const CircularProgressWithLabel = (
  props: CircularProgressProps & { time: string[] }
) => {
  const { time } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'max-content',
        width: 'max-content',
        margin: 'auto',
        mb: { xs: pxToRem(20), md: pxTovW(40) },
        color: '#cdcdcd',
      }}
    >
      <CircularProgress
        variant="determinate"
        color="inherit"
        value={100}
        size={isMobile ? pxToRem(150) : pxTovW(200)}
        sx={{ position: 'absolute', top: '0%', left: '0%' }}
      />
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: '40%',
          left: '20%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <Typography variant="h2">{time[0]}</Typography>
        <Typography variant="cardText" color="#7F7F7F">
          {time[1]}
        </Typography>
      </Box>
    </Box>
  );
};
