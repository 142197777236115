import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery } from '@mui/material';

import {
  ChapterCard,
  ChapterInfoCard,
  ChipBadge,
  ContentLockPopup,
  DashboardGrid,
  IStyles,
  Loader,
  LockToggleButton,
  NoContentCard,
  SubjectCard,
  cloneObject,
  deepClone,
  deserify,
  findDownloadedSubjectByParams,
  getEnumKeyByEnumValue,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
  useDownloadedSubjectRequests,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { SubjectChapterMiniInfo } from '@protos/content_management/content.common.apis_pb';
import {
  ChapterMetaInfo,
  DownloadedSubject,
  Module_ModuleCategoryEnum,
} from '@protos/content_management/content.db_pb';
import { ContentLockModuleData } from '@protos/learning_management/lms.common.apis_pb';
import {
  ContentLockStatusType,
  Feature,
  SchoolContentModuleNodeLock,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { DownloadButtonWrapper } from '../../../components/DownloadButtonWrapper';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOME,
  TEACH_TOPIC_SELECTION,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { setChapterSubjectInfo } from '../reducer/teach.slice';

interface IlockedPopupData {
  image: string;
  noofTopics: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockStatus: ContentLockStatusType;
}
const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    // width: '100vw',
    width: '100%',
    boxSizing: 'border-box',
    padding: {
      md: `${pxTovW(50)} ${pxTovW(40)}`,
      lg: `${pxTovW(50)} ${pxTovW(240)}`,
    },
    gap: { xs: pxToRem(20), md: pxTovW(40) },
  },
  subjectBox: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(15), md: 0 },
    boxSizing: 'border-box',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    padding: { xs: pxToRem(20), md: 0 },
  },
  onGoing: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(15), md: pxTovW(10) },
    mt: { md: pxTovW(15) },
    // backgroundColor: 'red',
  },
  allChaptersContainer: {
    width: '100%',
    boxSizing: 'border-box',

    '& .grid': {
      padding: { xs: pxToRem(12), md: pxTovW(12) },
    },
    '& .grid > div': {
      borderBottom: '1px solid #B8B8B8',
    },
    '& .grid:not(:nth-of-type(3n))': {
      borderRight: { xs: '1px solid none', md: '1px solid #B8B8B8' },
      borderImage:
        'linear-gradient(to bottom, white 10%,#B8B8B8 10% 90%,white 90% 100%) 1',
    },
  },

  lowerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(15), md: pxTovW(25) },
  },

  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    padding: { xs: pxToRem(20), md: 0 },
  },
};

export default function TeachChapterSelection() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subject_id } = useParams();
  const [loading, setLoading] = useState<'loading' | 'error' | 'completed'>(
    'completed'
  );
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  ); // eslint-disable-line
  const [contentLockData, setContentLockData] =
    useState<ContentLockModuleData>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const teacher_id = getLocalStorage('userId');

  const subjectChapterInfo = deserify(
    useAppSelector((state) => state.teach.subject_chapter_info)
  );

  const commonServiceClientContext = useCommonServiceClientContext();
  const { ContentCommonAPIServiceV1ClientWithStatusCodeHandler } =
    commonServiceClientContext;
  const { LmsCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  // getting current class and subject selected
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  // getting image path from user_info.subjects.iconUrl, by matching className,sectionName,subjectName
  const { user_info } = deserify(useAppSelector((state) => state.auth)) ?? {};
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );
  const { setSelectedFunction, setShowAppLoader } = useGlobalContext();
  const backButtonClick = async () => {
    navigate(HOME);
  };

  const findSubjectImage = () => {
    let subjectImage = '';

    user_info?.teachClassSubjects.forEach((classData) => {
      if (
        class_subject_info?.classname === classData.className &&
        class_subject_info?.section === classData.sectionName
      ) {
        classData.subjects.forEach((subjectData) => {
          if (class_subject_info?.subject === subjectData.subjectName) {
            subjectImage = getMediaBasePath(
              subjectData.iconUrl,
              'schoolnetStaticAssetBucket'
            );
          }
        });
      }
    });

    return subjectImage;
  };

  async function fetchSubjectChapterInfo(subjectId: string) {
    try {
      setLoading('loading');

      const response =
        await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchSubjectChapterInfo(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            bookId: class_subject_info?.bookId,
            sectionId: class_subject_info?.sectionId,
          }
        );

      if (response) {
        if (isContentLockFeature) {
          await getLessonLockedStatus(teacher_id);
        }
        setLoading('completed');
        if (response?.data) {
          const data = response.data;
          dispatch(setChapterSubjectInfo(data));
        }
      }

      // setLoading(false);
    } catch (err) {
      setLoading('error');
      // setError(err);
      console.log(err);
    }
  }

  const [fetchingState, setFetchingState] = useState(false);
  const downloadedSubject = useDownloadedSubjectRequests({
    subjectId: Number(subject_id),
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // downloadedSubject - offline repsonse from indexed db
  const [downloadedMetaData, setDownloadedMetaData] = useState<
    Record<number, ChapterMetaInfo | undefined>
  >({});
  // downloadedMetaData - Offline Response from api

  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);

  useEffect(() => {
    if (subject_id && !isNaN(Number(subject_id))) {
      fetchSubjectChapterInfo(subject_id);
      (async () => {
        setFetchingState(true);
        const indexDbData = await findDownloadedSubjectByParams({
          subjectId: Number(subject_id),
        });
        if (indexDbData.length > 0) {
          const data = indexDbData[0];
          const chapters = data.chapters || {};
          if (Object.keys(chapters).length > 0) {
            const downloadedChapIds = Object.keys(chapters);
            const meta = deepClone(downloadedMetaData);
            for (let i = 0; i < downloadedChapIds.length; i++) {
              const chapId = Number(downloadedChapIds[i]);
              const offlineData =
                await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.getOfflineMetaInfo(
                  {
                    chapterId: Number(chapId),
                  }
                );
              meta[chapId] = offlineData.data;
            }
            setDownloadedMetaData(meta);
          }
        }
        setFetchingState(false);
      })();
    }
  }, [subject_id]);

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = cloneObject(lockedPopupData);
    if (val && currentData) {
      currentData.lockStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };

  const getLessonLockedStatus = async (teacherId: string) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.moduleLockInfoFetch(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
          }
        );
      const data = response?.data;
      if (data) {
        isContentLockFeature && setContentLockData(data);
        // dispatch(setContentLock(data));
      }
    } catch (error) {
      console.log('Error:', error);
      throw error;
    }
  };

  const handleOpenPopup = (
    ev?: React.MouseEvent,
    elem?: SubjectChapterMiniInfo,
    filteredVal?: SchoolContentModuleNodeLock
  ) => {
    ev?.stopPropagation();
    if (elem && filteredVal) {
      setLockedPopData({
        image: elem?.posterImagesUrl
          ? getMediaBasePath(elem?.posterImagesUrl, 'processedMediaBucket')
          : '',
        noofTopics: elem?.noOfTopics.toString() || '',
        title: filteredVal?.title || '',
        moduleId: elem?.chapterId,
        category:
          filteredVal?.category ||
          Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER,
        lockStatus:
          filteredVal?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
      });
    }
    setIsContentLockPopupOpen(true);

    console.log('nbjfhdkhjk', lockedPopupData);
  };

  const handleClosePopup = () => {
    setLockedPopData(null);
    setIsContentLockPopupOpen(false);
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_id),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            moduleInfo: {
              moduleId: lockedPopupData?.moduleId,
              category: lockedPopupData?.category,
              lockStatus: lockedPopupData?.lockStatus,
            },
          }
        );
      const data = response;
      if (data) {
        await getLessonLockedStatus(teacher_id);
        handleClosePopup();
        setLockedPopData(null);
      }
    } catch (error) {
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
      console.log('Error:', error);
    }
  };

  const newItems = subjectChapterInfo?.response
    .sort((a, b) => a.chapterNo - b.chapterNo)
    .map((elem, index) => {
      return (
        <ChapterCard
          key={index}
          withArrow={!isMobile}
          isLocked={
            isContentLockFeature &&
            contentLockData &&
            getContentLockedData(elem.chapterId, contentLockData)
              ?.lockStatus ===
              ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
              ? true
              : false
          }
          variant="normal"
          image={getMediaBasePath(elem.posterImagesUrl, 'processedMediaBucket')}
          mainHeading={elem.chapterNo + '. ' + elem.chapterTitle}
          blueSubText={elem.noOfTopics + ' Topics'}
          rootStyle={{ width: '100%' }}
          cardClickHandler={async () => {
            await interactionEvent({
              url: 'Teacher_teach_chapter_selection',
              context: 'All_chapter',
              name: 'CHAPTER_OPEN',
            });
            navigate(
              `${TEACH_TOPIC_SELECTION}/${subject_id}/${elem.chapterId}`
            );
          }}
        >
          {!fetchingState && (
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '50%', md: 0 },
                right: { xs: pxToRem(12), md: 0 },
                transform: {
                  xs: 'translateY(-50%)',
                  md: 'translateY(-25%)',
                  lg: 'initial',
                },
                display: 'flex',
                gap: { xs: pxToRem(5), md: pxTovW(5) },
              }}
            >
              {isContentLockFeature && (
                <LockToggleButton
                  isLocked={
                    contentLockData
                      ? getContentLockedData(elem.chapterId, contentLockData)
                          ?.lockStatus
                      : false
                  }
                  handleOpenPopup={(ev) => {
                    handleOpenPopup(
                      ev,
                      elem,
                      getContentLockedData(elem.chapterId, contentLockData)
                    );
                  }}
                />
              )}
              <DownloadButtonWrapper
                user={new TeacherLoginResponseType(user_info)}
                downloadedSubject={
                  downloadedSubject
                    ? new DownloadedSubject(downloadedSubject)
                    : undefined
                }
                downloadedMetaData={downloadedMetaData}
                setDownloadedMetaData={setDownloadedMetaData}
                subjectId={Number(subject_id)}
                classId={class_subject_info?.classId}
                sectionId={class_subject_info?.sectionId}
                chapterId={elem.chapterId}
                title={`Chapter - ${elem.chapterTitle}`}
                interactionEventUrl={'Teacher_teach_chapters_selection'}
                interactionEventContext={'chapters'}
              />
            </Box>
          )}
        </ChapterCard>
      );
    });

  // const selectedChapterId = subjectChapterInfo?.response.find(
  //   (val) => !!val.noOfTopics
  // )?.chapterId;
  // const ongoingChapter = subjectChapterInfo?.response.find(
  //   (value) => value.chapterId === selectedChapterId
  // );
  const ongoingChapterId = subjectChapterInfo?.ongoingChapterId;
  const ongoingChapter = subjectChapterInfo?.response.find(
    (val) => val.chapterId === ongoingChapterId
  );

  const chapters =
    subjectChapterInfo?.response.sort((a, b) => a.chapterNo - b.chapterNo) ||
    [];

  return (
    <Box sx={styles.root}>
      <Box sx={styles.subjectBox}>
        <SubjectCard
          image={findSubjectImage()}
          title={class_subject_info ? class_subject_info?.subject : ''}
          subTitle={
            class_subject_info
              ? 'Class ' +
                class_subject_info?.classname +
                class_subject_info?.section
              : ''
          }
        />

        {ongoingChapter && (
          <Box
            sx={{
              ...styles.onGoing,
            }}
          >
            {isMobile && <Typography variant="h2">Ongoing Chapter</Typography>}
            <ChapterInfoCard
              variant={isIpadOnly ? 'large' : 'medium'}
              image={getMediaBasePath(
                ongoingChapter.posterImagesUrl,
                'processedMediaBucket'
              )}
              tagName="Ongoing"
              isContentLockFeature={isContentLockFeature}
              isLocked={
                contentLockData
                  ? getContentLockedData(
                      ongoingChapter.chapterId,
                      contentLockData
                    )?.lockStatus
                  : false
              }
              handleOpenPopup={(ev) => {
                handleOpenPopup(
                  ev,
                  ongoingChapter,
                  getContentLockedData(
                    ongoingChapter.chapterId,
                    contentLockData
                  )
                );
              }}
              mainHeading={
                ongoingChapter.chapterNo + '. ' + ongoingChapter.chapterTitle
              }
              blueSubText={ongoingChapter.noOfTopics + ' Topics'}
              rootStyle={{
                // backgroundColor: 'red',
                width: { xs: '100%', md: pxToRem(360), lg: pxTovW(692) },
                cursor: 'pointer',
              }}
              cardClickHandler={async () => {
                await interactionEvent({
                  url: 'Teacher_teach_chapter_selection',
                  context: 'Ongoing_chapter',
                  name: 'CHAPTER_OPEN',
                });
                navigate(
                  `${TEACH_TOPIC_SELECTION}/${subject_id}/${ongoingChapterId}`
                );
              }}
              withArrow
            />
          </Box>
        )}
      </Box>

      <Box sx={styles.lowerBox}>
        <Box sx={styles.textWithBadge}>
          <Typography variant="h2">All Chapters</Typography>
          <Typography variant="h4">
            <ChipBadge
              label={subjectChapterInfo?.response.length}
              color="primary"
              size="small"
            />
          </Typography>
          {!fetchingState && (
            <Box sx={{ marginLeft: { xs: 'auto', md: '10px' } }}>
              <DownloadButtonWrapper
                user={new TeacherLoginResponseType(user_info)}
                downloadedSubject={
                  downloadedSubject
                    ? new DownloadedSubject(downloadedSubject)
                    : undefined
                }
                downloadedMetaData={downloadedMetaData}
                setDownloadedMetaData={setDownloadedMetaData}
                subjectId={Number(subject_id)}
                classId={class_subject_info?.classId}
                sectionId={class_subject_info?.sectionId}
                chapterIds={chapters?.map((elem) => elem.chapterId)}
                title={`Subject - ${class_subject_info?.subject || ''}`}
                interactionEventUrl={'Teacher_teach_chapters_selection'}
                interactionEventContext={'subject'}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ marginBottom: { xs: pxToRem(20), md: 0 } }}>
          {/* <DashboardGrid items={items} WebNoOfCols={3} mobileNoOfCols={1} /> */}

          {loading === 'loading' ? (
            <Loader />
          ) : loading === 'error' ? (
            <NoContentCard variant="error" icon="error" text="Error Occured" />
          ) : (
            loading === 'completed' &&
            (newItems ? (
              <DashboardGrid
                items={[...newItems]}
                WebNoOfCols={3}
                mobileNoOfCols={1}
              />
            ) : (
              <NoContentCard
                variant="info"
                icon="cards"
                text="No cards to show"
              />
            ))
          )}
        </Box>
      </Box>
      {lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedPopupData.lockStatus}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData ? lockedPopupData : undefined}
        />
      )}
    </Box>
  );
}

const getContentLockedData = (
  chapter_id: number,
  contentLockData?: ContentLockModuleData
) => {
  return contentLockData?.moduleUpdatedLockInfo?.children.find(
    (val) => val.moduleId === chapter_id
  );
};
