import {
  IStyles,
  ImageWrapper,
  PrimaryButton,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
interface IProps {
  open: boolean;
  setInstructions: (list: string[]) => void;
  handleClose?: () => void;
  instructions?: string[] | undefined;
}
const styles: IStyles = {
  modal: {
    boxSizing: 'border-box',
    display: 'flex',
  },
  root: {
    display: { xs: 'flex' },
    flexDirection: 'column',
    backgroundColor: 'common.white',
    margin: { xs: 'auto', md: 'auto' },
    width: { xs: '90%', md: '50%', lg: pxTovW(625) },
    height: { xs: pxToRem(500), md: pxTovW(825), lg: pxTovW(625) },
    borderRadius: { xs: pxToRem(10), md: pxToRem(20) },
    // mt: { md: '15vh'
    alignItems: 'center',
    gap: { md: pxTovW(25) },
  },
  closeBox: {
    marginLeft: 'auto',
  },
  close: {
    width: { xs: pxToRem(15), md: pxTovW(20), lg: pxTovW(25) },
    height: { xs: pxToRem(15), md: pxTovW(20), lg: pxTovW(25) },
    alignItems: 'center',
    cursor: 'pointer',
  },
  instructionCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: { xs: pxToRem(14), md: pxTovW(17) },
    gap: { xs: pxToRem(10), md: pxTovW(20) },
    width: '90%',
  },
  instructionBox: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F6FBFF',
    borderRadius: '5px',
    padding: {
      xs: `${pxToRem(17.5)} ${pxToRem(7)}`,
      lg: `${pxTovW(17.5)} ${pxTovW(12)}`,
    },
  },
  textBox: {
    display: 'flex',
    gap: { xs: pxToRem(4), md: pxTovW(5) },
    alignItems: 'flex-start',
    '& > div': {
      borderBottom: '1px solid #E7F4E7',
    },
  },
  iconBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};
export const HomeworkInstructions = (props: IProps) => {
  const { open, handleClose, instructions, setInstructions } = props;
  const [credential, setCredential] = useState('');
  const [list, setList] = useState<string[]>(instructions || []);

  const handleCredentialChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCredential(event.target.value);
  };

  const handleAddList = (credential: string) => {
    if (credential !== '') {
      setList([...list, credential]);
    }
  };
  const deleteList = (elem: string): void => {
    const updatedInstructions: string[] = list.filter((item) => item !== elem);
    setList(updatedInstructions);
  };

  return (
    <Modal
      sx={styles.modal}
      open={open}
      onClose={() => {
        instructions && setList(instructions);
        handleClose && handleClose();
      }}
    >
      <Box sx={styles.root}>
        <Box sx={styles.instructionCard}>
          <Box
            sx={styles.closeBox}
            onClick={() => {
              instructions && setList(instructions);
              handleClose && handleClose();
            }}
          >
            <ImageWrapper
              name="close"
              type="png"
              parentFolder="icons"
              styles={styles.close}
            />
          </Box>
          <Box sx={styles.instructionBox}>
            <Typography variant="h2" fontWeight="bold" paddingBottom={2}>
              Instructions
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: { xs: pxToRem(280), md: pxTovW(400), lg: pxTovW(350) },
                overflowY: 'auto',
              }}
            >
              {list?.map((elem, i) => (
                <Box key={i} sx={styles.textBox}>
                  <Box
                    sx={{
                      ...styles.iconBox,
                      borderBottom: 'none',
                      cursor: 'default',
                    }}
                  >
                    <CheckOutlinedIcon fontSize="small" color="primary" />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      background: '#FFFFFF',
                      padding: {
                        xs: `${pxToRem(12)} ${pxToRem(10)}`,
                        md: `${pxTovW(17)} ${pxTovW(22)}`,
                      },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        wordBreak: 'break-word',
                      }}
                    >
                      {elem}
                    </Typography>
                    <Box sx={styles.iconBox}>
                      <ClearOutlinedIcon
                        sx={{ color: 'neutral.cornflowerBlue' }}
                        onClick={() => {
                          deleteList(elem);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
              <Box sx={{ display: 'flex' }}>
                <Box
                  sx={styles.iconBox}
                  onClick={() => {
                    handleAddList(credential);
                    setCredential('');
                  }}
                >
                  <AddCircleOutlinedIcon
                    sx={{ color: 'neutral.cornflowerBlue' }}
                  />
                </Box>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  maxRows={4}
                  value={credential}
                  onChange={handleCredentialChange}
                  sx={{
                    background: '#FFFFFF',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={handleClose}
          >
            <PrimaryButton
              onClick={() => {
                if (credential) {
                  handleAddList(credential);
                  setCredential('');
                  setInstructions([...list, credential]);
                } else {
                  setInstructions(list);
                }
              }}
            >
              Save
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
