import {
  ContactPopup,
  IStyles,
  ImageWrapper,
  Loader,
  PrimaryButton,
  UserDetailField,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ProfileVerificationData } from '@protos/user_management/ums.login.apis_pb';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  REGISTRATION,
  SET_PASSWORD,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../auth.events';
import { setSelfRegistrationData, setToastInfo } from '../reducer/auth.slice';
const styles: IStyles = {
  root: {
    // height: { md: '100vh' },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: { md: 'center' },
    marginY: { md: 'auto' },
    // overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    position: { xs: 'relative' },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(26) },
  },
  Heading: {
    display: 'flex',
    justifyContent: { xs: 'flex-start', md: 'center' },
    gap: { xs: pxToRem(10), md: pxTovW(15) },
  },
  DetailsContainer: {
    paddingX: { xs: 0, md: pxTovW(60) },
    marginBottom: { xs: pxToRem(100), md: pxTovW(18) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(25) },
  },
  BottomContainer: {
    width: { xs: '90%', md: pxTovW(774) },
    position: { xs: 'fixed', md: 'relative' },
    bottom: { xs: 0, md: 'none' },
    display: 'flex',
    background: { xs: '#FFFFFF' },
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(50) },
  },
  verifyButton: {
    width: { xs: pxToRem(320), md: pxTovW(517) },
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
  },
  supportBox: {
    width: { xs: '100%', md: pxTovW(774) },
    height: { xs: pxToRem(56), md: pxTovW(74) },
    bgcolor: 'neutral.turquoise',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: { xs: pxToRem(5), md: pxTovW(30) },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
  },
};

//teacher_1
//teacher@1
export const VerifyDetails = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [actionPopupState, setActionPopupState] = useState(false);
  const {
    user_validation_info,
    self_registration_flow,
    self_registration_data,
  } = deserify(useAppSelector((state) => state.auth));
  const [UserVerificationInfo, setUserVerificationInfo] =
    useState<ProfileVerificationData>();
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const getUserInfo = async (profileId?: bigint) => {
    if (profileId) {
      try {
        setLoading('loading');
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.userProfileVerification(
            {
              profileId: profileId,
              role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            }
          );
        // dispatch(setUserInfo(response.data));
        if (response.data) {
          setLoading('completed');
          setUserVerificationInfo(response.data);
          console.log(response);
        }
      } catch (error) {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Please enter valid username',
            variant: 'error',
            open: true,
          })
        );
        navigate(REGISTRATION);
        console.log(error);
      }
    } else {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'Please enter valid username',
          variant: 'error',
          open: true,
        })
      );
      navigate(REGISTRATION);
    }
  };

  useEffect(() => {
    if (!self_registration_flow) {
      getUserInfo(user_validation_info?.profileId);
    }
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        verifyHandler();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const verifyHandler = async () => {
    if (self_registration_flow) {
      if (self_registration_data?.user_profile_id) {
        try {
          setLoading('loading');
          const profileVerification =
            await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
              {
                teacherProfileId: BigInt(
                  self_registration_data?.user_profile_id
                ),
                isProfileVerified: true,
              }
            );
          // console.log('Profile Verification status', profileVerification);
          if (profileVerification.data) {
            dispatch(
              setSelfRegistrationData({
                ...self_registration_data,
                profile_verification_data: profileVerification.data,
              })
            );
            setLoading('completed');
            navigate(SET_PASSWORD);
            await interactionEvent({
              url: '',
              context: 'user_details',
              name: 'VERIFY',
              pathSegments: pathSegments,
            });
          } else {
            throw new Error('Profile Verification failed');
          }
        } catch (err) {
          setLoading('error');
          console.log(err);
          dispatch(
            setToastInfo({
              label: 'Profile verification Failed ',
              variant: 'error',
              open: true,
            })
          );
        }
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Profile verification Failed ',
            variant: 'error',
            open: true,
          })
        );
      }
    } else {
      navigate(SET_PASSWORD);
    }
  };

  // const getTeachSubjects = () => {
  //   const teachSubjects: string[] = [];

  //   UserVerificationInfo?.teacherProfile?.teachClassSubjects.map((elem) =>
  //     elem.subjects.map(
  //       (e) =>
  //         !teachSubjects.includes(e.subjectName) &&
  //         teachSubjects.push(e.subjectName)
  //     )
  //   );
  //   return teachSubjects;
  // };
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.Heading}>
          <Typography variant="h1">Verify Your</Typography>
          <Typography variant="h1" color={'primary.main'}>
            Profile
          </Typography>
        </Box>
        <Box sx={styles.DetailsContainer}>
          <Box sx={styles.Texts}>
            <Typography variant="h2" paddingBottom={1}>
              Personal Details
            </Typography>
            {self_registration_flow ? (
              <Box>
                <UserDetailField
                  label="Phone"
                  value={`${self_registration_data?.phone_number}`}
                />
                <UserDetailField
                  label="Name"
                  value={`${self_registration_data?.first_name} ${self_registration_data?.last_name}`}
                />
              </Box>
            ) : (
              <Box>
                <UserDetailField
                  label="Name"
                  value={`${UserVerificationInfo?.teacherProfile?.firstName} ${UserVerificationInfo?.teacherProfile?.lastName}`}
                />
                {UserVerificationInfo?.teacherProfile?.email && (
                  <UserDetailField
                    label="Email"
                    value={`${UserVerificationInfo?.teacherProfile?.email}`}
                  />
                )}
                {UserVerificationInfo?.teacherProfile?.phoneNumber && (
                  <UserDetailField
                    label="Phone Number"
                    value={`${UserVerificationInfo?.teacherProfile?.phoneNumber}`}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box sx={styles.Texts}>
            <Typography variant="h2" paddingBottom={1}>
              School Details
            </Typography>
            {self_registration_flow ? (
              <Box>
                <UserDetailField
                  label="School Name"
                  value={`${self_registration_data?.school_name}`}
                />
                <UserDetailField
                  label="School Code"
                  value={`${self_registration_data?.school_code}`}
                />
                <UserDetailField
                  label="Role"
                  value={`${
                    self_registration_data?.role ===
                      ProfileRolesEnum.PROFILE_ROLE_TEACHER && 'Teacher '
                  }`}
                />
                <UserDetailField
                  label="Grade, Section & Subject"
                  value={`${self_registration_data?.detailed_class_section_subjects?.map(
                    (elem) =>
                      ` ${elem.class_name}${elem.section_name} ${elem.subject_name}`
                  )}`}
                />
              </Box>
            ) : (
              <Box>
                <UserDetailField
                  label="School Name"
                  value={`${UserVerificationInfo?.teacherProfile?.schoolDetails?.map(
                    (elem) => elem.schoolName
                  )}`}
                />
                <UserDetailField
                  label="Board"
                  value={`${UserVerificationInfo?.teacherProfile?.schoolDetails.map(
                    (elem) => elem.boardMediumInfo.map((e) => e.boardName)
                  )}`}
                />
                <UserDetailField
                  label="Medium"
                  value={`${UserVerificationInfo?.teacherProfile?.schoolDetails.map(
                    (elem) => elem.boardMediumInfo.map((e) => e.mediumName)
                  )}`}
                />
                <UserDetailField
                  label="Class & Section"
                  value={`${UserVerificationInfo?.teacherProfile?.teachClassSubjects?.map(
                    (elem) => `${elem.className} ${elem.sectionName}`
                  )}`}
                />
                <Box>
                  <UserDetailField
                    label="Subjects"
                    value={`${[
                      ...new Set(
                        (
                          UserVerificationInfo?.teacherProfile
                            ?.teachClassSubjects || []
                        ).flatMap((elem) =>
                          elem.subjects.map((e) => e.subjectName)
                        )
                      ),
                    ].map((elem) => elem)}`}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={styles.BottomContainer}>
          <Box sx={styles.verifyButton}>
            <PrimaryButton
              fullWidth
              onClick={verifyHandler}
              // disabled={
              //   UserVerificationInfo?.teacherProfile?.firstName ? false : true
              // }
              ref={buttonRef}
              id="button1"
              onKeyDown={(e) => {
                e.code === 'Enter' && verifyHandler();
              }}
            >
              <Typography variant="h3" fontWeight={'bold'} color="success.light">Verify</Typography>
            </PrimaryButton>
          </Box>
          <Box sx={styles.supportBox} onClick={() => setActionPopupState(true)}>
            <ImageWrapper
              name="loginSupport"
              parentFolder="icons"
              type="png"
              styles={{
                height: { xs: pxToRem(20), md: pxTovW(25) },
                width: { xs: pxToRem(16), md: pxTovW(20) },
              }}
            />
            <Typography variant="linkText">Help & Support</Typography>
          </Box>
        </Box>
      </Box>

      {/* <ActionPopUpSingleButton
        fontSmall={true}
        open={actionPopupState}
        iconName="costumer-service"
        popupText="Please Contact Your School Admin for Registration"
        handleClose={() => setActionPopupState(false)}
        ctaHandler={() => setActionPopupState(false)}
        buttontext="OK"
        background="rgba(193, 248, 218, 1)"
      /> */}

      <ContactPopup
        open={actionPopupState}
        setOpen={() => setActionPopupState(false)}
      />
    </Box>
  );
};
