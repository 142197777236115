import { Timestamp } from '@bufbuild/protobuf';
import {
  BookEventData,
  BookSessionData,
  BookSessionData_InteralSessionData,
  ChapterEventData,
  HomeworkEventData,
  HomeworkSessionData,
  LessonPlanEventData,
  LessonPlanSessionData,
  PerformanceEventData,
  PerformanceSessionData,
  RecommendationEventData,
  RecommendationSessionData,
  ResourceEventData,
  ResourcePageContentEventData,
  ResourcePageContentSessionData,
  ResourcePageEventData,
  ResourcePageInstructionEventData,
  ResourcePageInstructionSessionData,
  ResourcePageQuestionEventData,
  ResourcePageQuestionSessionData,
  ResourcePageSessionData,
  ResourceSessionData,
  SessionData,
  TopicEventData,
  TopicSessionData,
} from '@protos/geneo_ai/ai.teacher.events.data_pb';
import { v4 as uuidv4 } from 'uuid';
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  saveMessageLocal,
} from '../../storage';
import {
  BOOK_SESSION_KEY,
  CONTENT_SESSION_KEY,
  HOMEWORK_SESSION_KEY,
  INSTRUCTION_SESSION_KEY,
  KeyRegex,
  LESSON_PLAN_SESSION_KEY,
  PERFORMANCE_SESSION_KEY,
  RECOMMENDATION_SESSION_KEY,
  RESOURCE_PAGE_CONTENT_SESSION_KEY,
  RESOURCE_SESSION_KEY,
  RPQUESTION_SESSION_KEY,
  SESSION_KEY,
  TOPIC_SESSION_KEY,
} from './storageKeys';

export function timeDifference(
  startTimestamp: Timestamp,
  endTimestamp: Timestamp
) {
  const diffMilliBySeconds =
    Number(endTimestamp.seconds - startTimestamp.seconds) * 1000;
  const diffMilliByNonos = Math.round(
    (endTimestamp.nanos - startTimestamp.nanos) / 1000000
  );
  return Math.round((diffMilliBySeconds + diffMilliByNonos) / 1000);
}

export function getBookEventData(key: string): BookEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return BookEventData.fromJsonString(value);
}

export function getChapterEventData(key: string): ChapterEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ChapterEventData.fromJsonString(value);
}

export function checkNgetBookEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return BookEventData.fromJsonString(value);
}

// export function getChapterEventData(key: string): ChapterEventData | null {
//   const value = SESSION_STORAGE.getItem(key);
//   if (value === null) return null;
//   return ChapterEventData.fromJsonString(value);
// }

export function checkNgetChapterEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  // return BookEventData.fromJsonString(value);
  return ChapterEventData.fromJsonString(value);
}

export function hasEventData(key: string): boolean {
  return SESSION_STORAGE.getItem(key) !== null;
}

export function clearEventData(key: string) {
  SESSION_STORAGE.removeItem(key);
}

export function getSessionData(): SessionData {
  const value = LOCAL_STORAGE.getItem(SESSION_KEY);
  const userId = LOCAL_STORAGE.getItem('userId');
  if (value === null) {
    const tempSessionData = new SessionData({
      sessionId: uuidv4(),
      eventIndex: 1,
      loginTimestamp: Timestamp.fromDate(new Date()),
      teacherId: userId ? BigInt(userId) : BigInt(-1),
    });
    writeSessionData(tempSessionData);
    return tempSessionData;
    // throw new Error(`Session can not be null`);
  }
  return SessionData.fromJsonString(value);
}

export function getBookSessionData(): BookSessionData {
  const value = LOCAL_STORAGE.getItem(BOOK_SESSION_KEY);
  if (value === null) {
    return new BookSessionData();
  }
  return BookSessionData.fromJsonString(value);
}

export function intialiseSessionData(args: {
  teacherId: number;
  sessionId: string;
  eventIndex?: number;
}) {
  const { teacherId, sessionId, eventIndex } = args;
  const sessionData: SessionData = new SessionData({
    teacherId: BigInt(teacherId),
    sessionId,
    eventIndex: eventIndex ? eventIndex : 0,
  });
  writeSessionData(sessionData);
  return sessionData;
}

export function initialiseBookSession(args: {
  bookSessionId: string;
  bookId: number;
}) {
  const { bookSessionId, bookId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.book = new BookSessionData_InteralSessionData({
    sessionId: bookSessionId,
    id: bookId.toString(),
  });
  writeBookSessionData(bookSessionData);
  return bookSessionData;
}

export function initialiseChapterSession(args: {
  chapterSessionId: string;
  chapterId: number;
}) {
  const { chapterSessionId, chapterId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.chapter = new BookSessionData_InteralSessionData({
    sessionId: chapterSessionId,
    id: chapterId.toString(),
  });
  writeBookSessionData(bookSessionData);
}

export function initialiseTopicSession(args: {
  topicSessionId: string;
  topicId: number;
}) {
  const { topicSessionId, topicId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.topic = new BookSessionData_InteralSessionData({
    sessionId: topicSessionId,
    id: topicId.toString(),
  });
  writeBookSessionData(bookSessionData);
  writeBookSessionData(bookSessionData);
}

export function initialiseResourceSession(args: {
  resourceSessionId: string;
  resourceId: string;
}) {
  const { resourceSessionId, resourceId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.resource = new BookSessionData_InteralSessionData({
    sessionId: resourceSessionId,
    id: resourceId,
  });
  writeBookSessionData(bookSessionData);
  writeBookSessionData(bookSessionData);
}

export function hasSessionData(): boolean {
  return LOCAL_STORAGE.getItem(SESSION_KEY) !== null;
}

export function getSessionDataWithIncrimentedIndex(): SessionData {
  const sessionData = getSessionData();
  sessionData.eventIndex += 1;
  writeSessionData(sessionData);
  return sessionData;
}

export function writeSessionData(sessionData: SessionData) {
  saveMessageLocal(SESSION_KEY, sessionData);
}

export function writeBookSessionData(bookSessionData: BookSessionData) {
  saveMessageLocal(BOOK_SESSION_KEY, bookSessionData);
}

export function clearteacherData() {
  const keysToDelete = Object.keys(SESSION_STORAGE).filter(
    (key) => KeyRegex.exec(key) !== null
  );
  keysToDelete.forEach((key) => SESSION_STORAGE.removeItem(key));
  LOCAL_STORAGE.removeItem(SESSION_KEY);
}

export function getTopicEventData(key: string): TopicEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return TopicEventData.fromJsonString(value);
}

export function checkNgetTopicEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return TopicEventData.fromJsonString(value);
}

export function getTopicSessionData(): TopicSessionData {
  const value = SESSION_STORAGE.getItem(TOPIC_SESSION_KEY);
  if (value === null) {
    return new TopicSessionData();
  }
  return TopicSessionData.fromJsonString(value);
}

export function getLessonPlanEventData(
  key: string
): LessonPlanEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return LessonPlanEventData.fromJsonString(value);
}

export function checkNgetLessonPlanEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return LessonPlanEventData.fromJsonString(value);
}

export function getLessonPlanSessionData(): LessonPlanSessionData {
  const value = SESSION_STORAGE.getItem(LESSON_PLAN_SESSION_KEY);
  if (value === null) {
    return new LessonPlanSessionData();
  }
  return LessonPlanSessionData.fromJsonString(value);
}

export function getRecommendationEventData(
  key: string
): RecommendationEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return RecommendationEventData.fromJsonString(value);
}

export function checkNgetRecommendationEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return RecommendationEventData.fromJsonString(value);
}

export function getRecommendationSessionData(): RecommendationSessionData {
  const value = SESSION_STORAGE.getItem(RECOMMENDATION_SESSION_KEY);
  if (value === null) {
    return new RecommendationSessionData();
  }
  return RecommendationSessionData.fromJsonString(value);
}

export function getResourceEventData(key: string): ResourceEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ResourceEventData.fromJsonString(value);
}

export function checkNgetResourceEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return ResourceEventData.fromJsonString(value);
}

export function getResourceSessionData(): ResourceSessionData {
  const value = SESSION_STORAGE.getItem(RESOURCE_SESSION_KEY);
  if (value === null) {
    return new ResourceSessionData();
  }
  return ResourceSessionData.fromJsonString(value);
}

export function getResourcePageEventData(
  key: string
): ResourcePageEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ResourcePageEventData.fromJsonString(value);
}

export function checkNgetResourcePageEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return ResourcePageEventData.fromJsonString(value);
}

export function getResourcePageSessionData(): ResourcePageSessionData {
  const value = SESSION_STORAGE.getItem(RESOURCE_PAGE_CONTENT_SESSION_KEY);
  if (value === null) {
    return new ResourcePageSessionData();
  }
  return ResourcePageSessionData.fromJsonString(value);
}

export function getHomeworkEventData(key: string): HomeworkEventData {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return new HomeworkEventData();
  return HomeworkEventData.fromJsonString(value);
}

export function getnewHomeworkEventData(key: string): HomeworkEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return HomeworkEventData.fromJsonString(value);
}

export function checkNgetHomeworkEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return HomeworkEventData.fromJsonString(value);
}

export function getHomeworkSessionData(): HomeworkSessionData {
  const value = SESSION_STORAGE.getItem(HOMEWORK_SESSION_KEY);
  if (value === null) {
    return new HomeworkSessionData();
  }
  return HomeworkSessionData.fromJsonString(value);
}

export function getResourcePageContentEventData(
  key: string
): ResourcePageContentEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ResourcePageContentEventData.fromJsonString(value);
}

export function checkNgetResourcePageContentEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return ResourcePageContentEventData.fromJsonString(value);
}

export function getContentSessionData(): ResourcePageContentSessionData {
  const value = SESSION_STORAGE.getItem(CONTENT_SESSION_KEY);
  if (value === null) {
    return new ResourcePageContentSessionData();
  }
  return ResourcePageContentSessionData.fromJsonString(value);
}

export function getResourcePageQuestionEventData(
  key: string
): ResourcePageQuestionEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ResourcePageQuestionEventData.fromJsonString(value);
}

export function checkNgetResourcePageQuestionEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return ResourcePageQuestionEventData.fromJsonString(value);
}

export function getResourcePageQuestionSessionData(): ResourcePageQuestionSessionData {
  const value = SESSION_STORAGE.getItem(RPQUESTION_SESSION_KEY);
  if (value === null) {
    return new ResourcePageQuestionSessionData();
  }
  return ResourcePageQuestionSessionData.fromJsonString(value);
}

export function getResourcePageInstructionEventData(
  key: string
): ResourcePageInstructionEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return ResourcePageInstructionEventData.fromJsonString(value);
}

export function checkNgetResourcePageInstructionEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return ResourcePageInstructionEventData.fromJsonString(value);
}

export function getResourcePageInstructionSessionData(): ResourcePageInstructionSessionData {
  const value = SESSION_STORAGE.getItem(INSTRUCTION_SESSION_KEY);
  if (value === null) {
    return new ResourcePageInstructionSessionData();
  }
  return ResourcePageInstructionSessionData.fromJsonString(value);
}

export function getPerformanceEventData(
  key: string
): PerformanceEventData | null {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) return null;
  return PerformanceEventData.fromJsonString(value);
}

export function checkNgetPerformanceEventData(key: string) {
  const value = SESSION_STORAGE.getItem(key);
  if (value === null) {
    throw new Error(`Data should not be null for "${key}"`);
  }
  return PerformanceEventData.fromJsonString(value);
}

export function getPerformanceSessionData(): PerformanceSessionData {
  const value = SESSION_STORAGE.getItem(PERFORMANCE_SESSION_KEY);
  if (value === null) {
    return new PerformanceSessionData();
  }
  return PerformanceSessionData.fromJsonString(value);
}

export function getTimestampByDiff(timestamp: Date, diffMilliSeconds: number) {
  const diffTimestamp = new Date();
  timestamp.setMilliseconds(diffTimestamp.getMilliseconds() + diffMilliSeconds);
  return diffTimestamp;
}

export type ISelectedFromType =
  | 'HOMEWORK_SELECTION_FROM_UNDEFINED'
  | 'APP_HOMEPAGE_HW'
  | 'HOMEWORK_SUBMITTED_HOMEPAGE_HW'
  | 'RESOURCE_SELECTION_FROM_UNDEFINED'
  | 'APP_HOMEPAGE'
  | 'CHAPTER_HOMEPAGE'
  | 'TOPIC_HOMEPAGE'
  | 'LESSON_PLAN_HOMEPAGE'
  | 'RECOMMENDATIONS'
  | 'PERFOMANCE_HOMEPAGE'
  | 'LESSONPLAN_SELECTION_FROM_UNDEFINED'
  | 'APP_HOMEPAGE_LP'
  | 'CHAPTER_HOMEPAGE_LP'
  | 'TOPIC_HOMEPAGE_LP'
  | 'LESSON_PLAN_HOMEPAGE_LP';
