import { useState } from 'react';

import { Box, Chip, Tab, Tabs, Typography } from '@mui/material';

import { ChipBadge, IStyles, pxToRem, pxTovW } from '@geneo2-web/shared-ui';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `${pxTovW(1)} solid #E0E0E0`,
    borderBottom: `${pxTovW(1)} solid #E0E0E0`,
    // backgroundColor: 'blue',
    width: { xs: '100vw', md: 'unset' },
  },

  tabs: {
    '&.MuiTabs-root': {
      minHeight: { xs: '7vw', md: '3.441vw' },
      bgcolor: 'unset',

      '& > div > div': {
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '100%',
        // backgroundColor: 'blue',
      },
    },
    '& .MuiTabs-indicator': {
      height: '0px',
    },
  },

  tab: {
    '&.MuiButtonBase-root': {
      fontSize: { xs: pxToRem(25), md: '1vw' },
      color: 'text.primary',
      fontWeight: 'light',
      m: {
        xs: `${pxToRem(10)} ${pxToRem(0)} ${pxToRem(5)} ${pxToRem(0)}`,
        md: `${pxTovW(20)} ${pxTovW(20)} ${pxTovW(8)} ${pxTovW(20)}`,
      },
    },
    '&.Mui-selected': {
      color: 'text.secondary',
      fontWeight: 'bold',
    },

    // border: '1px solid red',
    p: { xs: `${pxToRem(5)} ${pxToRem(5)}`, md: `0` },
  },

  tabBox: {
    display: 'flex',
    gap: { xs: pxToRem(13), md: pxTovW(13) },
    p: { md: `${pxTovW(0)} ${pxTovW(21)}` },
    // backgroundColor: 'red',
    width: { xs: pxToRem(180) },
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface IProps {
  stateValue: string;
  mapData: string[];
  handleChange: (arg: string) => void;
}
export const TabComp = ({ stateValue, mapData, handleChange }: IProps) => {
  const [value, setValue] = useState(0);

  const handleClick = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleChange(mapData[newValue]);
  };

  return (
    <Box sx={styles.root}>
      <Tabs
        value={value}
        onChange={handleClick}
        aria-label="basic tabs example"
        sx={styles.tabs}
      >
        {mapData.map((elem, index) => (
          <Tab
            key={index}
            sx={styles.tab}
            label={<TotalCountTab elem={elem} stateValue={stateValue} />}
          />
        ))}
      </Tabs>
    </Box>
  );
};

interface TCT {
  elem: string;
  stateValue: string;
}
const TotalCountTab = ({ elem, stateValue }: TCT) => {
  return (
    <Box sx={styles.tabBox}>
      {stateValue === elem ? (
        <Chip
          label={
            <Typography variant="cardText" sx={{ margin: '10px' }}>
              {elem}
            </Typography>
          }
          size="small"
          sx={{
            visibility: elem === stateValue ? 'visible' : 'hidden',
            padding: { xs: `${pxToRem(4)} ${pxToRem(16)}` },
            height: 'auto',
            backgroundColor: '#F8C807',
            '& .MuiChip-label': {
              //   backgroundColor: 'red',
              display: 'flex',
              whiteSpace: 'normal',

              height: 'auto',

              textAlign: 'center',
            },
          }}
        />
      ) : (
        <Typography variant="h3" fontWeight="light">
          {elem}{' '}
          {/* length to be retrieved by sending the entire data or from redux */}
        </Typography>
      )}
    </Box>
  );
};
