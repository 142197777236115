// @generated by protoc-gen-es v1.2.1 with parameter "target=ts"
// @generated from file src/protos/geneo_ai/ai.teacher.events.data.proto (package geneo.teacher.session.events, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Content_ContentEnum, Question_QuestionEnum } from "@protos/content_management/content.db_pb";

/**
 * @generated from enum geneo.teacher.session.events.QAttemptResultEnum
 */
export enum QAttemptResultEnum {
  /**
   * @generated from enum value: RESPONSE_UNDEFINED = 0;
   */
  RESPONSE_UNDEFINED = 0,

  /**
   * @generated from enum value: RESPONSE_CORRECT = 1;
   */
  RESPONSE_CORRECT = 1,

  /**
   * @generated from enum value: RESPONSE_INCORRECT = 2;
   */
  RESPONSE_INCORRECT = 2,

  /**
   * @generated from enum value: RESPONSE_PARTIALLY_CORRECT = 3;
   */
  RESPONSE_PARTIALLY_CORRECT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QAttemptResultEnum)
proto3.util.setEnumType(QAttemptResultEnum, "geneo.teacher.session.events.QAttemptResultEnum", [
  { no: 0, name: "RESPONSE_UNDEFINED" },
  { no: 1, name: "RESPONSE_CORRECT" },
  { no: 2, name: "RESPONSE_INCORRECT" },
  { no: 3, name: "RESPONSE_PARTIALLY_CORRECT" },
]);

/**
 * @generated from message geneo.teacher.session.events.BookSessionData
 */
export class BookSessionData extends Message<BookSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.BookSessionData.InteralSessionData book = 1;
   */
  book?: BookSessionData_InteralSessionData;

  /**
   * @generated from field: optional geneo.teacher.session.events.BookSessionData.InteralSessionData chapter = 2;
   */
  chapter?: BookSessionData_InteralSessionData;

  /**
   * @generated from field: optional geneo.teacher.session.events.BookSessionData.InteralSessionData topic = 3;
   */
  topic?: BookSessionData_InteralSessionData;

  /**
   * @generated from field: optional geneo.teacher.session.events.BookSessionData.InteralSessionData resource = 4;
   */
  resource?: BookSessionData_InteralSessionData;

  constructor(data?: PartialMessage<BookSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.BookSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book", kind: "message", T: BookSessionData_InteralSessionData },
    { no: 2, name: "chapter", kind: "message", T: BookSessionData_InteralSessionData, opt: true },
    { no: 3, name: "topic", kind: "message", T: BookSessionData_InteralSessionData, opt: true },
    { no: 4, name: "resource", kind: "message", T: BookSessionData_InteralSessionData, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookSessionData {
    return new BookSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookSessionData {
    return new BookSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookSessionData {
    return new BookSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: BookSessionData | PlainMessage<BookSessionData> | undefined, b: BookSessionData | PlainMessage<BookSessionData> | undefined): boolean {
    return proto3.util.equals(BookSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.BookSessionData.InteralSessionData
 */
export class BookSessionData_InteralSessionData extends Message<BookSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<BookSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.BookSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookSessionData_InteralSessionData {
    return new BookSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookSessionData_InteralSessionData {
    return new BookSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookSessionData_InteralSessionData {
    return new BookSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: BookSessionData_InteralSessionData | PlainMessage<BookSessionData_InteralSessionData> | undefined, b: BookSessionData_InteralSessionData | PlainMessage<BookSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(BookSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.SessionData
 */
export class SessionData extends Message<SessionData> {
  /**
   * @generated from field: int64 teacher_id = 1;
   */
  teacherId = protoInt64.zero;

  /**
   * @generated from field: string session_id = 2;
   */
  sessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp login_timestamp = 3;
   */
  loginTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp logout_timestamp = 4;
   */
  logoutTimestamp?: Timestamp;

  /**
   * @generated from field: int32 event_index = 5;
   */
  eventIndex = 0;

  constructor(data?: PartialMessage<SessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.SessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teacher_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "login_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "logout_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "event_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SessionData {
    return new SessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SessionData {
    return new SessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SessionData {
    return new SessionData().fromJsonString(jsonString, options);
  }

  static equals(a: SessionData | PlainMessage<SessionData> | undefined, b: SessionData | PlainMessage<SessionData> | undefined): boolean {
    return proto3.util.equals(SessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.BookEventData
 */
export class BookEventData extends Message<BookEventData> {
  /**
   * @generated from field: int32 book_id = 1;
   */
  bookId = 0;

  /**
   * @generated from field: string book_session_id = 2;
   */
  bookSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<BookEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.BookEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "book_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "book_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BookEventData {
    return new BookEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BookEventData {
    return new BookEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BookEventData {
    return new BookEventData().fromJsonString(jsonString, options);
  }

  static equals(a: BookEventData | PlainMessage<BookEventData> | undefined, b: BookEventData | PlainMessage<BookEventData> | undefined): boolean {
    return proto3.util.equals(BookEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.TopicSessionData
 */
export class TopicSessionData extends Message<TopicSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.TopicSessionData.InteralSessionData topic = 1;
   */
  topic?: TopicSessionData_InteralSessionData;

  constructor(data?: PartialMessage<TopicSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.TopicSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "message", T: TopicSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicSessionData {
    return new TopicSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicSessionData {
    return new TopicSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicSessionData {
    return new TopicSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: TopicSessionData | PlainMessage<TopicSessionData> | undefined, b: TopicSessionData | PlainMessage<TopicSessionData> | undefined): boolean {
    return proto3.util.equals(TopicSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.TopicSessionData.InteralSessionData
 */
export class TopicSessionData_InteralSessionData extends Message<TopicSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<TopicSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.TopicSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicSessionData_InteralSessionData {
    return new TopicSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicSessionData_InteralSessionData {
    return new TopicSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicSessionData_InteralSessionData {
    return new TopicSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: TopicSessionData_InteralSessionData | PlainMessage<TopicSessionData_InteralSessionData> | undefined, b: TopicSessionData_InteralSessionData | PlainMessage<TopicSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(TopicSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.TopicEventData
 */
export class TopicEventData extends Message<TopicEventData> {
  /**
   * @generated from field: int32 topic_id = 1;
   */
  topicId = 0;

  /**
   * @generated from field: string topic_session_id = 2;
   */
  topicSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<TopicEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.TopicEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "topic_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicEventData {
    return new TopicEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicEventData {
    return new TopicEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicEventData {
    return new TopicEventData().fromJsonString(jsonString, options);
  }

  static equals(a: TopicEventData | PlainMessage<TopicEventData> | undefined, b: TopicEventData | PlainMessage<TopicEventData> | undefined): boolean {
    return proto3.util.equals(TopicEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.PerformanceEventData
 */
export class PerformanceEventData extends Message<PerformanceEventData> {
  /**
   * @generated from field: string performance_id = 1;
   */
  performanceId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<PerformanceEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.PerformanceEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "performance_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceEventData {
    return new PerformanceEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceEventData {
    return new PerformanceEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceEventData {
    return new PerformanceEventData().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceEventData | PlainMessage<PerformanceEventData> | undefined, b: PerformanceEventData | PlainMessage<PerformanceEventData> | undefined): boolean {
    return proto3.util.equals(PerformanceEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.PerformanceSessionData
 */
export class PerformanceSessionData extends Message<PerformanceSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.PerformanceSessionData.InteralSessionData performance = 1;
   */
  performance?: PerformanceSessionData_InteralSessionData;

  constructor(data?: PartialMessage<PerformanceSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.PerformanceSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "performance", kind: "message", T: PerformanceSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceSessionData {
    return new PerformanceSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceSessionData {
    return new PerformanceSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceSessionData {
    return new PerformanceSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceSessionData | PlainMessage<PerformanceSessionData> | undefined, b: PerformanceSessionData | PlainMessage<PerformanceSessionData> | undefined): boolean {
    return proto3.util.equals(PerformanceSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.PerformanceSessionData.InteralSessionData
 */
export class PerformanceSessionData_InteralSessionData extends Message<PerformanceSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<PerformanceSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.PerformanceSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PerformanceSessionData_InteralSessionData {
    return new PerformanceSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PerformanceSessionData_InteralSessionData {
    return new PerformanceSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PerformanceSessionData_InteralSessionData {
    return new PerformanceSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: PerformanceSessionData_InteralSessionData | PlainMessage<PerformanceSessionData_InteralSessionData> | undefined, b: PerformanceSessionData_InteralSessionData | PlainMessage<PerformanceSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(PerformanceSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.LessonPlanSessionData
 */
export class LessonPlanSessionData extends Message<LessonPlanSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.LessonPlanSessionData.InteralSessionData lesson_plan = 1;
   */
  lessonPlan?: LessonPlanSessionData_InteralSessionData;

  constructor(data?: PartialMessage<LessonPlanSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.LessonPlanSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_plan", kind: "message", T: LessonPlanSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonPlanSessionData {
    return new LessonPlanSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonPlanSessionData {
    return new LessonPlanSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonPlanSessionData {
    return new LessonPlanSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: LessonPlanSessionData | PlainMessage<LessonPlanSessionData> | undefined, b: LessonPlanSessionData | PlainMessage<LessonPlanSessionData> | undefined): boolean {
    return proto3.util.equals(LessonPlanSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.LessonPlanSessionData.InteralSessionData
 */
export class LessonPlanSessionData_InteralSessionData extends Message<LessonPlanSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<LessonPlanSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.LessonPlanSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonPlanSessionData_InteralSessionData {
    return new LessonPlanSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonPlanSessionData_InteralSessionData {
    return new LessonPlanSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonPlanSessionData_InteralSessionData {
    return new LessonPlanSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: LessonPlanSessionData_InteralSessionData | PlainMessage<LessonPlanSessionData_InteralSessionData> | undefined, b: LessonPlanSessionData_InteralSessionData | PlainMessage<LessonPlanSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(LessonPlanSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.LessonPlanEventData
 */
export class LessonPlanEventData extends Message<LessonPlanEventData> {
  /**
   * @generated from field: string lesson_id = 1;
   */
  lessonId = "";

  /**
   * @generated from field: string lessonplan_session_id = 2;
   */
  lessonplanSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<LessonPlanEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.LessonPlanEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lesson_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "lessonplan_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonPlanEventData {
    return new LessonPlanEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonPlanEventData {
    return new LessonPlanEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonPlanEventData {
    return new LessonPlanEventData().fromJsonString(jsonString, options);
  }

  static equals(a: LessonPlanEventData | PlainMessage<LessonPlanEventData> | undefined, b: LessonPlanEventData | PlainMessage<LessonPlanEventData> | undefined): boolean {
    return proto3.util.equals(LessonPlanEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourceSessionData
 */
export class ResourceSessionData extends Message<ResourceSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.ResourceSessionData.InteralSessionData resource = 1;
   */
  resource?: ResourceSessionData_InteralSessionData;

  constructor(data?: PartialMessage<ResourceSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourceSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource", kind: "message", T: ResourceSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceSessionData {
    return new ResourceSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceSessionData {
    return new ResourceSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceSessionData {
    return new ResourceSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceSessionData | PlainMessage<ResourceSessionData> | undefined, b: ResourceSessionData | PlainMessage<ResourceSessionData> | undefined): boolean {
    return proto3.util.equals(ResourceSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourceSessionData.InteralSessionData
 */
export class ResourceSessionData_InteralSessionData extends Message<ResourceSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<ResourceSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourceSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceSessionData_InteralSessionData {
    return new ResourceSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceSessionData_InteralSessionData {
    return new ResourceSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceSessionData_InteralSessionData {
    return new ResourceSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceSessionData_InteralSessionData | PlainMessage<ResourceSessionData_InteralSessionData> | undefined, b: ResourceSessionData_InteralSessionData | PlainMessage<ResourceSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(ResourceSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourceEventData
 */
export class ResourceEventData extends Message<ResourceEventData> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: string resource_session_id = 2;
   */
  resourceSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<ResourceEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourceEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourceEventData {
    return new ResourceEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourceEventData {
    return new ResourceEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourceEventData {
    return new ResourceEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourceEventData | PlainMessage<ResourceEventData> | undefined, b: ResourceEventData | PlainMessage<ResourceEventData> | undefined): boolean {
    return proto3.util.equals(ResourceEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageSessionData
 */
export class ResourcePageSessionData extends Message<ResourcePageSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.ResourcePageSessionData.InteralSessionData resource_page = 1;
   */
  resourcePage?: ResourcePageSessionData_InteralSessionData;

  constructor(data?: PartialMessage<ResourcePageSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_page", kind: "message", T: ResourcePageSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageSessionData {
    return new ResourcePageSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageSessionData {
    return new ResourcePageSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageSessionData {
    return new ResourcePageSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageSessionData | PlainMessage<ResourcePageSessionData> | undefined, b: ResourcePageSessionData | PlainMessage<ResourcePageSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageSessionData.InteralSessionData
 */
export class ResourcePageSessionData_InteralSessionData extends Message<ResourcePageSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: int32 page_number = 3;
   */
  pageNumber = 0;

  constructor(data?: PartialMessage<ResourcePageSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageSessionData_InteralSessionData {
    return new ResourcePageSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageSessionData_InteralSessionData {
    return new ResourcePageSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageSessionData_InteralSessionData {
    return new ResourcePageSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageSessionData_InteralSessionData | PlainMessage<ResourcePageSessionData_InteralSessionData> | undefined, b: ResourcePageSessionData_InteralSessionData | PlainMessage<ResourcePageSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageEventData
 */
export class ResourcePageEventData extends Message<ResourcePageEventData> {
  /**
   * @generated from field: string resource_id = 1;
   */
  resourceId = "";

  /**
   * @generated from field: int32 page_number = 2;
   */
  pageNumber = 0;

  /**
   * @generated from field: string resource_session_id = 3;
   */
  resourceSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 4;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 5;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 6;
   */
  actionId = "";

  constructor(data?: PartialMessage<ResourcePageEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "resource_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageEventData {
    return new ResourcePageEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageEventData {
    return new ResourcePageEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageEventData {
    return new ResourcePageEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageEventData | PlainMessage<ResourcePageEventData> | undefined, b: ResourcePageEventData | PlainMessage<ResourcePageEventData> | undefined): boolean {
    return proto3.util.equals(ResourcePageEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ChapterEventData
 */
export class ChapterEventData extends Message<ChapterEventData> {
  /**
   * @generated from field: int32 chapter_id = 1;
   */
  chapterId = 0;

  /**
   * @generated from field: string chapter_session_id = 2;
   */
  chapterSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<ChapterEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ChapterEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chapter_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "chapter_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChapterEventData {
    return new ChapterEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChapterEventData {
    return new ChapterEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChapterEventData {
    return new ChapterEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ChapterEventData | PlainMessage<ChapterEventData> | undefined, b: ChapterEventData | PlainMessage<ChapterEventData> | undefined): boolean {
    return proto3.util.equals(ChapterEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.HomeworkSessionData
 */
export class HomeworkSessionData extends Message<HomeworkSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.HomeworkSessionData.InteralSessionData homework = 1;
   */
  homework?: HomeworkSessionData_InteralSessionData;

  constructor(data?: PartialMessage<HomeworkSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.HomeworkSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework", kind: "message", T: HomeworkSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkSessionData {
    return new HomeworkSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkSessionData {
    return new HomeworkSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkSessionData {
    return new HomeworkSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkSessionData | PlainMessage<HomeworkSessionData> | undefined, b: HomeworkSessionData | PlainMessage<HomeworkSessionData> | undefined): boolean {
    return proto3.util.equals(HomeworkSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.HomeworkSessionData.InteralSessionData
 */
export class HomeworkSessionData_InteralSessionData extends Message<HomeworkSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<HomeworkSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.HomeworkSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkSessionData_InteralSessionData {
    return new HomeworkSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkSessionData_InteralSessionData {
    return new HomeworkSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkSessionData_InteralSessionData {
    return new HomeworkSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkSessionData_InteralSessionData | PlainMessage<HomeworkSessionData_InteralSessionData> | undefined, b: HomeworkSessionData_InteralSessionData | PlainMessage<HomeworkSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(HomeworkSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.HomeworkEventData
 */
export class HomeworkEventData extends Message<HomeworkEventData> {
  /**
   * @generated from field: int32 homework_id = 1;
   */
  homeworkId = 0;

  /**
   * @generated from field: string homework_session_id = 2;
   */
  homeworkSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * @generated from field: repeated string question_ids = 6;
   */
  questionIds: string[] = [];

  /**
   * @generated from field: geneo.teacher.session.events.QuestionData question_data = 7;
   */
  questionData?: QuestionData;

  constructor(data?: PartialMessage<HomeworkEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.HomeworkEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "homework_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "homework_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "question_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "question_data", kind: "message", T: QuestionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeworkEventData {
    return new HomeworkEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeworkEventData {
    return new HomeworkEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeworkEventData {
    return new HomeworkEventData().fromJsonString(jsonString, options);
  }

  static equals(a: HomeworkEventData | PlainMessage<HomeworkEventData> | undefined, b: HomeworkEventData | PlainMessage<HomeworkEventData> | undefined): boolean {
    return proto3.util.equals(HomeworkEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.QuestionSubmitStatus
 */
export class QuestionSubmitStatus extends Message<QuestionSubmitStatus> {
  constructor(data?: PartialMessage<QuestionSubmitStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.QuestionSubmitStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionSubmitStatus {
    return new QuestionSubmitStatus().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionSubmitStatus | PlainMessage<QuestionSubmitStatus> | undefined, b: QuestionSubmitStatus | PlainMessage<QuestionSubmitStatus> | undefined): boolean {
    return proto3.util.equals(QuestionSubmitStatus, a, b);
  }
}

/**
 * @generated from enum geneo.teacher.session.events.QuestionSubmitStatus.QuestionSubmitStatusEnum
 */
export enum QuestionSubmitStatus_QuestionSubmitStatusEnum {
  /**
   * right
   *
   * @generated from enum value: R = 0;
   */
  R = 0,

  /**
   * wrong
   *
   * @generated from enum value: W = 1;
   */
  W = 1,

  /**
   * unattempted
   *
   * @generated from enum value: U = 2;
   */
  U = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionSubmitStatus_QuestionSubmitStatusEnum)
proto3.util.setEnumType(QuestionSubmitStatus_QuestionSubmitStatusEnum, "geneo.teacher.session.events.QuestionSubmitStatus.QuestionSubmitStatusEnum", [
  { no: 0, name: "R" },
  { no: 1, name: "W" },
  { no: 2, name: "U" },
]);

/**
 * @generated from message geneo.teacher.session.events.QuestionData
 */
export class QuestionData extends Message<QuestionData> {
  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string question_id = 6;
   */
  questionId = "";

  /**
   * @generated from field: geneo.teacher.session.events.QAttemptResultEnum status = 5;
   */
  status = QAttemptResultEnum.RESPONSE_UNDEFINED;

  /**
   * @generated from field: repeated string answer = 8;
   */
  answer: string[] = [];

  constructor(data?: PartialMessage<QuestionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.QuestionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(QAttemptResultEnum) },
    { no: 8, name: "answer", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QuestionData {
    return new QuestionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QuestionData {
    return new QuestionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QuestionData {
    return new QuestionData().fromJsonString(jsonString, options);
  }

  static equals(a: QuestionData | PlainMessage<QuestionData> | undefined, b: QuestionData | PlainMessage<QuestionData> | undefined): boolean {
    return proto3.util.equals(QuestionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.RecommendationSessionData
 */
export class RecommendationSessionData extends Message<RecommendationSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.RecommendationSessionData.InteralSessionData recommendation = 1;
   */
  recommendation?: RecommendationSessionData_InteralSessionData;

  constructor(data?: PartialMessage<RecommendationSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.RecommendationSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recommendation", kind: "message", T: RecommendationSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationSessionData {
    return new RecommendationSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationSessionData {
    return new RecommendationSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationSessionData {
    return new RecommendationSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationSessionData | PlainMessage<RecommendationSessionData> | undefined, b: RecommendationSessionData | PlainMessage<RecommendationSessionData> | undefined): boolean {
    return proto3.util.equals(RecommendationSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.RecommendationSessionData.InteralSessionData
 */
export class RecommendationSessionData_InteralSessionData extends Message<RecommendationSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: int32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<RecommendationSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.RecommendationSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationSessionData_InteralSessionData {
    return new RecommendationSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationSessionData_InteralSessionData {
    return new RecommendationSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationSessionData_InteralSessionData {
    return new RecommendationSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationSessionData_InteralSessionData | PlainMessage<RecommendationSessionData_InteralSessionData> | undefined, b: RecommendationSessionData_InteralSessionData | PlainMessage<RecommendationSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(RecommendationSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.RecommendationEventData
 */
export class RecommendationEventData extends Message<RecommendationEventData> {
  /**
   * @generated from field: string recommendation_id = 1;
   */
  recommendationId = "";

  /**
   * @generated from field: string recommendation_session_id = 2;
   */
  recommendationSessionId = "";

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  constructor(data?: PartialMessage<RecommendationEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.RecommendationEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recommendation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recommendation_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendationEventData {
    return new RecommendationEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendationEventData {
    return new RecommendationEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendationEventData {
    return new RecommendationEventData().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendationEventData | PlainMessage<RecommendationEventData> | undefined, b: RecommendationEventData | PlainMessage<RecommendationEventData> | undefined): boolean {
    return proto3.util.equals(RecommendationEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageContentSessionData
 */
export class ResourcePageContentSessionData extends Message<ResourcePageContentSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.ResourcePageContentSessionData.InteralSessionData content = 1;
   */
  content?: ResourcePageContentSessionData_InteralSessionData;

  constructor(data?: PartialMessage<ResourcePageContentSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageContentSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: ResourcePageContentSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentSessionData {
    return new ResourcePageContentSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentSessionData {
    return new ResourcePageContentSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentSessionData {
    return new ResourcePageContentSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentSessionData | PlainMessage<ResourcePageContentSessionData> | undefined, b: ResourcePageContentSessionData | PlainMessage<ResourcePageContentSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageContentSessionData.InteralSessionData
 */
export class ResourcePageContentSessionData_InteralSessionData extends Message<ResourcePageContentSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<ResourcePageContentSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageContentSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentSessionData_InteralSessionData {
    return new ResourcePageContentSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentSessionData_InteralSessionData {
    return new ResourcePageContentSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentSessionData_InteralSessionData {
    return new ResourcePageContentSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentSessionData_InteralSessionData | PlainMessage<ResourcePageContentSessionData_InteralSessionData> | undefined, b: ResourcePageContentSessionData_InteralSessionData | PlainMessage<ResourcePageContentSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageQuestionSessionData
 */
export class ResourcePageQuestionSessionData extends Message<ResourcePageQuestionSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.ResourcePageQuestionSessionData.InteralSessionData question = 1;
   */
  question?: ResourcePageQuestionSessionData_InteralSessionData;

  constructor(data?: PartialMessage<ResourcePageQuestionSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageQuestionSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "question", kind: "message", T: ResourcePageQuestionSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageQuestionSessionData {
    return new ResourcePageQuestionSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageQuestionSessionData {
    return new ResourcePageQuestionSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageQuestionSessionData {
    return new ResourcePageQuestionSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageQuestionSessionData | PlainMessage<ResourcePageQuestionSessionData> | undefined, b: ResourcePageQuestionSessionData | PlainMessage<ResourcePageQuestionSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageQuestionSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageQuestionSessionData.InteralSessionData
 */
export class ResourcePageQuestionSessionData_InteralSessionData extends Message<ResourcePageQuestionSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<ResourcePageQuestionSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageQuestionSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageQuestionSessionData_InteralSessionData {
    return new ResourcePageQuestionSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageQuestionSessionData_InteralSessionData {
    return new ResourcePageQuestionSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageQuestionSessionData_InteralSessionData {
    return new ResourcePageQuestionSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageQuestionSessionData_InteralSessionData | PlainMessage<ResourcePageQuestionSessionData_InteralSessionData> | undefined, b: ResourcePageQuestionSessionData_InteralSessionData | PlainMessage<ResourcePageQuestionSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageQuestionSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageInstructionSessionData
 */
export class ResourcePageInstructionSessionData extends Message<ResourcePageInstructionSessionData> {
  /**
   * @generated from field: geneo.teacher.session.events.ResourcePageInstructionSessionData.InteralSessionData instruction = 1;
   */
  instruction?: ResourcePageInstructionSessionData_InteralSessionData;

  constructor(data?: PartialMessage<ResourcePageInstructionSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageInstructionSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruction", kind: "message", T: ResourcePageInstructionSessionData_InteralSessionData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageInstructionSessionData {
    return new ResourcePageInstructionSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageInstructionSessionData {
    return new ResourcePageInstructionSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageInstructionSessionData {
    return new ResourcePageInstructionSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageInstructionSessionData | PlainMessage<ResourcePageInstructionSessionData> | undefined, b: ResourcePageInstructionSessionData | PlainMessage<ResourcePageInstructionSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageInstructionSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageInstructionSessionData.InteralSessionData
 */
export class ResourcePageInstructionSessionData_InteralSessionData extends Message<ResourcePageInstructionSessionData_InteralSessionData> {
  /**
   * @generated from field: string session_id = 1;
   */
  sessionId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<ResourcePageInstructionSessionData_InteralSessionData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageInstructionSessionData.InteralSessionData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageInstructionSessionData_InteralSessionData {
    return new ResourcePageInstructionSessionData_InteralSessionData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageInstructionSessionData_InteralSessionData {
    return new ResourcePageInstructionSessionData_InteralSessionData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageInstructionSessionData_InteralSessionData {
    return new ResourcePageInstructionSessionData_InteralSessionData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageInstructionSessionData_InteralSessionData | PlainMessage<ResourcePageInstructionSessionData_InteralSessionData> | undefined, b: ResourcePageInstructionSessionData_InteralSessionData | PlainMessage<ResourcePageInstructionSessionData_InteralSessionData> | undefined): boolean {
    return proto3.util.equals(ResourcePageInstructionSessionData_InteralSessionData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.VideoEventRange
 */
export class VideoEventRange extends Message<VideoEventRange> {
  /**
   * @generated from field: float start_position = 1;
   */
  startPosition = 0;

  /**
   * @generated from field: optional float end_position = 2;
   */
  endPosition?: number;

  /**
   * @generated from field: float speed = 3;
   */
  speed = 0;

  constructor(data?: PartialMessage<VideoEventRange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.VideoEventRange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "end_position", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "speed", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoEventRange {
    return new VideoEventRange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoEventRange {
    return new VideoEventRange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoEventRange {
    return new VideoEventRange().fromJsonString(jsonString, options);
  }

  static equals(a: VideoEventRange | PlainMessage<VideoEventRange> | undefined, b: VideoEventRange | PlainMessage<VideoEventRange> | undefined): boolean {
    return proto3.util.equals(VideoEventRange, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageContentEventData
 */
export class ResourcePageContentEventData extends Message<ResourcePageContentEventData> {
  /**
   * @generated from field: optional string content_id = 1;
   */
  contentId?: string;

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * @generated from field: repeated geneo.teacher.session.events.VideoEventRange ranges = 6;
   */
  ranges: VideoEventRange[] = [];

  /**
   * @generated from field: optional int32 video_position = 7;
   */
  videoPosition?: number;

  /**
   * @generated from field: optional string resource_id = 8;
   */
  resourceId?: string;

  /**
   * @generated from field: optional int32 page_number = 9;
   */
  pageNumber?: number;

  /**
   * @generated from field: geneo.content.db.Content.ContentEnum type = 10;
   */
  type = Content_ContentEnum.CONTENT_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageContentEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageContentEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "ranges", kind: "message", T: VideoEventRange, repeated: true },
    { no: 7, name: "video_position", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "type", kind: "enum", T: proto3.getEnumType(Content_ContentEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageContentEventData {
    return new ResourcePageContentEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageContentEventData {
    return new ResourcePageContentEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageContentEventData {
    return new ResourcePageContentEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageContentEventData | PlainMessage<ResourcePageContentEventData> | undefined, b: ResourcePageContentEventData | PlainMessage<ResourcePageContentEventData> | undefined): boolean {
    return proto3.util.equals(ResourcePageContentEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageQuestionEventData
 */
export class ResourcePageQuestionEventData extends Message<ResourcePageQuestionEventData> {
  /**
   * @generated from field: optional string question_id = 2;
   */
  questionId?: string;

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * @generated from field: optional string resource_id = 6;
   */
  resourceId?: string;

  /**
   * @generated from field: optional int32 page_number = 7;
   */
  pageNumber?: number;

  /**
   * @generated from field: geneo.content.db.Question.QuestionEnum type = 9;
   */
  type = Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<ResourcePageQuestionEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageQuestionEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "question_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "type", kind: "enum", T: proto3.getEnumType(Question_QuestionEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageQuestionEventData {
    return new ResourcePageQuestionEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageQuestionEventData {
    return new ResourcePageQuestionEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageQuestionEventData {
    return new ResourcePageQuestionEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageQuestionEventData | PlainMessage<ResourcePageQuestionEventData> | undefined, b: ResourcePageQuestionEventData | PlainMessage<ResourcePageQuestionEventData> | undefined): boolean {
    return proto3.util.equals(ResourcePageQuestionEventData, a, b);
  }
}

/**
 * @generated from message geneo.teacher.session.events.ResourcePageInstructionEventData
 */
export class ResourcePageInstructionEventData extends Message<ResourcePageInstructionEventData> {
  /**
   * @generated from field: optional string instruction_id = 1;
   */
  instructionId?: string;

  /**
   * @generated from field: google.protobuf.Timestamp open_timestamp = 3;
   */
  openTimestamp?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp close_timestamp = 4;
   */
  closeTimestamp?: Timestamp;

  /**
   * @generated from field: string action_id = 5;
   */
  actionId = "";

  /**
   * @generated from field: optional string resource_id = 6;
   */
  resourceId?: string;

  /**
   * @generated from field: optional int32 page_number = 7;
   */
  pageNumber?: number;

  constructor(data?: PartialMessage<ResourcePageInstructionEventData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "geneo.teacher.session.events.ResourcePageInstructionEventData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instruction_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "open_timestamp", kind: "message", T: Timestamp },
    { no: 4, name: "close_timestamp", kind: "message", T: Timestamp },
    { no: 5, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "page_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcePageInstructionEventData {
    return new ResourcePageInstructionEventData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcePageInstructionEventData {
    return new ResourcePageInstructionEventData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcePageInstructionEventData {
    return new ResourcePageInstructionEventData().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcePageInstructionEventData | PlainMessage<ResourcePageInstructionEventData> | undefined, b: ResourcePageInstructionEventData | PlainMessage<ResourcePageInstructionEventData> | undefined): boolean {
    return proto3.util.equals(ResourcePageInstructionEventData, a, b);
  }
}

