import {
  callTeacherEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  deserify,
  getEnvConfig,
  getLocalStorage,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { resetAnalyticsState } from '../../pages/Analytics/reducer/analytics.slice';
import { aiLogoutEvent } from '../../pages/Auth/auth.events';
import { resetAuthState } from '../../pages/Auth/reducer/auth.slice';
import { aiBookCloseEvent } from '../../pages/Home/home.events';
import { resetHomeDashboardState } from '../../pages/Home/reducer/homeDashboard.slice';
import { aiHomeworkCloseEvent } from '../../pages/Homework/homework.events';
import { resetHomeworkState } from '../../pages/Homework/reducer/homework.slice';
import { resetManageHWState } from '../../pages/ManageHomework/reducer/manageHomework.slice';
import { resetConnectedClassState } from '../../pages/Teach/reducer/connectedClass.slice';
import {
  resetTeachState,
  setSelectedResourceSessionId,
} from '../../pages/Teach/reducer/teach.slice';
import {
  aiChapterCloseEvent,
  aiLessonCloseEvent,
  aiTopicCloseEvent,
} from '../../pages/Teach/teach.events';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';
import { useAuthentication } from '../../utils/customHooks';
import { useDownloadContext } from '../../app/Context/DownloadContextProviderV2';

// const PrivateRouterWrapper = () => {
//   const isAuthenticated = useAuthentication();
//   console.log('isAuthenticated', isAuthenticated);
//   return isAuthenticated === true ? <Outlet /> : <Navigate to="/login" />;
// };

// export default PrivateRouterWrapper;

const PrivateRouterWrapper = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOffline } = useDownloadContext();
  const isAuthenticated = useAuthentication();
  const isMCBUser = deserify(useAppSelector((state) => state.auth.is_mcb_user));
  const ssoRedirectionUrl = deserify(
    useAppSelector((state) => state.auth.ssoRedirectionUrl)
  );
  const config = getEnvConfig();
  // console.log('isAuthenticated', isAuthenticated);
  // const { isAuthenticated2 } = useGlobalContext();
  // console.log('isAuthenticated2', isAuthenticated2);
  // Function to handle logout and state reset
  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;
  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { selected_hw_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );
  const closeAiFunctions = async () => {
    await aiLessonCloseEvent(selected_lessons_info?.lessonId);
    await aiTopicCloseEvent(selected_topic_info?.topicId);
    await aiChapterCloseEvent(chapter_resources?.chapterId);
    await aiBookCloseEvent(class_subject_info?.bookId);
    await aiHomeworkCloseEvent(selected_hw_id);
    await aiLogoutEvent();
    dispatch(setSelectedResourceSessionId(undefined));
    // a call to clear backlog of events stored in indexDb
    if (isAuthenticated) {
      !isOffline && (await callTeacherEventsApi(commonServiceClientContext));
    }
  };

  const notificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
        deviceId: deviceId ? BigInt(deviceId) : undefined,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_TEACHER}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  const handleLogout = async () => {
    try {
      closeAiFunctions();
      if (fcmToken) {
        await notificationAPI();
      }
      if (isAuthenticated) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
            profileId: getLocalStorage('userId'),
            role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          });
      }
    } catch (err) {
      console.log(err);
    } finally {
      let redirectUrl = undefined;
      if (isMCBUser && ssoRedirectionUrl) {
        redirectUrl = ssoRedirectionUrl;
      }
      dispatch(resetAuthState());
      dispatch(resetHomeDashboardState());
      dispatch(resetHomeworkState());
      dispatch(resetManageHWState());
      dispatch(resetTeachState());
      dispatch(resetConnectedClassState());
      dispatch(resetAnalyticsState());
      // localStorage.clear();
      clearLocalStorageKeys();
      deleteOfflineAccessKeyFromCache();
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      // navigate(LOGIN);
    }
  };
  // Check if the user is authenticated, and render accordingly
  if (isAuthenticated) {
    return <Outlet />;
  } else {
    // If not authenticated, call handleLogout function and then navigate to login
    handleLogout();
    return <Navigate to="/login" />;
  }
};

export default PrivateRouterWrapper;
