export const BASE_PATH = '/';
export const HOME = '/home';
export const DOCS = '/docs';
//LOGIN
export const LOGIN = '/login';
export const VERIFY_DETAILS = '/verify-details';
export const ENTER_UID = '/enter-uid';
export const VERIFY_PHONE = '/verify-phone';
export const VERIFY_EMAIL = '/verify-email';
export const SET_PASSWORD = '/set-password';
export const RESET_PASSWORD_OTP = '/reset-password-otp';
export const CHOOSE_PROFILE = '/choose-profile';
export const REGISTRATION = '/registration';
export const SELF_REGISTRATION = '/self-registration';
export const USER_NAME_REGISTRATION = '/registration-user-name';
export const SELF_REGISTRATION_FORM = '/self-registration-form';

// Homework
export const HOMEWORK_CHAPTER_SELECTION = '/hw-chapters-selection';
export const HOMEWORK_TOPIC_SELECTION = '/hw-topics-selection';
export const HOMEWORK_CREATE = '/homework-create';
export const HOMEWORK_ASSIGN = '/homework-assign';
export const HOMEWORK_MANAGE = '/homework-manage';
export const HOMEWORK_ONGOING = '/homework-ongoing';
export const HOMEWORK_COMPLETED = '/homework-completed';
export const HOMEWORK_REVIEW = '/homework-review';
export const HOMEWORK_QUESTIONBANK = '/homework-questionBank';
export const HOMEWORK_PERFORMANCE = '/performance-homework';
export const HOMEWORK_CONGRATULATIONS = '/homework-congratulations';
export const HOMEWORK_VIEW = '/view-homework';
export const HOMEWORK_CURRENT = '/current-homework';
export const HOMEWORK_CURRENT_CUSTOM = '/current-custom-homework';
export const HOMEWORK_SUBMISSION_CUSTOM = '/submission-custom-hw';

//Analytics
export const ANALYTICS_CLASS_SELECTION = '/analytics-dashboard';
export const ANALYTICS_CLASS_SCORE = '/analytics-classScore';
export const ANALYTICS_CHAPTER_SCORE = '/analytics-chapterScore';
export const ANALYTICS_STUDENT_SCORE = '/analytics-studentScore';
// Teach
export const TEACH_CHAPTER_SELECTION = '/teach-chapters-selection';
export const TEACH_TOPIC_SELECTION = '/teach-topics-selection';
export const LESSON_PLAN_LISTS = '/lesson-plan-lists';
export const TEACHING_FLOW = '/teaching-flow';
export const EDIT_LESSON_PLAN = '/edit-lesson-plan';
export const ADD_RESOURCES = '/add-resources';
export const REPLACE_RESOURCE = '/replace-resource';
export const RESOURCE_PAGE = '/resource';
export const SEEALL_LESSON_PLAN_LIST = '/lessonplan-list';
// Sidebar
export const NOTIFICATION = '/notifications';
export const HELP = '/help';
export const PROFILE = '/profile';
export const CHANGE_PASSWORD = '/change-password';
export const FAQ = '/faq';
export const DISCLAIMER = '/disclaimer';
export const VERIFY_PASSWORD = '/verify-password';
export const RESET_PASSWORD = '/reset-password';
export const OTP_SCREEN = '/otp-screen';
export const LOGIN_NEW = '/login-new';
//ConnectedClassroom
export const START_CLASSROOM = '/connected-clasroom/start';
export const CONNECTED_CLASS_RESULTS = '/connected-clasroom/results';
export const LOGIN_PASSWORD = '/login_password';
export const PROFILE_OTP_SCREEN = '/profile-otp-screen';

//Offline
export const OFFLINE_TEACH_CHAPTER_SELECTION =
  '/offline/teach-chapters-selection';
export const OFFLINE_TEACH_TOPIC_SELECTION = '/offline/teach-topics-selection';
export const OFFLINE_LESSON_PLAN_LISTS = '/offline/lesson-plan-lists';
export const OFFLINE_TEACHING_FLOW = '/offline/teaching-flow';
export const OFFLINE_RESOURCE = '/offline/resource';
export const DOWNLOAD_SETTINGS = '/download-settings';

// MCB Login
export const MCB_LOGIN = '/mcb-login';
export const MCB_APP_LOGIN = '/mcb-app-login';
