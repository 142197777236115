import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { PlainMessage } from '@bufbuild/protobuf';
import {
  ChapterSelectedCard,
  ChipBadge,
  IStyles,
  TopicCard,
  convertBytesToHumanReadable,
  deleteDownloadedChapter,
  deleteDownloadedTopic,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  getSizeOfNode,
  pxToRem,
  pxTovW,
  resourceTypeName,
} from '@geneo2-web/shared-ui';
import { ResourceInfo } from '@protos/content_management/content.common.apis_pb';
import { DownloadedSubject } from '@protos/content_management/content.db_pb';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { DeleteButton } from '../../../../components/DeleteButton';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { OFFLINE_TEACH_CHAPTER_SELECTION } from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/auth.events';

const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: 'column',
    gap: pxTovW(25),
    padding: { md: `${pxTovW(14)} ${pxTovW(240)}` },
    paddingTop: { md: pxTovW(40) },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(5)} ${pxToRem(20)}`,
      md: 0,
    },
  },
  topicContainer: {
    flexBasis: { xs: '100%', md: '50%' },
    // maxWidth: { md: pxTovW(554) },
    // paddingLeft: { md: pxTovW(246) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chapterBox: {
    padding: { xs: pxToRem(20), md: 0 },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },

  chooseTopicBox: {
    display: 'flex',
    flexDirection: 'column',
    // gap: { xs: pxToRem(25) },
    width: { xs: '100%', md: '50%' },
    gap: { xs: pxToRem(7), md: pxTovW(18) },
    // backgroundColor: 'red',
  },
  topicBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '& > div': { borderBottom: '1px solid #C6C6C6' },
  },
};

export default function TeachTopicSelectionOffline() {
  const navigate = useNavigate();
  const { subject_id, chapter_id } = useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const [downloadedSubject, setDownloadedSubject] = useState<
    PlainMessage<DownloadedSubject>
  >(new DownloadedSubject());
  const chapters = downloadedSubject.chapters || {};
  const selectedChapter = chapters[chapterId];
  const topics = selectedChapter?.downloadedTopics || {};
  console.log({ class_subject_info, topics });
  // Filter out topics with lesson plans of current teacher and section
  const topicsArray =
    Object.values(topics)
      .sort((a, b) => a.order - b.order)
      .filter((top) => {
        const lPs = top.downloadedLessonPlans || {};
        const lPsArray = Object.values(lPs).filter(
          (val) =>
            class_subject_info?.sectionId &&
            val.schoolClassSectionId.includes(class_subject_info.sectionId) &&
            (!val.teacherId || val.teacherId == user_info?.teacherProfileId)
        );
        if (lPsArray.length) {
          return true;
        }
        return false;
      }) || [];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setSelectedFunction } = useGlobalContext();

  const backButtonClick = () => {
    navigate(`${OFFLINE_TEACH_CHAPTER_SELECTION}/${subjectId}`);
  };

  useEffect(() => {
    updateDownloadedSubjectData();
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const updateDownloadedSubjectData = async () => {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: subjectId,
    });
    if (indexDbData.length > 0) {
      const data = indexDbData[0];
      setDownloadedSubject(data);
    }
  };
  return (
    <Box sx={styles.root}>
      {/* <ConfigSelector /> */}
      <Box sx={styles.topicContainer}>
        <Box sx={styles.chapterBox}>
          <ChapterSelectedCard
            image={getMediaBasePath(
              selectedChapter?.posterImagesUrl,
              'processedMediaBucket'
            )}
            chapterName={selectedChapter?.chapterTitle}
            className={
              class_subject_info
                ? class_subject_info?.classname + class_subject_info?.section
                : ''
            }
            subject={downloadedSubject?.name}
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              gap: pxTovW(15),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: {
                  xs: `${pxToRem(10)} ${pxToRem(6)}`,
                  md: `${pxTovW(11)} ${pxTovW(19)}`,
                },
                backgroundColor: '#FFE3E7',
                border: '1px solid #FF6177',
                borderRadius: {
                  xs: pxToRem(7),
                  md: pxTovW(7),
                },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3" fontWeight="medium">
                {convertBytesToHumanReadable(
                  getSizeOfNode(
                    downloadedSubject,
                    chapterId,
                    undefined,
                    undefined,
                    user_info?.teacherProfileId
                  )
                )}
              </Typography>
            </Box>
            <DeleteButton
              title={selectedChapter?.chapterTitle}
              rootStyle={{
                height: {
                  xs: pxToRem(39),
                  md: pxTovW(55),
                },
                width: {
                  xs: pxToRem(39),
                  md: pxTovW(55),
                },
                marginLeft: {
                  xs: pxToRem(10),
                  md: pxTovW(15),
                },
                marginRight: {
                  xs: pxToRem(10),
                  md: pxTovW(15),
                },
              }}
              onClick={async () => {
                if (isNaN(subjectId) || isNaN(chapterId)) {
                  return;
                }
                await deleteDownloadedChapter({
                  subjectId,
                  chapterId,
                  sectionId: class_subject_info?.sectionId,
                  userId: user_info?.teacherProfileId.toString(),
                });
                navigate(`${OFFLINE_TEACH_CHAPTER_SELECTION}/${subjectId}`);
                await interactionEvent({
                  url: 'Teacher_teach_topic_selection',
                  context: 'chapter',
                  name: 'DELETE',
                });
              }}
            />
          </Box>
          {/* {isMobile &&
            <Typography sx={{ marginRight: pxToRem(10), marginTop: pxToRem(6) }} variant='body1'>Expires in 19d</Typography>
          } */}
        </Box>
      </Box>
      <Box
        sx={{
          // backgroundColor: 'red',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={styles.chooseTopicBox}>
          <Box sx={styles.textWithBadge}>
            <Typography variant="h2">Choose a Topic</Typography>
            <Typography variant="h3">
              <ChipBadge
                label={topicsArray.length}
                color="primary"
                size="small"
                sx={{ display: { xs: 'none', md: 'unset' } }}
              />
            </Typography>
          </Box>

          <Box sx={styles.topicBox}>
            {topicsArray.map((topic) => {
              return (
                <TopicCard
                  title={topic.topicName}
                  showViewAllOnMobile
                  // title={`${findChapter()?.chapterNo}.${index + 1} ${topics.topicTitle
                  //   }`}
                  offlineSubText={convertBytesToHumanReadable(
                    getSizeOfNode(
                      downloadedSubject,
                      chapterId,
                      topic.topicId,
                      undefined,
                      user_info?.teacherProfileId
                    )
                  )}
                  lessonPlanCount={topic.lessonPlanCount}
                  questionsCount={4}
                  viewAllClickHandler={async () => {
                    navigate(
                      `/offline/lesson-plan-lists/${subjectId}/${chapterId}/${topic.topicId}`
                    );
                    await interactionEvent({
                      url: 'Teacher_teach_topic_selection',
                      context: 'Choose_a_topic',
                      name: 'VIEW',
                    });
                  }}
                  rootStyle={{
                    padding: {
                      xs: `${pxToRem(14)} ${pxToRem(5)} ${pxToRem(
                        14
                      )} ${pxToRem(20)}`,
                      md: `${pxTovW(14)} ${pxTovW(32)}`,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      // backgroundColor: 'red',
                    }}
                  >
                    <DeleteButton
                      title={topic.topicName}
                      onClick={async () => {
                        if (
                          isNaN(subjectId) ||
                          isNaN(chapterId) ||
                          !topic.topicId
                        ) {
                          return;
                        }
                        await deleteDownloadedTopic({
                          subjectId,
                          chapterId,
                          topicId: topic.topicId,
                          sectionId: class_subject_info?.sectionId,
                          userId: user_info?.teacherProfileId.toString(),
                        });
                        await updateDownloadedSubjectData();
                        await interactionEvent({
                          url: 'Teacher_teach_topic_selection',
                          context: 'Choose_a_topic',
                          name: 'DELETE',
                        });
                      }}
                      rootStyle={{
                        marginLeft: {
                          xs: pxToRem(10),
                          md: pxTovW(15),
                        },
                        marginRight: {
                          xs: pxToRem(5),
                          md: 0,
                        },
                      }}
                    />
                  </Box>
                </TopicCard>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const iconDetails = (resource: ResourceInfo) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },

    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};
