import { Timestamp } from '@bufbuild/protobuf';
import { HomeworkEventData } from '@protos/geneo_ai/ai.student.events.data_pb';
import { TeacherEvent } from '@protos/geneo_ai/ai.teacher.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  IHomeworkCloseArgs,
  IHomeworkCreateEventArgs,
  IHomeworkEventArgs,
  IHomeworkOpenArgs,
} from './inputArgs';
import {
  HomeworkCloseArgsValidator,
  HomeworkEventArgsValidator,
  HomeworkOpenArgsValidator,
} from './inputArgsValidator';
import { HOMEWORK_KEY } from './storageKeys';
import {
  checkNgetHomeworkEventData,
  clearEventData,
  getHomeworkEventData,
  getSessionDataWithIncrimentedIndex,
  getnewHomeworkEventData,
  hasEventData,
  timeDifference,
} from './utils';

export function homeworkStorageKey(homeworkId: number) {
  return `${HOMEWORK_KEY}!${homeworkId}`;
}

export function homeworkOpen(args: IHomeworkOpenArgs): HomeworkEventData {
  const { error } = HomeworkOpenArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { openTimestamp, homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getnewHomeworkEventData(storageKey);
  const homeworkSessionId = uuidv4();
  if (homeworkData !== null) {
    throw new Error(
      `Homework Data has to be null but ${JSON.stringify(
        homeworkData
      )} is present"`
    );
  } else {
    const eventData = new HomeworkEventData({
      homeworkId,
      homeworkSessionId: homeworkSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: homeworkSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getHomeworkOpenEvent(args: IHomeworkEventArgs): TeacherEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (eventData === undefined) {
    throw new Error(
      `eventData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        HomeworkActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function getHomeworkViewEvent(args: IHomeworkEventArgs): TeacherEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (eventData === undefined) {
    throw new Error(
      `eventData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        HomeworkActionType: {
          case: 'viewHomework',
          value: true,
        },
      },
    },
  });
}

export function getHomeworkCreateEvent(
  args: IHomeworkCreateEventArgs
): TeacherEvent {
  // const { error } = HomeworkEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const {
    homeworkId,
    name,
    estimateTime,
    questionInfo,
    noOfQuestions,
    marks,
    startTimestamp,
    endTimestamp,
    allowLateSubmission,
    dailyRemainder,
  } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  if (eventData === undefined) {
    throw new Error(
      `eventData can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        HomeworkActionType: {
          case: 'create',
          value: {
            name: name,
            estimateTime: estimateTime,
            questionInfo: questionInfo,
            noOfQuestions: noOfQuestions,
            marks: marks,
            startTimestamp: Timestamp.fromDate(startTimestamp),
            endTimestamp: Timestamp.fromDate(endTimestamp),
            allowLateSubmission: allowLateSubmission,
            dailyReminderTime: Timestamp.fromDate(dailyRemainder),
          },
        },
      },
    },
  });
}

export function homeworkClose(args: IHomeworkCloseArgs): HomeworkEventData {
  const { error } = HomeworkCloseArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { closeTimestamp, homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const homeworkData = getHomeworkEventData(storageKey);
  if (!homeworkData) {
    throw new Error('homeworkData can not be undefined');
  }

  if (homeworkData.homeworkId !== homeworkId) {
    throw new Error(
      `Homework Id in ${JSON.stringify(
        homeworkData
      )} can't be different from provided "${homeworkId}"`
    );
  } else {
    homeworkData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, homeworkData);
    return homeworkData;
  }
}

export function getHomeworkCloseEvent(args: IHomeworkEventArgs): TeacherEvent {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  const eventData = checkNgetHomeworkEventData(storageKey);
  if (!eventData) {
    throw new Error('eventData can not be undefined');
  }
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const homeworkSessionData = getHomeworkSessionData();
  // if (homeworkSessionData.homework === undefined) {
  //   throw new Error(
  //     `homework Session id can not be undefined in ${JSON.stringify(
  //       homeworkSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'homeworkAction',
      value: {
        actionId: eventData.actionId,
        homeworkId,
        HomeworkActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function clearHomeworkData(args: IHomeworkEventArgs) {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  clearEventData(storageKey);
}

export function isHomeworkOpen(args: IHomeworkEventArgs): boolean {
  const { error } = HomeworkEventArgsValidator.validate(args);
  if (error !== undefined) {
    throw error;
  }
  const { homeworkId } = args;
  const storageKey = homeworkStorageKey(homeworkId);
  return hasEventData(storageKey);
}
