import { Timestamp } from '@bufbuild/protobuf';
import {
  BookSessionData_InteralSessionData,
  SessionData,
} from '@protos/geneo_ai/ai.teacher.events.data_pb';
import { TeacherEvent } from '@protos/geneo_ai/ai.teacher.events_pb';
import { saveMessageSession } from '../../storage';
import { BOOK_SESSION_KEY } from './storageKeys';
import {
  getBookSessionData,
  getSessionData,
  getSessionDataWithIncrimentedIndex,
  timeDifference,
  writeSessionData,
} from './utils';

export function initialiseBookSession(args: {
  bookSessionId: string;
  bookId: number;
}) {
  const { bookSessionId, bookId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.book = new BookSessionData_InteralSessionData({
    sessionId: bookSessionId,
    id: bookId.toString(),
  });
  saveMessageSession(BOOK_SESSION_KEY, bookSessionData);
}

export function removeBookSession() {
  const bookSessionData = getBookSessionData();
  bookSessionData.book = undefined;
  saveMessageSession(BOOK_SESSION_KEY, bookSessionData);
}

export function login(args: {
  teacherId: number;
  sessionId: string;
  loginTimestamp: Date;
}): SessionData {
  const { teacherId, sessionId, loginTimestamp } = args;
  const sessionData: SessionData = new SessionData({
    sessionId,
    eventIndex: 1,
    loginTimestamp: Timestamp.fromDate(loginTimestamp),
    teacherId: BigInt(teacherId),
  });
  writeSessionData(sessionData);
  return sessionData;
}

export function getLoginEvent(): TeacherEvent {
  const sessionData = getSessionData();
  if (sessionData.loginTimestamp === undefined) {
    throw new Error(
      `Login timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: sessionData.loginTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'login',
      value: true,
    },
  });
}

export function logout(args: { logoutTimestamp: Date }) {
  const { logoutTimestamp } = args;
  console.log('session.ts/logout : ', args);
  const sessionData = getSessionDataWithIncrimentedIndex();
  sessionData.logoutTimestamp = Timestamp.fromDate(logoutTimestamp);
  writeSessionData(sessionData);
}

export function getLogoutEvent(): TeacherEvent {
  const sessionData = getSessionData();
  if (sessionData.loginTimestamp === undefined) {
    throw new Error(
      `Login timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  if (sessionData.logoutTimestamp === undefined) {
    throw new Error(
      `logout timestamp can not be undefined in ${JSON.stringify(sessionData)}`
    );
  }
  // const logoutTimestamp = Timestamp.fromDate(logoutTimestamp);
  // const timespent: number = timeDifference(
  //   sessionData.loginTimestamp,
  //   sessionData.logoutTimestamp
  // );
  const timespent: number = timeDifference(
    sessionData.loginTimestamp,
    sessionData.logoutTimestamp
  );
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: sessionData.logoutTimestamp,
    eventIndex: sessionData.eventIndex,
    EventType: {
      case: 'logout',
      value: {
        timespent,
      },
    },
  });
}

export function initialiseChapterSession(args: {
  chapterSessionId: string;
  chapterId: number;
}) {
  const { chapterSessionId, chapterId } = args;
  const bookSessionData = getBookSessionData();
  bookSessionData.chapter = new BookSessionData_InteralSessionData({
    sessionId: chapterSessionId,
    id: chapterId.toString(),
  });
  saveMessageSession(BOOK_SESSION_KEY, bookSessionData);
}

export function removeChapterSession() {
  const bookSessionData = getBookSessionData();
  bookSessionData.chapter = undefined;
  saveMessageSession(BOOK_SESSION_KEY, bookSessionData);
}
