import { IStyles, deserify, pxToRem } from '@geneo2-web/shared-ui';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import { interactionEvent } from '../../../Auth/auth.events';
import { setDownloadResolution } from '../../reducer/offline.slice';

const styles: IStyles = {
  wrapper: {
    margin: '0 auto',
    textAlign: 'center',
  },
  heading: {
    marginTop: {
      xs: pxToRem(15),
      md: pxToRem(30),
    },
    marginBottom: {
      xs: pxToRem(15),
      md: pxToRem(20),
    },
  },
  settingsWrapper: {
    background: 'color.white',
    width: {
      md: '100%',
    },
    maxWidth: {
      md: pxToRem(670),
      xs: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    padding: {
      xs: pxToRem(15),
      md: pxToRem(15),
    },
    marginLeft: {
      xs: pxToRem(15),
      md: 'auto',
    },
    marginRight: {
      xs: pxToRem(15),
      md: 'auto',
    },
    borderRadius: '10px',
    boxShadow: '0 0 10px #00000029',
  },
  resolutionWrapper: {
    background: 'color.white',
    width: {
      md: '100%',
    },
    maxWidth: {
      md: pxToRem(670),
      xs: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    padding: {
      xs: pxToRem(15),
      md: pxToRem(15),
    },
    marginLeft: {
      xs: pxToRem(15),
      md: 'auto',
    },
    marginRight: {
      xs: pxToRem(15),
      md: 'auto',
    },
    borderRadius: '10px',
    boxShadow: '0 0 10px #00000029',
    marginBottom: {
      xs: pxToRem(20),
      md: pxToRem(25),
    },
    cursor: 'pointer',
  },
};

export default function DownloadSettings() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const [resolutionSelector, setResolutionSelector] = useState(false);
  const { downloadResolution } = deserify(
    useAppSelector((state) => state.offline)
  );
  const resolution = downloadResolution || 720;

  const renderBox = (
    key: React.ReactNode,
    val: React.ReactNode,
    isDashed?: boolean
  ) => {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          spacing={0}
          sx={{
            paddingY: {
              xs: pxToRem(20),
              md: pxToRem(20),
            },
            borderBottom: isDashed ? '1px solid #ddd' : undefined,
            alignItems: 'center',
          }}
        >
          <Grid item xs={5}>
            <Typography
              variant="elementH3"
              sx={{ textAlign: 'left', fontWeight: 'bold' }}
            >
              {key}
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
            <Typography variant="elementH3" color="#007CDC">
              {val}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="elementH1">
        <Box sx={styles.heading}>Download Settings</Box>
      </Typography>
      {resolutionSelector ? (
        <Box>
          {resolutions.map((res) => {
            const isTicked = res.quality == resolution;
            return (
              <Box
                sx={{
                  ...styles.resolutionWrapper,
                  border: isTicked ? '1px solid #007CDC' : undefined,
                }}
                onClick={async () => {
                  dispatch(setDownloadResolution(res.quality));
                  setResolutionSelector(false);
                  await interactionEvent({
                    url: '',
                    context: 'download_quality',
                    name: res.textBold.toUpperCase(),
                    pathSegments: pathSegments,
                  });
                }}
              >
                <Box sx={{ marginRight: '5px', fontWeight: 'bold' }}>
                  <Typography variant="elementBodyText">
                    {res.textBold}
                  </Typography>
                </Box>
                ({res.text})
                {isTicked ? (
                  <DoneIcon
                    sx={{
                      color: '#007CDC',
                      fontSize: {
                        xs: pxToRem(16),
                        md: pxToRem(22),
                      },
                      marginLeft: 'auto',
                      fontWeight: 'bold',
                    }}
                  />
                ) : null}
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box sx={styles.settingsWrapper}>
          {renderBox(
            <Box>Download Quality</Box>,
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setResolutionSelector(true);
              }}
            >
              {resolution}p
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

const resolutions = [
  { textBold: 'High', text: 'upto 1080p', quality: 1080 },
  { textBold: 'Medium', text: 'upto 720p', quality: 720 },
  { textBold: 'Low', text: 'upto 360p', quality: 360 },
];
