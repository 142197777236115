import { createInteractionButtonEvent } from '@events/teacher/eventsCreator/interactionButton';
import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

import { IInteractionButtonEventArgs } from '@events/teacher/eventsCreator/inputArgs';
import {
  getLoginEvent,
  getLogoutEvent,
  login,
  logout,
} from '@events/teacher/eventsCreator/session';
import { Resource_ResourceEnum } from '@protos/content_management/content.db_pb';
import {
  ADD_RESOURCES,
  ANALYTICS_CHAPTER_SCORE,
  ANALYTICS_CLASS_SCORE,
  ANALYTICS_CLASS_SELECTION,
  ANALYTICS_STUDENT_SCORE,
  CHANGE_PASSWORD,
  DISCLAIMER,
  DOWNLOAD_SETTINGS,
  EDIT_LESSON_PLAN,
  ENTER_UID,
  FAQ,
  HELP,
  HOME,
  HOMEWORK_ASSIGN,
  HOMEWORK_CHAPTER_SELECTION,
  HOMEWORK_COMPLETED,
  HOMEWORK_CONGRATULATIONS,
  HOMEWORK_CREATE,
  HOMEWORK_CURRENT,
  HOMEWORK_CURRENT_CUSTOM,
  HOMEWORK_MANAGE,
  HOMEWORK_ONGOING,
  HOMEWORK_PERFORMANCE,
  HOMEWORK_QUESTIONBANK,
  HOMEWORK_REVIEW,
  HOMEWORK_SUBMISSION_CUSTOM,
  HOMEWORK_TOPIC_SELECTION,
  HOMEWORK_VIEW,
  LESSON_PLAN_LISTS,
  NOTIFICATION,
  OFFLINE_LESSON_PLAN_LISTS,
  OFFLINE_RESOURCE,
  OFFLINE_TEACHING_FLOW,
  OFFLINE_TEACH_CHAPTER_SELECTION,
  OFFLINE_TEACH_TOPIC_SELECTION,
  OTP_SCREEN,
  PROFILE,
  REPLACE_RESOURCE,
  RESOURCE_PAGE,
  SEEALL_LESSON_PLAN_LIST,
  SELF_REGISTRATION,
  SELF_REGISTRATION_FORM,
  TEACHING_FLOW,
  TEACH_CHAPTER_SELECTION,
  TEACH_TOPIC_SELECTION,
  USER_NAME_REGISTRATION,
  VERIFY_DETAILS,
} from '../../routeHandling/RoutesNomenclature';

//* --------------- Login Events ---------------
interface loginProps {
  teacherId: number;
  sessionId: string;
}
export const aiLoginEvent = async (props: loginProps) => {
  const { teacherId, sessionId } = props;

  try {
    login({
      teacherId: teacherId,
      sessionId: sessionId,
      loginTimestamp: new Date(),
    });

    const loginEventData = getLoginEvent();
    if (loginEventData.timestamp) {
      await addEventRequest({
        key: loginEventData.timestamp?.toJsonString(),
        value: loginEventData.toJson(),
      });
    }

    // await TeacherEventsAPIServiceV1Client.teacherEventProcess(loginEventData);
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Logout Events ---------------
export const aiLogoutEvent = async () => {
  try {
    logout({ logoutTimestamp: new Date() });
    const logoutEvent = getLogoutEvent();
    if (logoutEvent.timestamp) {
      await addEventRequest({
        key: logoutEvent.timestamp?.toJsonString(),
        value: logoutEvent.toJson(),
      });
    }

    // await TeacherEventsAPIServiceV1Client.teacherEventProcess(logoutEvent);
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Interaction Events ---------------
interface InteractionArgs extends IInteractionButtonEventArgs {
  // url: string;
  // context: string;
  // name: string;
  pathSegments?: string[];
}
export const interactionEvent = async (props: InteractionArgs) => {
  console.log('props:', props);
  const { context, name, pathSegments } = props;
  let { url } = props;

  if (pathSegments) {
    url = getPathSegmentMapping(pathSegments) || '';
  } else if (!navigator.onLine) {
    url = `Offline_${url}`;
  }
  // console.log('interactionEvent:', { url: url, context: context, name: name });

  try {
    const interactionEventData = createInteractionButtonEvent({
      url: url,
      context: context,
      name: name,
      // timestamp: new Date(),
      lessonSessionId: props.lessonSessionId,
      resourceSessionId: props.resourceSessionId,
    });

    if (interactionEventData.timestamp) {
      await addEventRequest({
        key: interactionEventData.timestamp?.toJsonString(),
        value: interactionEventData.toJson(),
      });
    }

    // console.log('interactionEventData:', interactionEventData);
    // await TeacherEventsAPIServiceV1Client.teacherEventProcess(
    //   interactionEventData
    // );
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

// ---------------------------------------------------------

function getPathSegmentMapping(pathSegments: string[]) {
  const removeOfflinePrefix = (path: string) => {
    // console.log('path:', path);
    // console.log('path.replace:', path.replace('/offline/', ''));
    return path.replace('/offline/', '');
  };

  const pathMap = {
    //& ------------------------- AUTH -------------------------
    [ENTER_UID.slice(1)]: 'Teacher_login',
    [OTP_SCREEN.slice(1)]: 'Teacher_otp_screen',
    [VERIFY_DETAILS.slice(1)]: 'Teacher_self_registration_verify_details',
    [SELF_REGISTRATION.slice(1)]: 'Teacher_self_registration',
    [USER_NAME_REGISTRATION.slice(1)]: 'Teacher_registration_username',
    [SELF_REGISTRATION_FORM.slice(1)]: 'Teacher_self_registration_form',

    //Home
    [HOME.slice(1)]: 'Teacher_Home',
    [SEEALL_LESSON_PLAN_LIST.slice(1)]: 'Teacher_See_All_Lesson_Plan',

    // Homework
    [HOMEWORK_CHAPTER_SELECTION.slice(1)]: 'Teacher_hw_chapter_selection',
    [HOMEWORK_TOPIC_SELECTION.slice(1)]: 'Teacher_hw_topic_selection',
    [HOMEWORK_CREATE.slice(1)]: 'Teacher_homework_create',
    [HOMEWORK_MANAGE.slice(1)]: 'Teacher_homework_manage',
    [HOMEWORK_ASSIGN.slice(1)]: 'Teacher_hw_assign',
    [HOMEWORK_ONGOING.slice(1)]: 'Teacher_ongoing_homework',
    [HOMEWORK_COMPLETED.slice(1)]: 'Teacher_hw_completed',
    [HOMEWORK_REVIEW.slice(1)]: 'Teacher_hw_review',
    [HOMEWORK_QUESTIONBANK.slice(1)]: 'Teacher_hw_questionBank',
    [HOMEWORK_PERFORMANCE.slice(1)]: 'Teacher_hw_performance',
    [HOMEWORK_CONGRATULATIONS.slice(1)]: 'Teacher_hw_congratulations',
    [HOMEWORK_VIEW.slice(1)]: 'Teacher_hw_view',
    [HOMEWORK_CURRENT.slice(1)]: 'Teacher_current_homework',
    [HOMEWORK_CURRENT_CUSTOM.slice(1)]: 'Teacher_current_custom_homework',
    [HOMEWORK_SUBMISSION_CUSTOM.slice(1)]: 'Teacher_submission_custom_HW',

    //Analytics
    [ANALYTICS_CLASS_SELECTION.slice(1)]: 'Teacher_analytics_dashboard',
    [ANALYTICS_CLASS_SCORE.slice(1)]: 'Teacher_analytics_classScore',
    [ANALYTICS_CHAPTER_SCORE.slice(1)]: 'Teacher_analytics_chapterScore',
    [ANALYTICS_STUDENT_SCORE.slice(1)]: 'Teacher_analytics_studentScore',

    // Teach
    [TEACH_CHAPTER_SELECTION.slice(1)]: 'Teacher_teach_chapter_selection',
    [removeOfflinePrefix(OFFLINE_TEACH_CHAPTER_SELECTION)]:
      'Teacher_teach_chapter_selection',

    [TEACH_TOPIC_SELECTION.slice(1)]: 'Teacher_teach_topic_selection',
    [removeOfflinePrefix(OFFLINE_TEACH_TOPIC_SELECTION)]:
      'Teacher_teach_topic_selection',

    [LESSON_PLAN_LISTS.slice(1)]: 'Teacher_lesson_plan_lists',
    [removeOfflinePrefix(OFFLINE_LESSON_PLAN_LISTS)]:
      'Teacher_lesson_plan_lists',

    [TEACHING_FLOW.slice(1)]: 'Teacher_teaching_flow',
    [removeOfflinePrefix(OFFLINE_TEACHING_FLOW)]: 'Teacher_teaching_flow',

    [EDIT_LESSON_PLAN.slice(1)]: 'Teacher_edit_lesson',

    [ADD_RESOURCES.slice(1)]: 'Teacher_add_resources',

    [REPLACE_RESOURCE.slice(1)]: 'Teacher_replace_resource',

    [RESOURCE_PAGE.slice(1)]: 'Teacher_resource_page',
    [removeOfflinePrefix(OFFLINE_RESOURCE)]: 'Teacher_resource_page',

    // Sidebar
    [PROFILE.slice(1)]: 'Teacher_profile',
    [CHANGE_PASSWORD.slice(1)]: 'Teacher_download_settings',
    [NOTIFICATION.slice(1)]: 'Teacher_notification',
    [HELP.slice(1)]: 'Teacher_help',
    [FAQ.slice(1)]: 'Teacher_faq',
    [DISCLAIMER.slice(1)]: 'Teacher_disclaimer',
    [DOWNLOAD_SETTINGS.slice(1)]: 'Teacher_download_settings',
  };

  for (const segment of pathSegments) {
    if (pathMap[segment]) {
      if (!navigator.onLine) return `Offline_${pathMap[segment]}`;
      else return pathMap[segment];
    }
  }
}

// ENTER_UID,
// CHOOSE_PROFILE,
// LOGIN,
// LOGIN_PASSWORD,
// OTP_SCREEN,REGISTRATION,
// RESET_PASSWORD,
// RESET_PASSWORD_OTP,
// SET_PASSWORD,VERIFY_DETAILS,
// VERIFY_EMAIL,
// VERIFY_PHONE,

// ---------------------------------------------------------

const resourceTypeMap: { [key: number]: string } = {
  0: 'RESOURCE_TYPE_UNDEFINED',
  1: 'RESOURCE_TYPE_VIDEO',
  2: 'RESOURCE_TYPE_AUDIO',
  3: 'RESOURCE_TYPE_READING',
  4: 'RESOURCE_TYPE_QUESTION_SET',
  5: 'RESOURCE_TYPE_QUESTION',
  6: 'RESOURCE_TYPE_PRACTICE',
  7: 'RESOURCE_TYPE_TEST',
  8: 'RESOURCE_TYPE_ACTIVITY',
  9: 'RESOURCE_TYPE_SIMULATION',
  10: 'RESOURCE_TYPE_EXPLORIMENT',
  11: 'RESOURCE_TYPE_GAME',
  12: 'RESOURCE_TYPE_AR_VR',
  13: 'RESOURCE_TYPE_PPT',
  14: 'RESOURCE_TYPE_E_BOOK',
  15: 'RESOURCE_TYPE_PDF',
  16: 'RESOURCE_TYPE_FLASHCARDS',
  17: 'RESOURCE_TYPE_MINDMAP',
};

export const getResourceType = (number: number): string => {
  return Resource_ResourceEnum[number] || Resource_ResourceEnum[0];
};

// ---------------------------------------------------------

/*
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  await interactionEvent({
    url: '',
    context: 'context',
    name: 'name',
    pathSegments: pathSegments,
  });

 */
