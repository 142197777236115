import {
  bookClose,
  bookOpen,
  clearBookData,
  getBookCloseEvent,
  getBookOpenEvent,
  isBookOpen,
} from '@events/teacher/eventsCreator/book';
import {
  initialiseBookSession,
  removeBookSession,
} from '@events/teacher/eventsCreator/session';

import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

//* --------------- Book Events ---------------
export const aiBookOpenEvent = async (
  bookId: number | undefined,
  bookSessionId: string
) => {
  try {
    if (bookId && !isBookOpen({ bookId: bookId })) {
      bookOpen({ openTimestamp: new Date(), bookId: bookId });
      initialiseBookSession({
        bookId: bookId,
        bookSessionId: bookSessionId,
      });

      const bookOpenEvent = getBookOpenEvent({ bookId: bookId });
      if (bookOpenEvent.timestamp) {
        await addEventRequest({
          key: bookOpenEvent.timestamp?.toJsonString(),
          value: bookOpenEvent.toJson(),
        });
      }
      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(bookOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiBookCloseEvent = async (bookId: number | undefined) => {
  try {
    if (bookId && isBookOpen({ bookId: bookId })) {
      bookClose({ closeTimestamp: new Date(), bookId: bookId });
      const bookCloseEvent = getBookCloseEvent({ bookId: bookId });
      if (bookCloseEvent.timestamp) {
        await addEventRequest({
          key: bookCloseEvent.timestamp?.toJsonString(),
          value: bookCloseEvent.toJson(),
        });
      }

      removeBookSession();
      clearBookData({ bookId: bookId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(bookCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
