import { OutlinedTextFieldProps, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';

interface IProps extends OutlinedTextFieldProps {
  id?: string;

  // boldtext?: boolean;
  // nonCircular?: boolean;
  // borderWidth?: number;
  // fontSize?: number;
  // fontColor?: string;
  // borderColor?: string;
}

export const NumericalInputField = forwardRef((props: IProps, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <TextField
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      type="number"
      inputProps={{
        ref: ref,
        inputMode: 'numeric',
        pattern: '[0-9]*',
      }}
      // InputProps={
      //   boldtext === true
      //     ? { style: { fontWeight: '700', color: 'black' } }
      //     : { style: { color: 'black' } }
      // }
      label={null}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      autoComplete="off"
      {...props}
      onKeyDown={(evt) => {
        let checkIfNum = false;
        if (evt.key !== undefined) {
          // Check if it's a "e", ".", "+" or "-"
          checkIfNum =
            evt.key === 'e' ||
            evt.key === '.' ||
            evt.key === '+' ||
            evt.key === '-';
        }
        return checkIfNum && evt.preventDefault();
      }}
      onPaste={(e) => {
        e.preventDefault();
        return false;
      }}
      onCopy={(e) => {
        e.preventDefault();
        return false;
      }}
      sx={{
        width: { xs: pxToRem(150), md: pxTovW(130) },
        height: { md: pxTovW(46) },

        '& input': {
          width: { md: pxTovW(130) },
          height: { md: pxTovW(46) },
          // padding: 0,
          textAlign: 'center',
          color: 'secondary.main',
          boxShadow: `0px ${pxTovW(3)} ${pxTovW(6)} grey`,
          borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
        },
        '& input[type=number]': {
          '-moz-appearance': 'textfield' /* Firefox */,
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
        borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
        '& .MuiOutlinedInput-root': {
          fontSize: { xs: pxToRem(30), md: pxTovW(30) },
          fontWeight: '700',
          color: 'secondary.main',
          padding: '0',
          border: `${pxTovW(2)} solid red`,
          borderColor: isFocused ? 'secondary.main' : '#EEEEEE',
          // borderColor: 'neutral.cornflowerBlue',
          borderRadius: { xs: pxToRem(8), md: pxTovW(8) },
        },
        ...props.sx,
      }}
    />
  );
});
