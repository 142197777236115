import { Timestamp } from '@bufbuild/protobuf';
import { IClassAndSubjectSelected } from '@geneo2-web/shared-ui';
import {
  DifficultyLevelEnum,
  McqMultipleContentModel,
  Question,
  Question_QuestionEnum,
} from '@protos/content_management/content.db_pb';
import { TeacherLoginResponseType } from '@protos/user_management/ums.login.apis_pb';
interface QuestionCounts {
  noOfLowQuestions: number;
  noOfMediumQuestions: number;
  noOfHighQuestions: number;
}

interface TimeRange {
  min: number;
  max: number;
}

export const calculateMinMaxTime = (
  questionsCount: QuestionCounts
): TimeRange => {
  // const lowTimeRange: TimeRange = { min: 1, max: 2 };
  // const mediumTimeRange: TimeRange = { min: 3, max: 4 };
  // const highTimeRange: TimeRange = { min: 5, max: 6 };

  const lowTimeRange: TimeRange = { min: 0.5, max: 1 };
  const mediumTimeRange: TimeRange = { min: 1, max: 2 };
  const highTimeRange: TimeRange = { min: 2, max: 4 };

  const totalMinTime =
    questionsCount.noOfLowQuestions * lowTimeRange.min +
    questionsCount.noOfMediumQuestions * mediumTimeRange.min +
    questionsCount.noOfHighQuestions * highTimeRange.min;

  const totalMaxTime =
    questionsCount.noOfLowQuestions * lowTimeRange.max +
    questionsCount.noOfMediumQuestions * mediumTimeRange.max +
    questionsCount.noOfHighQuestions * highTimeRange.max;

  return { min: totalMinTime, max: totalMaxTime };
};

export const totalNoQuestions = (questionsCount: QuestionCounts): number => {
  const totalQuestions =
    questionsCount.noOfLowQuestions +
    questionsCount.noOfMediumQuestions +
    questionsCount.noOfHighQuestions;
  return totalQuestions;
};

export const toDifficultyEnum = (value: string | null): DifficultyLevelEnum => {
  switch (value) {
    case 'Easy':
      return DifficultyLevelEnum.DIFFICULTY_LEVEL_LOW;
    case 'Medium':
      return DifficultyLevelEnum.DIFFICULTY_LEVEL_MEDIUM;
    case 'Hard':
      return DifficultyLevelEnum.DIFFICULTY_LEVEL_HIGH;
    default:
      return DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED;
  }
};

export const toStringDifficulty = (
  difficulty: string | DifficultyLevelEnum
): string => {
  switch (difficulty) {
    case 'DIFFICULTY_LEVEL_LOW':
      return 'Easy';
    case 'DIFFICULTY_LEVEL_MEDIUM':
      return 'Medium';
    case 'DIFFICULTY_LEVEL_HIGH':
      return 'Hard';
    default:
      return 'DIFFICULTY_LEVEL_UNDEFINED';
  }
};

export const difficultyValuesArray = Object.values(DifficultyLevelEnum)
  .filter((e) => typeof e === 'string' && e !== 'DIFFICULTY_LEVEL_UNDEFINED')
  .map((value) => toStringDifficulty(value));

export const toQuestionEnum = (
  value?: string | null
): Question_QuestionEnum => {
  if (!value) {
    return Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;
  }
  switch (value) {
    case 'Fill in the Blanks':
      return Question_QuestionEnum.QUESTION_TYPE_FIB;
    case 'Match the Following':
      return Question_QuestionEnum.QUESTION_TYPE_MTF;
    case 'Multiple Choice Single Answer':
      return Question_QuestionEnum.QUESTION_TYPE_MCQS;
    case 'Multiple Choice Multiple Answers':
      return Question_QuestionEnum.QUESTION_TYPE_MCQM;
    case 'True/False':
      return Question_QuestionEnum.QUESTION_TYPE_TF;
    case 'Short Answer':
      return Question_QuestionEnum.QUESTION_TYPE_SHORT;
    case 'Long Answer':
      return Question_QuestionEnum.QUESTION_TYPE_LONG;
    case 'Arrange in Order':
      return Question_QuestionEnum.QUESTION_TYPE_ARRANGE;
    case 'Very Short Answer':
      return Question_QuestionEnum.QUESTION_TYPE_VERY_SHORT;
    case 'Label':
      return Question_QuestionEnum.QUESTION_TYPE_LABEL;
    default:
      return Question_QuestionEnum.QUESTION_TYPE_UNDEFINED;
  }
};

export const toStringQuestion = (
  questionType: string | Question_QuestionEnum
): string => {
  switch (questionType) {
    case 'QUESTION_TYPE_FIB':
      return 'Fill in the Blanks';
    case 'QUESTION_TYPE_MTF':
      return 'Match the Following';
    case 'QUESTION_TYPE_MCQS':
      return 'Multiple Choice Single Answer';
    case 'QUESTION_TYPE_MCQM':
      return 'Multiple Choice Multiple Answers';
    case 'QUESTION_TYPE_TF':
      return 'True/False';
    case 'QUESTION_TYPE_SHORT':
      return 'Short Answer';
    case 'QUESTION_TYPE_LONG':
      return 'Long Answer';
    case 'QUESTION_TYPE_ARRANGE':
      return 'Arrange in Order';
    case 'QUESTION_TYPE_VERY_SHORT':
      return 'Very Short Answer';
    case 'QUESTION_TYPE_LABEL':
      return 'Label';
    default:
      return 'QUESTION_TYPE_UNDEFINED';
  }
};

export const questionValuesArray = Object.values(Question_QuestionEnum)
  .filter((e) => typeof e === 'string' && e !== 'QUESTION_TYPE_UNDEFINED')
  .filter((value) => {
    switch (value) {
      case 'QUESTION_TYPE_MTF':
      case 'QUESTION_TYPE_ARRANGE':
      case 'QUESTION_TYPE_LABEL':
        return false; // Exclude the commented values
      default:
        return true;
    }
  })
  .map((value) => toStringQuestion(value));

// export const combineStartTimeAndDate = (startTimeObj: {
//   timeValue: string;
//   dateValue: string;
// }): string => {
//   const { timeValue, dateValue } = startTimeObj;
//   // Extract the year, month, and day from the startDate
//   const startDateObj = new Date(dateValue);
//   const year = startDateObj.getFullYear();
//   const month = startDateObj.getMonth() + 1; // Months are zero-based, so we add 1
//   const day = startDateObj.getDate();
//   // Parse the hours and minutes from the startTime
//   const [hours, minutes] = timeValue.split(':').map(Number);
//   // Create a new Date object with the combined components
//   const combinedDate = new Date(year, month - 1, day, hours, minutes);
//   // Get the ISO timestamp
//   const isoTimestamp = combinedDate.toISOString();
//   return isoTimestamp;
// };
export const combineStartTimeAndDate = (startTimeObj: {
  timeValue: string;
  dateValue: string;
}): string => {
  const { timeValue, dateValue } = startTimeObj;
  // Parse the provided dateValue as an ISO string (assumed to be in IST)
  const startDateObj = new Date(dateValue);
  // Extract the year, month, day, hours, and minutes from the startDate
  const year = startDateObj.getUTCFullYear();
  const month = startDateObj.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const day = startDateObj.getUTCDate();
  const hours = startDateObj.getUTCHours();
  const minutes = startDateObj.getUTCMinutes();
  // Parse the hours and minutes from the startTime
  const [timeHours, timeMinutes] = timeValue.split(':').map(Number);
  // Create a new Date object with the combined components
  const combinedDate = new Date(year, month - 1, day, timeHours, timeMinutes);
  // Get the ISO timestamp in IST
  const isoTimestamp = combinedDate.toISOString();
  return isoTimestamp;
};

export function convertIsoStringToTimestamp(isoDateString: string): Timestamp {
  // Parse the ISO date string into a JavaScript Date object
  const dateObject = new Date(isoDateString);
  // Get the Unix timestamp (milliseconds since January 1, 1970)
  const unixTimestampMs = dateObject.getTime();
  // Create a google.protobuf.Timestamp object using the Unix timestamp
  const timestamp = new Timestamp();
  timestamp.seconds = BigInt(Math.floor(unixTimestampMs / 1000));
  timestamp.nanos = (unixTimestampMs % 1000) * 1000000;
  return timestamp;
}
export function convertTimeStringToTimestamp(timeString: string): Timestamp {
  // Split the time string to extract the hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);
  // Create a JavaScript Date object with the current date and extracted hours and minutes
  const currentDate = new Date();
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  // Get the Unix timestamp (milliseconds since January 1, 1970)
  const unixTimestampMs = currentDate.getTime();

  // Create a google.protobuf.Timestamp object using the Unix timestamp
  const timestamp = new Timestamp();
  timestamp.seconds = BigInt(Math.floor(unixTimestampMs / 1000));
  timestamp.nanos = (unixTimestampMs % 1000) * 1000000;
  return timestamp;
}
//

// export const findSectionIdFromClassSection = (
//   user_info?: TeacherLoginResponseType,
//   class_subject_info?: IClassAndSubjectSelected,
//   section?: string,
//   classId?: number
// ) => {
//   let sectionId: number | undefined;

//   user_info?.teachClassSubjects.forEach((classData) => {
//     classData.subjects.forEach((subjectData) => {
//       if (
//         classData?.section === section &&
//         class_subject_info?.classId === classId
//       ) {
//         sectionId = class_subject_info?.sectionId;
//       }
//     });
//   });

//   return sectionId;
// };
interface Iprops {
  userInfo?: TeacherLoginResponseType;
  sectionId?: number;
  classId?: number;
  subjectId?: number;
}
export const findClassSubjectInfoFromClassSection = (props: Iprops) => {
  const { userInfo, sectionId, classId, subjectId } = props;
  let classSubjectInfo: IClassAndSubjectSelected | undefined;
  userInfo?.teachClassSubjects.forEach((classData) => {
    classData.subjects.forEach((subjectData) => {
      if (
        classData.classId === classId &&
        classData.sectionId === sectionId &&
        subjectData.subjectId === subjectId
      ) {
        classSubjectInfo = {
          classname: classData.className,
          section: classData.sectionName,
          sectionId: classData.sectionId,
          subject: subjectData.subjectName,
          subjectId: subjectData.subjectId,
          classId: classData.classId,
          icon: subjectData.iconUrl,
          bookId: subjectData.bookId,
          color: subjectData.textColor,
        };
      }
    });
  });

  return classSubjectInfo;
};

export const calculateMarksMCQM = (
  questionObj: Question,
  latestResponse: { answer: string[] }
): number | undefined => {
  if (!questionObj && !latestResponse) {
    return;
  }
  const questionContentModel = questionObj.question?.model.value;
  const questionCase = questionObj.question?.model.case;
  if (questionCase !== 'mcqMultipleContentModel') {
    console.error(`Invalid question model case.`);
    return;
  }
  function distributeMarks() {
    if (
      questionContentModel instanceof McqMultipleContentModel &&
      questionContentModel.commonQuestionContent
    ) {
      if (
        questionContentModel.correct.length ===
        questionContentModel.commonQuestionContent?.marks.length
      ) {
        return questionContentModel.commonQuestionContent.marks;
      } else if (
        questionContentModel.commonQuestionContent?.marks.length === 1
      ) {
        const correctLength = questionContentModel.correct.length;
        return questionContentModel.correct.map(
          () =>
            (questionContentModel.commonQuestionContent?.marks[0] ?? 0) /
            correctLength
        );
      }
      console.error(
        `Marks can be specified for each option or as total marks to be equally distributed for the options. INVALID_DATA`
      );
      return;
    }
    console.error(`Invalid question model case.`);
    return;
  }

  const marksDist = distributeMarks();
  if (marksDist === undefined) {
    return;
  }
  if (questionContentModel instanceof McqMultipleContentModel) {
    const marks =
      latestResponse && questionContentModel && questionContentModel.correct
        ? questionContentModel.correct.reduce(
            (acc, curr, i: number) =>
              latestResponse.answer.includes(
                String.fromCharCode(64 + parseInt(curr))
              )
                ? acc + marksDist[i]
                : acc,
            0
          )
        : 0;

    let negativeMarks = 0;

    const correctOptionWeightage = marksDist[0];

    if (latestResponse && latestResponse.answer) {
      negativeMarks = latestResponse.answer.reduce(
        (acc, opt) =>
          questionContentModel.correct.includes(
            (opt.charCodeAt(0) - 64).toString()
          )
            ? acc
            : acc + correctOptionWeightage,
        0
      );
    }
    return Math.max(0, Math.round(marks - negativeMarks));
  }
  return;
};
