import {
  ConnectedClassIndicator,
  deserify,
  theme,
} from '@geneo2-web/shared-ui';
import {
  McqMultipleContentModel,
  McqSingleContentModel,
  PageContentInfo,
  Question,
  TfContentModel,
} from '@protos/content_management/content.db_pb';
import { useAppSelector } from 'apps/teacher/src/reduxStore/reduxHooks';
import { calculateMarksMCQM } from 'apps/teacher/src/utils/functions';
import { convertAnswerOptionstoNumbers } from './responseList';
import { useConnectedClassContext } from 'apps/teacher/src/app/Context/ConnectedClassContextProvider';
import { useMediaQuery } from '@mui/material';
interface Iprops {
  pageContent?: PageContentInfo;
  isAvailable?: boolean;
}
export const ConnectedClassHeader = (props: Iprops) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { pageContent, isAvailable } = props;
  const { questionSessionData, baseStationData } = useConnectedClassContext();
  const { students_data } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const studentList = students_data?.map((student) => {
    const response = questionSessionData?.responses.find(
      (e) => e.keypadSn === student.clickerSerialNo
    );
    return {
      ...student,
      response: response, // Add the response data to each student object
    };
  });

  const question = pageContent?.contents[0].model.value as Question;
  const maxMarks = question.question
    ? question?.question.model.value?.commonQuestionContent?.marks.reduce(
      (acc, curr) => acc + curr,
      0
    ) || 0
    : 0;
  const correctAnswer =
    question.question?.model.value instanceof TfContentModel ||
      question.question?.model.value instanceof McqSingleContentModel ||
      question.question?.model.value instanceof McqMultipleContentModel
      ? question.question.model.value.correct
      : undefined;

  const totalStudents = studentList?.length || 0;
  const totalJoined =
    baseStationData?.keyPads.filter((keypad) => keypad.isOnline).length || 0;
  const totalAnswered =
    studentList?.filter((student) => student.response).length || 0;
  const totalCorrectAnswers =
    studentList?.filter(
      (student) =>
        student.response?.response &&
        convertAnswerOptionstoNumbers(student.response?.response) ===
        correctAnswer
    ).length || 0;
  const totalResponseTime =
    studentList?.reduce(
      (acc, student) => acc + (student.response?.timeSpent || 0),
      0
    ) || 0;
  const averageTime = totalAnswered
    ? Math.round((60 * totalResponseTime) / totalAnswered)
    : 0;
  const averageMarks = totalAnswered
    ? ((totalCorrectAnswers / totalAnswered) * maxMarks).toFixed(2)
    : 0;
  const totalMarksForMCQM =
    question?.question?.model.case === 'mcqMultipleContentModel'
      ? studentList?.reduce((acc, curr) => {
        const response = curr.response?.response;
        if (response) {
          const marks =
            calculateMarksMCQM(question, { answer: response.split('') }) ?? 0;
          return acc + marks;
        }
        return acc;
      }, 0)
      : undefined;
  const averageMarksforMcqM =
    totalMarksForMCQM && totalAnswered
      ? (totalMarksForMCQM / totalAnswered).toFixed(2)
      : 0;
  const totalCorrectAnswersForMcqM =
    question?.question?.model.case === 'mcqMultipleContentModel'
      ? studentList?.filter((student) => {
        const response = student.response?.response;
        return (
          response &&
          calculateMarksMCQM(question, { answer: response.split('') }) ===
          maxMarks
        );
      }).length
      : undefined;
  return (
    <>
      <ConnectedClassIndicator
        variant={isMobile ? "connectedmobile" : "connected"}
        fullwidth
        isOnline={isAvailable}
        correct={totalCorrectAnswersForMcqM !== undefined
          ? totalCorrectAnswersForMcqM
          : totalCorrectAnswers}
        totalJoined={totalJoined}
        totalAnswered={totalAnswered}
        totalStudents={totalStudents}
        averageTime={averageTime}
        averagemarks={totalMarksForMCQM !== undefined
          ? averageMarksforMcqM
          : averageMarks}
      />
    </>
  )
}
