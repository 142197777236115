import { Code } from '@bufbuild/connect';
import {
  HelpAndSupportText,
  IStyles,
  ImageWrapper,
  InfoPopup,
  InputFieldContainer,
  Loader,
  PrimaryButton,
  deserify,
  isValidMobileNumber,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import { OTPEnum, ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  ENTER_UID,
  OTP_SCREEN,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../auth.events';
import {
  setLoginPhone,
  setOtpInfo,
  setSchoolData,
  setSelfRegistrationData,
  setSelfRegistrationFlow,
  setToastInfo,
} from '../reducer/auth.slice';

const styles: IStyles = {
  registerBox: {
    // height: { xs: '80vh', md: 'unset' },
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: { xs: 'flex-start', md: 'center' },
    alignItems: 'center',
    mt: { xs: pxToRem(0), md: pxTovW(40) },
  },
  containerTop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(40) },
  },
  containerBottom: {
    width: { xs: '100%', md: pxTovW(517) },
    height: { xs: pxToRem(55), md: pxTovW(77) },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(25), md: pxTovW(65) },
    marginTop: { xs: pxToRem(75), md: pxTovW(79) },
    marginBottom: { md: pxTovW(163) },
  },
  supportBox: {
    position: { xs: 'absolute', md: 'unset' },
    bottom: { xs: 0, md: 0 },
    width: { xs: '100%', md: '100%' },
    bgcolor: 'neutral.turquoise',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: { xs: pxToRem(25), md: pxTovW(25) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(10)}`,
      md: `${pxTovW(20)} ${pxTovW(10)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    boxSizing: 'border-box',
    marginBottom: { xs: pxToRem(15), md: 0 },
  },
};

export const SelfRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const { setSelectedFunction } = useGlobalContext();

  const { UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler } =
    useCommonServiceClientContext();
  const { self_registration_data } = deserify(
    useAppSelector((state) => state.auth)
  );

  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [credential, setCredential] = useState('');
  const [credentialError, setCredentialError] = useState<string>('');
  const [actionPopupState, setActionPopupState] = useState(false);
  const [accountExist, setAccountExist] = useState(false);

  const handleCredentialChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountExist(false);

    if (/^\d*$/.test(event.target.value)) {
      setCredential(event.target.value);
    }

    if (event.target.value.length > 10) {
      setCredentialError('Please Enter only 10 Digits');
    } else if (
      event.target.value.length === 10 &&
      !isValidMobileNumber(event.target.value)
    ) {
      setCredentialError('Please Enter Valid Mobile Number');
    } else setCredentialError('');
  };

  // dispatch(setLoginPhone(''));
  const nextClickHandler = async () => {
    try {
      const response =
        await UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler.validateUserSelfRegistration(
          { phoneNumber: credential }
        );

      if (response.status === 200) {
        setLoading('completed');

        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: response.verificationCode,
            otp_type: OTPEnum.TEACHER_SELF_REGISTRATION,
          })
        );
        dispatch(setSelfRegistrationFlow(true));
        dispatch(setLoginPhone(credential));
        dispatch(
          setSelfRegistrationData({
            ...self_registration_data,
            phone_number: credential,
            role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          })
        );
        navigate(OTP_SCREEN);
        await interactionEvent({
          url: '',
          context: 'navigation_bar',
          name: 'NEXT',
          pathSegments: pathSegments,
        });
      }
    } catch (error: any) {
      console.log('error:', error);

      if (error.code === Code.AlreadyExists) {
        setAccountExist(true);
        dispatch(setLoginPhone(credential));
      } else {
        dispatch(
          setToastInfo({
            label: 'Self Registration failed.',
            variant: 'error',
            open: true,
          })
        );
        setLoading('error');
      }
    }
  };

  const backButtonClick = () => {
    dispatch(setSelfRegistrationFlow(false));
    dispatch(setSelfRegistrationData(undefined));
    navigate(ENTER_UID);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);

    dispatch(setSelfRegistrationFlow(true));
    if (self_registration_data?.phone_number)
      setCredential(self_registration_data?.phone_number);

    dispatch(setSelfRegistrationData(undefined));
    dispatch(setSchoolData(undefined));

    return () => {
      setSelectedFunction(null);
    };
  }, []);

  if (loading === 'loading') return <Loader />;
  return (
    <Box
      sx={{
        height: { xs: '100vh', md: 'unset' },
        position: 'relative',
        marginY: { md: 'auto' },
      }}
    >
      {/* <Box
        onClick={backButtonClick}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: { xs: 'unset', md: 'absolute' },
          top: { xs: 0, md: '-5%' },
          left: { xs: 0, md: '-8%' },
        }}
      >
        <KeyboardBackspaceIcon fontSize="large" sx={{ cursor: 'pointer' }} />
      </Box> */}

      <Box sx={styles.registerBox}>
        <Box sx={styles.containerTop}>
          <Box sx={{ textAlign: { md: 'center' } }}>
            <Typography variant="h1">Start Your</Typography>
            <Typography variant="h1" color="primary.main" fontWeight="bold">
              Teaching Journey
            </Typography>
          </Box>

          {accountExist && (
            <Box
              sx={{
                backgroundColor: 'error.light',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: { xs: pxToRem(15), md: pxTovW(15) },
              }}
            >
              <Typography variant="h4" color="neutral.crimson">
                Account already exists.
              </Typography>

              <Typography
                variant="h5"
                color="neutral.crimson"
                onClick={async () => {
                  dispatch(setSelfRegistrationFlow(false));
                  dispatch(setSelfRegistrationData(undefined));
                  navigate(ENTER_UID);
                }}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                Click here to login
              </Typography>
            </Box>
          )}

          <InputFieldContainer
            helperTextvariant="error"
            topLabel="Enter 10 Digit Phone Number"
            value={credential}
            onChange={handleCredentialChange}
            helperText={credentialError}
            onEnter={() =>
              !accountExist &&
              credential.length === 10 &&
              isValidMobileNumber(credential) &&
              nextClickHandler()
            }
          />
        </Box>

        <Box sx={styles.containerBottom}>
          <PrimaryButton
            fullWidth
            onClick={() => nextClickHandler()}
            disabled={
              accountExist ||
              credential.length !== 10 ||
              !isValidMobileNumber(credential)
            }
          >
            <Typography variant="h3" fontWeight="bold" color="common.white">
              Next
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
      <Box
        sx={styles.supportBox}
        onClick={async () => {
          await interactionEvent({
            url: '',
            context: 'navigation_bar',
            name: 'HELP&SUPPORT',
            pathSegments: pathSegments,
          });
          setActionPopupState(true);
        }}
      >
        <ImageWrapper
          name="loginSupport"
          parentFolder="icons"
          type="png"
          styles={{
            height: { xs: pxToRem(20), md: pxTovW(25) },
            width: { xs: pxToRem(16), md: pxTovW(20) },
          }}
        />
        <Typography variant="linkText">Help & Support</Typography>
      </Box>

      <InfoPopup
        iconName="support2"
        popupText={[
          <Typography variant="h2" fontWeight="bold">
            Help & Support
          </Typography>,
          <HelpAndSupportText />,
        ]}
        background="#007CDC"
        handleClose={() => setActionPopupState(false)}
        open={actionPopupState}
      />
    </Box>
  );
};
