import {
  HelpAndSupportText,
  IStyles,
  ImageWrapper,
  InfoPopup,
  InputFieldContainer,
  Loader,
  PrimaryButton,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography } from '@mui/material';
import {
  LoginTypeEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useAppDispatch } from '../../../../reduxStore/reduxHooks';
import {
  LOGIN_PASSWORD,
  VERIFY_DETAILS,
} from '../../../../routeHandling/RoutesNomenclature';
import { useAuthentication } from '../../../../utils/customHooks';
import ConfigSelector from '../../../OfflineScreens/Teach/components/ConfigSelector';
import {
  SetUserValidationInfo,
  setLoginUID,
  setToastInfo,
} from '../../reducer/auth.slice';

const styles: IStyles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    justifyContent: { xs: 'start', md: 'center' },
    // backgroundColor: 'blue',
    // gap: { xs: pxToRem(90) },
  },
  topBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(75), md: pxTovW(49) },
  },
  containerTop: {
    width: '100%',
    // flexBasis: { xs: pxToRem(300) },
    // flexGrow: { md: 1 },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(41) },
  },
  containerBotton: {
    width: { xs: '100%', md: pxTovW(517) },
    // marginBottom: { xs: pxToRem(80), md: pxTovW(0) },
    display: 'flex',
    alignSelf: 'center',
    gap: { xs: pxToRem(60), md: pxTovW(65) },
    mb: { md: pxTovW(161) },
  },
  supportBox: {
    position: { xs: 'absolute', md: 'absolute' },
    bottom: { xs: 0, md: 0 },
    width: '100%',
    bgcolor: 'neutral.turquoise',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: { xs: pxToRem(25), md: pxTovW(25) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(10)}`,
      md: `${pxTovW(20)} ${pxTovW(10)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
  },
};
export default function EnterUserIdRegistration() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [credential, setCredential] = useState('');
  const [credentialError, setCredentialError] = useState<string>('');
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const [actionPopupState, setActionPopupState] = useState(false);
  const { setSelectedFunction } = useGlobalContext();
  const backButtonClick = async () => {
    navigate('/');
  };
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { isOffline } = useDownloadContext();

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);
  const isAuthenticated = useAuthentication();

  const handleCredentialChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCredential(event.target.value);
    setCredentialError('');
  };

  const validateInputs = (): boolean => {
    if (!credential.trim()) {
      setCredentialError(
        'Please enter a valid username, phone number, or email.'
      );
      return false;
    }

    setCredentialError('');
    return true;
  };
  async function validationRequest() {
    setLoading('loading');
    try {
      const loginType = LoginTypeEnum.LOGIN_TYPE_USERNAME;

      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.validateUser({
          loginType,
          userLoginInput: credential,
          role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        });
      // to reset activation status for users for testing the flow
      // const response2 = await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile({
      //   teacherProfileId: response.data[0].profileId,
      //   isProfileVerified: false,
      //   isActive: 0,
      //   isPasswordUpdated: false,
      // });
      if (response.status === 200 && response.data.length > 0) {
        setLoading('completed');
        dispatch(SetUserValidationInfo(response.data[0]));

        if (response.data[0].isActive) {
          dispatch(setLoginUID(credential));
          dispatch(
            setToastInfo({
              label: `You're already registered. Please log in.`,
              variant: 'error',
              open: true,
            })
          );
          navigate(LOGIN_PASSWORD);
        } else {
          // if (response.data[0].isProfileVerified === true) {
          //   navigate(SET_PASSWORD);
          // } else {
          navigate(VERIFY_DETAILS);
          // }
        }
        console.log(response);
      } else {
        setLoading('error');
        setCredentialError('Enter valid username');
      }
    } catch (err) {
      setLoading('error');
      setCredentialError('Enter valid username');
      console.log(err);
    }
  }
  const startClickHandler = () => {
    // console.log('hi');
    validationRequest();
  };
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={styles.root}>
      <Box sx={styles.topBox}>
        <Box sx={styles.containerTop}>
          <Box sx={{ textAlign: { md: 'center' } }}>
            <Typography variant="h1">Start Your</Typography>
            <Typography variant="h1" color="primary.main">
              Teaching Journey
            </Typography>
          </Box>
          {isOffline && (
            <ConfigSelector
              rootStyle={{
                marginTop: 0,
                marginBottom: 0,
                width: '100%',
              }}
            />
          )}
          <Box>
            <InputFieldContainer
              helperTextvariant="error"
              topLabel="Enter Username"
              value={credential}
              onChange={handleCredentialChange}
              helperText={credentialError}
              onEnter={startClickHandler}
              // nonEditable={true}
            />
          </Box>
        </Box>
        <Box sx={styles.containerBotton}>
          <PrimaryButton
            fullWidth
            onClick={startClickHandler}
            disabled={credential === ''}
          >
            Start
          </PrimaryButton>
        </Box>
      </Box>
      <Box sx={styles.supportBox} onClick={() => setActionPopupState(true)}>
        <ImageWrapper
          name="loginSupport"
          parentFolder="icons"
          type="png"
          styles={{
            height: { xs: pxToRem(20), md: pxTovW(25) },
            width: { xs: pxToRem(16), md: pxTovW(20) },
          }}
        />
        <Typography variant="linkText">Help & Support</Typography>
      </Box>
      <InfoPopup
        iconName="support2"
        popupText={[
          <Box sx={{ marginTop: pxToRem(2) }}>
            <Typography variant="h2" fontWeight="bold">
              Help & Support
            </Typography>
          </Box>,
          <HelpAndSupportText />,
        ]}
        background="#007CDC"
        handleClose={() => setActionPopupState(false)}
        open={actionPopupState}
      />
    </Box>
  );
}
