import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Box } from '@mui/system';
import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import { ContentLockStatusType } from '@protos/school_management/school.db_pb';
import React, { useEffect } from 'react';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
import { IStyles } from '../../../commonUtils/styleUtils';
import ImageWrapper from '../../elements/ImageWrapper';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { AnyAction } from '@reduxjs/toolkit';

interface IlockedPopupData {
  image?: string;
  title?: string;
  noofTopics?: string;
}

const styles: IStyles = {
  cropPopupPaper: {
    borderRadius: '12px',
    padding: '16px',
    maxWidth: 'fit-content',
  },
  popupTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  popupContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // overflow: 'hidden',
  },
  popupImage: {
    width: '100%',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  radioGroupFormControlLabel: {
    marginRight: '24px',
  },
  popupActions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4px',
  },
};
// Define props interface
interface ContentLockPopupProps {
  role?: ProfileRolesEnum;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  selectedValue?: ContentLockStatusType;
  onRadioChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  lockDataObj?: IlockedPopupData;
  handleLockedCheckboxChange?: (check: boolean) => void;
  checked?: boolean;
}
const ContentLockPopup: React.FC<ContentLockPopupProps> = ({
  open,
  onClose,
  onSubmit,
  onRadioChange,
  selectedValue,
  role,
  lockDataObj,
  checked,
  handleLockedCheckboxChange,
}) => {
  const categoryType = (category: string) => {
    switch (category) {
      case 'MODULE_CATEGORY_CHAPTER':
        return Module_ModuleCategoryEnum.MODULE_CATEGORY_CHAPTER;
      case 'MODULE_CATEGORY_TOPIC':
        return Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC;
      default:
        return 'UNDEFINED';
    }
  };

  function getLockStatus(selectedValue: ContentLockStatusType | undefined) {
    switch (selectedValue) {
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED:
        return 'lock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED:
        return 'unlock';
      case ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED:
        return 'partial_lock';
      default:
        return 'unknown'; // You can define a default return value if needed
    }
  }
  useEffect(() => {
    console.log('lockDataObj', lockDataObj);
  }, [lockDataObj]);
  return (
    <Box
      sx={{
        borderRadius: '12px',
        position: 'fixed',
        top: '30%',
      }}
    >
      <Dialog
        open={open}
        onClose={(ev: React.MouseEvent) => {
          ev.stopPropagation();
          onClose();
        }}
        maxWidth="sm"
        sx={{
          '& .MuiDialog-paper': {
            position: 'relative', // Ensure relative positioning for the dialog
            width: '90%', // Example: 90% width on smaller screens
            maxWidth: '300px', // Example: Limit the max width to 600px
            padding: '6px',
            margin: 'auto', // Center the dialog
            borderRadius: '12px',
            overflow: 'initial', // Allow overflow for the dialog content
            '@media (min-width:1280px)': {
              maxWidth: '350px', // Adjust width for extra-large screens (lg and above)
              width: '50%', // Adjust width for extra-large screens (lg and above)
            },
            '@media (min-width:960px)': {
              width: '60%', // Adjust width for large screens
            },
            '@media (min-width:600px)': {
              width: '80%', // Adjust width for medium screens
            },
            '@media (max-width:600px)': {
              maxWidth: '270px',
            },
          },
        }}
      >
        <DialogContent
          sx={{
            '& .MuiDialogContent-root': {
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              maxWidth: '100px',
              alignItems: 'center',
              '@media (max-width:600px)': {
                padding: '8px',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: '12px',
              width: '100%',
            }}
          >
            <ImageWrapper
              name="chapterImage"
              type="png"
              parentFolder="tempAssets"
              styles={{
                height: { xs: pxToRem(68), md: pxTovW(121) },
                width: { xs: pxToRem(68), md: pxTovW(121) },
                objectFit: 'cover',
                borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
                opacity:
                  selectedValue ===
                  ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                    ? 0.5
                    : 1,
              }}
              path={lockDataObj?.image}
            />
            <Box
              sx={{
                width: '100%',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                wordBreak: 'break-word',
              }}
            >
              <Typography
                variant="h3" // Changed to a more appropriate variant for titles
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'start',
                  marginBottom: 2, // Using theme.spacing
                  display: '-webkit-box',
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  opacity:
                    selectedValue ===
                    ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                      ? 0.5
                      : 1,
                }}
              >
                {lockDataObj?.title}
              </Typography>

              {lockDataObj?.noofTopics && (
                <Typography
                  sx={{ mt: 1 }} // Using margin-top from MUI spacing
                  variant="body2" // body2 for supporting text, replace "cardText" if not defined in theme
                  color="primary"
                >
                  {lockDataObj?.noofTopics} Topics
                </Typography>
              )}
            </Box>
            <IconButton
              size="small"
              sx={{ position: 'relative', top: '-6px', right: '-15px' }}
            >
              <ImageWrapper
                name={getLockStatus(selectedValue)}
                type="png"
                parentFolder="icons"
                styles={{
                  width: { xs: pxToRem(33), md: pxTovW(38) },
                  height: { xs: pxToRem(33), md: pxTovW(38) },
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // gap: '16px',
              marginTop: '16px',
              alignItems: 'flex-start',
            }}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontSize: '16px',
                textAlign: 'start',
                marginBottom: '8px',
                fontWeight: 'bold',
              }}
            >
              Choose State
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="choose-state-radio-group-label"
                name="choose-state-radio-group"
                value={selectedValue?.toString()}
                onChange={onRadioChange} // Handle change event
              >
                <FormControlLabel
                  value={ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED.toString()}
                  control={<Radio />}
                  label="Locked"
                />
                <FormControlLabel
                  value={ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED.toString()}
                  control={<Radio />}
                  label="Unlocked"
                />
              </RadioGroup>
            </FormControl>
            {handleLockedCheckboxChange && (
              <Box
                sx={{
                  display: 'flex',
                  gap: pxToRem(5),
                  alignItems: 'center',
                  mt: pxToRem(10),
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={(e) =>
                    handleLockedCheckboxChange &&
                    handleLockedCheckboxChange(e.target.checked)
                  }
                  sx={{
                    color: '#007CDC',
                    '& .MuiSvgIcon-root': {
                      fontSize: { xs: pxToRem(22), md: pxTovW(34) },
                    },
                    '&.Mui-checked': {
                      color: '#007CDC',
                    },
                  }}
                />
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ color: '#007CDC' }}
                >
                  Apply to all
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={styles.popupActions}>
          <Button
            disabled={
              selectedValue ===
              ContentLockStatusType.CONTENT_LOCK_STATUS_IS_PARTIALLY_LOCKED
                ? true
                : false
            }
            onClick={onSubmit}
            sx={{
              width: {
                xs: '80%',
                md: pxTovW(300),
              },
            }}
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </DialogActions>
        <IconButton
          sx={{
            position: 'absolute', // Absolute positioning relative to the dialog container
            // top: '-20px', // Position above the dialog
            right: '-40px', // Position outside the right of the dialog
            zIndex: 1500, // Ensure it's above the dialog
            // backgroundColor: '#FFD700', // Yellow background color
            borderRadius: '50%', // Circular button
            width: '36px', // Size of the close button
            height: '36px', // Size of the close button
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)', // Button shadow for effect
          }}
          onClick={onClose} // Close action
        >
          <ImageWrapper
            name="close-yellow"
            type="png"
            parentFolder="icons"
            styles={{
              width: '33px', // Small white "X"
              height: '33px',
            }}
          />
        </IconButton>
      </Dialog>
      {/* Close Button */}
    </Box>
  );
};

export default ContentLockPopup;
