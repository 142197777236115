import {
  AnswerStatusMark,
  IStyles,
  ImageWrapper,
  SquareImageAvatar,
  StatusRadioMark,
  deserify,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  McqMultipleContentModel,
  McqSingleContentModel,
  PageContentInfo,
  Question,
  TfContentModel,
  TfContentModel_AnswerEnum,
} from '@protos/content_management/content.db_pb';
import {
  IResponses,
  useConnectedClassContext,
} from '../../../../app/Context/ConnectedClassContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { calculateMarksMCQM } from '../../../../utils/functions';
// import { calculateMarksMCQM } from '../../../../utils/functions';
const styles: IStyles = {
  root: {
    width: { xs: '98%', md: '100%' },
    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: {
      xs: `0px 0px ${pxToRem(11)} #00000029`,
      md: `0px 0px ${pxTovW(11)} #00000029`,
    },
    padding: {
      xs: `${pxToRem(12)} ${pxToRem(14)} ${pxToRem(12)} ${pxToRem(14)}`,
      md: `${pxTovW(0)} ${pxTovW(20)} ${pxTovW(20)} ${pxTovW(20)}`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: { xs: pxToRem(500), md: pxTovW(566) },
    '&  > :nth-last-child(-n+1) ': {
      borderBottom: 'none',
    },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  marksWrapper: {
    padding: {
      xs: `${pxToRem(5)} ${pxToRem(13)}`,
      md: `${pxTovW(7)} ${pxTovW(11)}`,
    },
    borderRadius: {
      xs: pxToRem(16),
      md: pxTovW(35),
    },
    border: '1px solid #007CDC',
    marginLeft: '10px',
  },
  marks: {
    fontWeight: 700,
    color: '#007CDC',
  },
};
interface IProps {
  showResponse: boolean;
  pageContent?: PageContentInfo;
}
export const ResponseList = (props: IProps) => {
  const { showResponse, pageContent } = props;
  const { baseStationData, questionSessionData } = useConnectedClassContext();
  const { students_data } = deserify(
    useAppSelector((state) => state.connectedClass)
  );
  const question = pageContent?.contents[0].model.value as Question;
  const questionCase = question.question?.model.case;
  const maxMarks = question.question
    ? question?.question.model.value?.commonQuestionContent?.marks.reduce(
      (acc, curr) => acc + curr,
      0
    ) || 0
    : 0;
  const correctAnswer =
    question.question?.model.value instanceof TfContentModel ||
      question.question?.model.value instanceof McqSingleContentModel ||
      question.question?.model.value instanceof McqMultipleContentModel
      ? question.question.model.value.correct
      : undefined;

  const studentList = students_data?.map((student) => {
    const response = questionSessionData?.responses.find(
      (e) => e.keypadSn === student.clickerSerialNo
    );
    return {
      ...student,
      response: response, // Add the response data to each student object
    };
  });
  const checkOnlineStatus = (clickerSerialNo: string) => {
    let returnData = false;
    baseStationData?.keyPads?.forEach((keypad: any) => {
      if (keypad.keypadSn === clickerSerialNo) {
        returnData = keypad.isOnline;
      }
    });
    return returnData ? 'on' : 'off';
  };
  return (
    <Box sx={styles.root}>
      {studentList?.map((elem) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: { xs: pxToRem(15), md: pxTovW(21) },
            paddingTop: { xs: pxToRem(15), md: pxTovW(21) },
            borderBottom: '1px solid lightGrey',
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: '80%', md: '70%' },
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(10), md: pxTovW(25), lg: pxTovW(12) },
              textAlign: 'left',
            }}
          >
            {/* <ImageWrapper
              parentFolder="tempAssets"
              type="png"
              name="student1"
              styles={{
                height: { xs: pxToRem(36), md: pxTovW(48) },
                width: { xs: pxToRem(36), md: pxTovW(48) },
              }}
            /> */}
            <SquareImageAvatar
              size="small"
              name={elem.studentName}
              online={
                checkOnlineStatus(elem.clickerSerialNo) === 'on' ? true : false
              }
            />
            <Typography
              variant="h4"
              sx={{
                textOverflow: 'ellipsis',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxHeight: pxToRem(40),
                maxWidth: '90%',
              }}
            >
              {elem.studentName}
            </Typography>
          </Box>
          <Box>
            {showResponse === true ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: { xs: pxToRem(21), md: pxTovW(44) },
                }}
              >
                <Box sx={styles.marksWrapper}>
                  <Typography variant="h5" sx={styles.marks}>
                    {correctAnswer && elem.response?.response
                      ? questionCase === 'mcqMultipleContentModel'
                        ? calculateMarksMCQM(question, {
                          answer: elem.response?.response.split(''),
                        })
                        : correctAnswer ===
                          convertAnswerOptionstoNumbers(
                            elem.response?.response,
                            question
                          )
                          ? maxMarks
                          : 0
                      : 0}{' '}
                    Mark
                  </Typography>
                </Box>
                <AnswerStatusMark
                  statusmark={
                    elem.response?.response
                      ? questionCase === 'mcqMultipleContentModel'
                        ? calculateMarksMCQM(question, {
                          answer: elem.response?.response.split(''),
                        }) === maxMarks
                          ? 'on'
                          : 'off'
                        : correctAnswer ===
                          convertAnswerOptionstoNumbers(
                            elem.response?.response,
                            question
                          )
                          ? 'on'
                          : 'off'
                      : 'disconnected'
                  }
                />
              </Box>
            ) : (
              <Box>
                <StatusRadioMark
                  status={checkAttemptStatus(elem.response) ? 'on' : 'off'}
                />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const convertAnswerOptionstoNumbers = (
  option: string | undefined,
  questionObj?: Question
) => {
  switch (option) {
    case 'A':
      return '1';
    case 'B':
      return '2';
    case 'C':
      return '3';
    case 'D':
      return '4';
    case '1':
      return TfContentModel_AnswerEnum.TF_ANSWER_T;
    case '2':
      return TfContentModel_AnswerEnum.TF_ANSWER_F;
    default:
      return undefined;
  }
};

const checkAttemptStatus = (responseObj: IResponses | undefined) => {
  if (responseObj && responseObj.response) {
    if (responseObj.response.split('').length) return true;
  } else return false;
};
