//ConnectedClassContextProvider
import { PartialMessage, Timestamp } from '@bufbuild/protobuf';
import {
  deserify,
  getEnvConfig,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  BaseStationDetails,
  UpsertConnectedClassQuestionSessionRequest,
  UpsertConnectedClassQuestionSessionResponse,
  UpsertConnectedClassResourceSessionRequest,
  UpsertConnectedClassResourceSessionResponse,
  UpsertConnectedClassSessionRequest,
  UpsertConnectedClassSessionResponse,
} from '@protos/learning_management/lms.connected.apis_pb';
import { SessionStatusEnum } from '@protos/learning_management/lms.db_pb';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  IStudentClickerDataObj,
  setStudentsData,
  updateStudentSessionId,
} from '../../pages/Teach/reducer/connectedClass.slice';
import { useAppDispatch, useAppSelector } from '../../reduxStore/reduxHooks';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCN2AygzAn3_kzE3Bpkii2VLd0noAekqGA',
//   authDomain: 'schoolnet-india.firebaseapp.com',
//   projectId: 'schoolnet-india',
//   storageBucket: 'schoolnet-india.appspot.com',
//   messagingSenderId: '90784801557',
//   appId: '1:90784801557:web:912197868aab54bb5bbf1c',
//   measurementId: 'G-K1TX7YWLG8',
// };
const config = getEnvConfig();
const firebaseConfig = config.firebaseConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Define the shape of your context data
interface IContextData {
  dummy_data: boolean;
  getBaseStationConnectionStatus: (
    props: IgetBaseStationConnectionStatusProps
  ) => Promise<BaseStationDetails | undefined>;
  unsubscribeFromFirestore: () => void;
  baseStationData?: ISessionData;
  questionSessionIdPrefix: string;
  upsertConnectedClassSessionApi: (
    props: PartialMessage<UpsertConnectedClassSessionRequest>
  ) => Promise<UpsertConnectedClassSessionResponse | undefined>;
  upsertConnectedClassResourceSessionApi: (
    props: PartialMessage<UpsertConnectedClassResourceSessionRequest>
  ) => Promise<UpsertConnectedClassResourceSessionResponse | undefined>;
  upsertConnectedClassQuestionSessionApi: (
    props: PartialMessage<UpsertConnectedClassQuestionSessionRequest>
  ) => Promise<UpsertConnectedClassQuestionSessionResponse | undefined>;
  unsubscribeQuestionFromFirestore: () => void;
  questionSessionData?: IQuestionSessionData;
  authenticateAnonymously: () => void;
  resetConnectedClass: () => void;
}
interface IKeypads {
  [keypadSn: string]: boolean;
}
// Create the context
const connectedClassContext = createContext<IContextData | undefined>(
  undefined
);

const { Provider } = connectedClassContext;

// Create a provider component
interface IProviderProps {
  children: ReactNode;
}

export const ConnectedClassContextProvider: React.FC<IProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const {
    base_station_details,
    students_data,
    question_session_details,
    connectedClass_session_data,
  } = deserify(useAppSelector((state) => state.connectedClass));
  const { LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler } =
    useCommonServiceClientContext();
  const [baseStationData, setBaseStationData] = useState<
    ISessionData | undefined
  >();
  const [questionSessionData, setQuestionSessionData] = useState<
    IQuestionSessionData | undefined
  >();
  const [questionSessionIdPrefix, setQuestionSessionIdPrefix] =
    useState<string>('');
  const [unsubscribeFunction, setUnsubscribeFunction] = useState<
    (() => void) | null
  >(null);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [unsubscribeQuestionSesssion, setUnsubscribeQuestionSesssion] =
    useState<(() => void) | null>(null);
  const [keypadsOnlineStatus, setKeypadsOnlineStatus] = useState<IKeypads>({});

  const studentsDataRef = useRef(students_data);
  const keypadsOnlineStatusRef = useRef<IKeypads>(keypadsOnlineStatus);

  useEffect(() => {
    studentsDataRef.current = students_data;
    keypadsOnlineStatusRef.current = keypadsOnlineStatus;
  }, [students_data, keypadsOnlineStatus]);

  const resetConnectedClass = () => {
    setKeypadsOnlineStatus({});
    setUserLoggedIn(false);
    setUnsubscribeFunction(null);
    setQuestionSessionIdPrefix('');
    setQuestionSessionData(undefined);
    setBaseStationData(undefined);
    unsubscribeQuestionFromFirestore();
    unsubscribeFromFirestore();
  };
  const authenticateAnonymously = async () => {
    const auth = getAuth(app);
    return auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        console.log('user IN - ', user);
        // stopKeypadPolling();
        setUserLoggedIn(true);
        return user;
      } else {
        // No user is signed in.
        console.log('user NIN - ', user);
        setUserLoggedIn(false);
        return signInAnonymously(getAuth(app));
      }
    });
  };
  const updateKeypadOnlineStatus = (keypadSn: string, isOnline: boolean) => {
    console.log('inside updateKeypadOnlineStatus');
    setKeypadsOnlineStatus((prevStatus) => ({
      ...prevStatus,
      [keypadSn]: isOnline,
    }));
  };

  const handleKeypadStatusChange = async (
    keypad: Keypad,
    data: ISessionData,
    keypadsStatus: IKeypads,
    studentsData: IStudentClickerDataObj[] | undefined
  ) => {
    const previousStatus = keypadsStatus[keypad.keypadSn];
    console.log(
      'handleKeypadStatusChange 1.0',
      previousStatus,

      keypadsOnlineStatus,
      data
    );
    if (
      typeof previousStatus === 'undefined' ||
      previousStatus !== keypad.isOnline
    ) {
      console.log(
        'handleKeypadStatusChange 2.0',
        previousStatus,
        keypad.keypadSn,
        studentsData
      );
      if (studentsData) {
        const sessionIdString = data?.sessionId;
        // Find the studentId based on clickerSerialNo
        const student = studentsData.find(
          (e) => e.clickerSerialNo === keypad.keypadSn
        );
        console.log({ student });
        if (sessionIdString && student) {
          console.log('handleKeypadStatusChange 3.0', previousStatus);
          const res =
            await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.upsertStudentClickerSession(
              {
                connectedClassSessionId: BigInt(sessionIdString),
                // int64 connected_class_student_session_id = 2;
                studentId: student?.studentId,
                clickerId: keypad.keypadSn.toString(),
                sessionStatus: keypad.isOnline
                  ? SessionStatusEnum.SESSION_STATUS_STARTED
                  : SessionStatusEnum.SESSION_STATUS_EXITED,
              }
            );
          dispatch(
            updateStudentSessionId({
              studentId: student.studentId,
              sessionId: res.connectedClassStudentSessionId,
            })
          );
        }
      } // Update the keypad status in state
      updateKeypadOnlineStatus(keypad.keypadSn.toString(), keypad.isOnline);
    }
  };
  const streamBaseStationUpdate = (
    baseStationSN: string,
    keypadsStatus: IKeypads
  ) => {
    // eslint-disable-next-line no-debugger
    // debugger;
    const unsub = onSnapshot(
      doc(db, 'baseStations', baseStationSN),
      (doc) => {
        const currentStudentsData = studentsDataRef.current;
        const currentKeypadsStatus = keypadsOnlineStatusRef.current;
        const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server';
        console.log('Doc id: ', doc.id);
        console.log(source, ' data: ', doc.data());
        const data = doc.data() as ISessionData;
        setBaseStationData(data);
        if (data) {
          const prefix = `S${data.sessionId}_Q`;
          setQuestionSessionIdPrefix(prefix);
        }
        //creating individual students sessions based on online status
        if (data.sessionId) {
          data.keyPads.forEach((e) =>
            handleKeypadStatusChange(
              e,
              data,
              currentKeypadsStatus,
              currentStudentsData
            )
          );
        }
      },
      (error) => {
        console.log('streamBaseStationUpdate Error');
      }
    );
    setUnsubscribeFunction(() => unsub);
  };
  const streamQuestionBaseStationUpdate = (
    baseStationSN: string,
    streamQuestionId: string
  ) => {
    const questionSessionDocRef = doc(
      db,
      'baseStations',
      baseStationSN,
      'questionSession',
      streamQuestionId
    );
    console.log(
      'baseStationSN',
      baseStationSN,
      'streamQuestionId',
      streamQuestionId,
      questionSessionDocRef
    );
    const unsubscribe = onSnapshot(
      questionSessionDocRef,
      (doc) => {
        const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server';
        console.log('QS data: ', doc.data());
        const data = doc.data() as IQuestionSessionData;
        setQuestionSessionData(data);
      },
      (error) => {
        console.log('streamQuestionBaseStationUpdate Error');
      }
    );
    setUnsubscribeQuestionSesssion(() => unsubscribe);
  };
  const unsubscribeFromFirestore = () => {
    if (unsubscribeFunction) {
      unsubscribeFunction(); // Call the unsubscribe function
      setUnsubscribeFunction(null); // Clear the unsubscribe function
      setBaseStationData(undefined);
    }
  };
  const unsubscribeQuestionFromFirestore = () => {
    if (unsubscribeQuestionSesssion) {
      unsubscribeQuestionSesssion(); // Call the unsubscribe function
      setUnsubscribeQuestionSesssion(null); // Clear the unsubscribe function
      setQuestionSessionData(undefined);
    }
  };
  const baseStationActiveConnection = async (SN: string) => {
    // await authenticateAnonymously();
    await streamBaseStationUpdate(SN, keypadsOnlineStatus);
  };
  const questionConnectionBaseStationActiveConnection = async (
    SN: string,
    SQN: string
  ) => {
    // await authenticateAnonymously();
    await streamQuestionBaseStationUpdate(SN, SQN);
  };
  const getBaseStationConnectionStatus = async (
    props: IgetBaseStationConnectionStatusProps
  ): Promise<BaseStationDetails | undefined> => {
    try {
      const { teacherId, sectionId, classId } = props;
      const response =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.getClassStudentList(
          {
            teacherId: teacherId,
            classId: classId,
            sectionId: sectionId,
          }
        );
      if (response.status === 200) {
        const list = response.studentListData?.studentClickerData || [];
        dispatch(setStudentsData(list));
      } else {
        dispatch(setStudentsData(undefined));
      }
      const res =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.getBaseStationConnectionStatus(
          {
            teacherId: teacherId,
            sectionId: sectionId,
            classId: classId,
          }
        );
      console.log('getBaseStationConnectionStatus res', res);
      if (res.status === 200 && res.baseStationDetails) {
        return res.baseStationDetails;
      } else throw new Error(`getBaseStationConnectionStatus error: ${res}`);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      } else console.log(err);
    }
  };
  // console.log('handleKeypadStatusChange 4.0', keypadsOnlineStatus);
  const upsertConnectedClassSessionApi = async (
    props: PartialMessage<UpsertConnectedClassSessionRequest>
  ): Promise<UpsertConnectedClassSessionResponse | undefined> => {
    try {
      const res =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.upsertConnectedClassSession(
          props
        );
      if (res && res.status === 200) {
        return res;
      } else throw new Error(`upsertConnectedClassSession error: ${res}`);
    } catch (err) {
      console.log(err);
    }
  };
  const upsertConnectedClassResourceSessionApi = async (
    props: PartialMessage<UpsertConnectedClassResourceSessionRequest>
  ): Promise<UpsertConnectedClassResourceSessionResponse | undefined> => {
    try {
      const res =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.upsertConnectedClassResourceSession(
          props
        );
      if (res && res.status === 200) {
        return res;
      } else {
        throw new Error(`upsertConnectedClassResourceSession error: ${res}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const upsertConnectedClassQuestionSessionApi = async (
    props: PartialMessage<UpsertConnectedClassQuestionSessionRequest>
  ): Promise<UpsertConnectedClassQuestionSessionResponse | undefined> => {
    try {
      const res =
        await LmsConnectedClassAPIServiceV1WithClientStatusCodeHandler.upsertConnectedClassQuestionSession(
          props
        );
      if (res && res.status === 200) {
        return res;
      } else {
        throw new Error(`upsertConnectedClassResourceSession error: ${res}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (base_station_details?.isOnline) {
      if (userLoggedIn) {
        baseStationActiveConnection(base_station_details.baseReceiverSerialNo);
      }
      if (!userLoggedIn) {
        authenticateAnonymously();
      }
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [base_station_details?.isOnline, userLoggedIn]);

  useEffect(() => {
    if (
      base_station_details?.isOnline &&
      question_session_details &&
      userLoggedIn
    ) {
      questionConnectionBaseStationActiveConnection(
        base_station_details.baseReceiverSerialNo,
        `S${connectedClass_session_data?.connectedClassSessionId.toString()}_Q` +
          question_session_details.question_id
      );
    }
    if (!userLoggedIn) {
      authenticateAnonymously();
    }
    return () => {
      unsubscribeQuestionFromFirestore();
    };
  }, [
    question_session_details?.connected_class_question_session_id,
    userLoggedIn,
  ]);

  const contextValue: IContextData = {
    dummy_data: true,
    getBaseStationConnectionStatus,
    unsubscribeFromFirestore,
    baseStationData,
    questionSessionIdPrefix,
    upsertConnectedClassSessionApi,
    upsertConnectedClassResourceSessionApi,
    upsertConnectedClassQuestionSessionApi,
    unsubscribeQuestionFromFirestore,
    questionSessionData,
    authenticateAnonymously,
    resetConnectedClass,
  };
  return <Provider value={contextValue}>{children}</Provider>;
};

export const useConnectedClassContext = () => {
  const context = useContext(connectedClassContext);
  if (!context) {
    throw new Error(
      'connectedClassContext must be used within a MyContextProvider'
    );
  }
  return context;
};

interface IgetBaseStationConnectionStatusProps {
  teacherId: bigint;
  sectionId: number;
  classId: number;
}
interface Keypad {
  modifiedOn: Timestamp | null;
  createdOn: Timestamp;
  keypadSn: string;
  isOnline: boolean;
}

interface ISessionData {
  modifiedOn: Timestamp | null;
  sessionId: number;
  keyPads: Keypad[];
  createdOn: Timestamp;
  isOnline: boolean;
  activeQuestionId: string;
  sessionStatus: boolean;
  baseId: number;
  questionType: number;
}

export interface IResponses {
  createdOn: Timestamp;
  keypadSn: string;
  modifiedOn: Timestamp;
  response: string;
  timeSpent?: number;
}
interface IQuestionSessionData {
  createdOn: Timestamp;
  question: string;
  questionId: string;
  responses: IResponses[];
}
