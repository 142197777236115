import { ConnectError } from '@bufbuild/connect';
import {
  DocumentViewer,
  IStyles,
  LinkButton,
  deserify,
  getMediaBasePath,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box } from '@mui/material';
import { FileEnum } from '@protos/content_management/content.db_pb';
import { Feature } from '@protos/school_management/school.db_pb';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';

const styles: IStyles = {};

interface IViewReport {
  homeworkId: number;
}

export default function ViewReportButton(props: IViewReport) {
  const { SchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const user = deserify(useAppSelector((state) => state.auth.user_info));
  const featureInfo = user?.schoolDetails[0]?.featuresPlanInfo;
  const isViewReportsEnabled = useFeatureEnabled(
    featureInfo,
    Feature.VIEW_REPORTS
  );
  const { setShowAppLoader } = useGlobalContext();
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  if (!isViewReportsEnabled) {
    return null;
  }
  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
      >
        <LinkButton
          onClick={async () => {
            try {
              setShowAppLoader(true);
              const res =
                await SchoolAssessmentAPIServiceV1ClientWithStatusCodeHandler.fetchSchoolAssessmentReport(
                  {
                    homeworkId: props.homeworkId,
                    teacherId: user?.teacherProfileId,
                  }
                );
              if (res.data?.gcpReportUrl) {
                setPdfUrl(getMediaBasePath(res.data.gcpReportUrl));
              } else {
                dispatch(
                  setToastInfo({
                    label: res.message,
                    variant: 'success',
                    open: true,
                  })
                );
              }
              setShowAppLoader(false);
            } catch (err) {
              if (err instanceof ConnectError) {
                console.log('Error:', err.rawMessage);
                dispatch(
                  setToastInfo({
                    label: err.rawMessage,
                    variant: 'error',
                    open: true,
                  })
                );
              } else {
                dispatch(
                  setToastInfo({
                    label: 'Error while generating report',
                    variant: 'error',
                    open: true,
                  })
                );
              }
              setShowAppLoader(false);
            }
          }}
        >
          View Report
        </LinkButton>
      </Box>
      {pdfUrl && (
        <DocumentViewer
          downloadUrl={pdfUrl}
          previewUrl={pdfUrl}
          gcpUrl={pdfUrl}
          type={FileEnum.FILE_TYPE_PDF}
          fileName={`Geneo Assessment Report (Teacher) ${props.homeworkId}`}
          open={!!pdfUrl}
          closeFile={() => {
            setPdfUrl(undefined);
          }}
        />
      )}
    </>
  );
}
