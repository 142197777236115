import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { PlainMessage } from '@bufbuild/protobuf';
import {
  ChapterCard,
  ChipBadge,
  DashboardGrid,
  IStyles,
  SubjectCard,
  convertBytesToHumanReadable,
  deleteDownloadedChapter,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  getSizeOfNode,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { DownloadedSubject } from '@protos/content_management/content.db_pb';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { DeleteButton } from '../../../../components/DeleteButton';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { HOME } from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/auth.events';

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    boxSizing: 'border-box',
    padding: { md: `${pxTovW(50)} ${pxTovW(240)}` },
    gap: { xs: pxToRem(0), md: pxTovW(40) },
  },
  subjectBox: {
    width: '100%',
    display: 'flex',
    gap: { xs: pxToRem(15), md: 0 },
    boxSizing: 'border-box',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    padding: { xs: pxToRem(20), md: 0 },
  },
  onGoing: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(15), md: pxTovW(10) },
    mt: { md: pxTovW(15) },
  },
  allChaptersContainer: {
    width: '100%',
    boxSizing: 'border-box',

    '& .grid': {
      padding: { xs: pxToRem(12), md: pxTovW(12) },
    },
    '& .grid > div': {
      borderBottom: '1px solid #B8B8B8',
    },
    '& .grid:not(:nth-of-type(3n))': {
      borderRight: { xs: '1px solid none', md: '1px solid #B8B8B8' },
      borderImage:
        'linear-gradient(to bottom, white 10%,#B8B8B8 10% 90%,white 90% 100%) 1',
    },
  },

  lowerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(15), md: pxTovW(25) },
  },

  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    padding: { xs: pxToRem(20), md: 0 },
  },
};

export default function OfflineTeachChapterSelection() {
  const navigate = useNavigate();
  const { subject_id } = useParams();
  const subjectId = Number(subject_id);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const [downloadedSubject, setDownloadedSubject] = useState<
    PlainMessage<DownloadedSubject>
  >(new DownloadedSubject());
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);

  const chapters = downloadedSubject.chapters || {};
  // Filter out chapters with lesson plans of current teacher and section
  const chaptersArray =
    Object.values(chapters)
      .sort((a, b) => a.order - b.order)
      .filter((chap) => {
        const topics = chap.downloadedTopics || {};
        let hasDownloadedLps = false;
        Object.values(topics)
          .sort((a, b) => a.order - b.order)
          .filter((top) => {
            const lPs = top.downloadedLessonPlans || {};
            const lPsArray = Object.values(lPs).filter(
              (val) =>
                class_subject_info?.sectionId &&
                val.schoolClassSectionId.includes(
                  class_subject_info.sectionId
                ) &&
                (!val.teacherId || val.teacherId == user_info?.teacherProfileId)
            );
            if (lPsArray.length) {
              hasDownloadedLps = true;
            }
          });
        return hasDownloadedLps;
      }) || [];

  const { setSelectedFunction } = useGlobalContext();

  const backButtonClick = () => {
    navigate(HOME);
  };

  useEffect(() => {
    updateDownloadedSubjectData();
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const updateDownloadedSubjectData = async () => {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: subjectId,
    });
    if (indexDbData.length > 0) {
      const data = indexDbData[0];
      setDownloadedSubject(data);
    }
  };

  return (
    <Box sx={styles.root}>
      {/* <ConfigSelector /> */}
      {isNaN(subjectId) ? null : (
        <>
          <Box sx={styles.subjectBox}>
            <SubjectCard
              image={getMediaBasePath(
                downloadedSubject.bookImageUrl || class_subject_info?.icon,
                'processedMediaBucket'
              )}
              title={downloadedSubject.name || class_subject_info?.subject}
              subTitle={`Class ${
                class_subject_info
                  ? class_subject_info?.classname + class_subject_info?.section
                  : ''
              }`}
            />
          </Box>

          <Box sx={styles.lowerBox}>
            <Box sx={styles.textWithBadge}>
              <Typography variant="h2">Chapters</Typography>
              <Typography variant="h4">
                <ChipBadge
                  label={chaptersArray.length}
                  color="primary"
                  size="small"
                />
              </Typography>
            </Box>

            <Box sx={{ marginBottom: { xs: pxToRem(20), md: 0 } }}>
              <DashboardGrid
                items={chaptersArray.map((chapter, index) => {
                  return (
                    <ChapterCard
                      withArrow
                      variant="normal"
                      mainHeading={`${chapter.order}. ${chapter.chapterTitle}`}
                      offineSubText={convertBytesToHumanReadable(
                        getSizeOfNode(
                          downloadedSubject,
                          chapter.chapterId,
                          undefined,
                          undefined,
                          user_info?.teacherProfileId
                        )
                      )}
                      rootStyle={{ width: '100%' }}
                      cardClickHandler={async () => {
                        navigate(
                          `/offline/teach-topics-selection/${subjectId}/${chapter.chapterId}`
                        );
                        await interactionEvent({
                          url: 'Teacher_teach_chapter_selection',
                          context: 'All_chapter',
                          name: 'CHAPTER_OPEN',
                        });
                      }}
                      image={getMediaBasePath(
                        chapter.posterImagesUrl,
                        'processedMediaBucket'
                      )}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: { xs: '50%', md: 0 },
                          right: { xs: pxToRem(12), md: 0 },
                          transform: { xs: 'translateY(-50%)', md: 'initial' },
                        }}
                      >
                        <DeleteButton
                          title={`${chapter.order}. ${chapter.chapterTitle}`}
                          onClick={async () => {
                            if (isNaN(subjectId) || !chapter.chapterId) {
                              return;
                            }
                            await deleteDownloadedChapter({
                              subjectId,
                              chapterId: chapter.chapterId,
                              sectionId: class_subject_info?.sectionId,
                              userId: user_info?.teacherProfileId.toString(),
                            });
                            await updateDownloadedSubjectData();
                            await interactionEvent({
                              url: 'Teacher_teach_chapter_selection',
                              context: 'chapter',
                              name: 'DELETE',
                            });
                          }}
                        />
                      </Box>
                    </ChapterCard>
                  );
                })}
                WebNoOfCols={3}
                mobileNoOfCols={1}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

const newItems = [];
