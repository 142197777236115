import { createSlice } from '@reduxjs/toolkit';
// import {} from '@protos/common/'

export interface IOfflineState {
  downloadResolution?: number;
  //TeacherAnalysis
}

const initialState: IOfflineState = {
  downloadResolution: undefined
};

export const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    //setHomeworkListsData
    setDownloadResolution: (state, action) => {
      state.downloadResolution = action.payload;
    },
  },
});

export const {
  setDownloadResolution
} = offlineSlice.actions;
export default offlineSlice.reducer;
