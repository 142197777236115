import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { PlainMessage } from '@bufbuild/protobuf';
import {
  ContentDetailCard,
  HwProceedButton,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InPageHeader,
  InfoBar,
  InstructionsPopup,
  NoContentCard,
  PrimaryButton,
  SecondaryButton,
  convertBytesToHumanReadable,
  deleteDownloadedLessonPlan,
  deserify,
  findDownloadedSubjectByParams,
  getMediaBasePath,
  getResourceCategory,
  getSizeOfNode,
  pxToRem,
  pxTovW,
  resourceTypeName,
} from '@geneo2-web/shared-ui';
import {
  DownloadedLessonPlan,
  DownloadedResource,
  DownloadedSubject,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { DeleteButton } from '../../../../components/DeleteButton';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import {
  OFFLINE_LESSON_PLAN_LISTS,
  OFFLINE_RESOURCE,
} from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/auth.events';
const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: 'column',
    gap: pxTovW(25),
    padding: { md: `${pxTovW(14)} ${pxTovW(240)}` },
    paddingTop: { md: pxTovW(40) },
  },
  headingImage: {
    width: { xs: pxToRem(60), md: pxTovW(105) },
    height: { xs: pxToRem(60), md: pxTovW(105) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    objectFit: 'cover',
  },
  item: {
    display: { xs: 'flex', md: 'none' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: pxToRem(5),
    '&>div': {
      display: 'flex',
      gap: pxToRem(2),
      justifyContent: 'center',
      alignItems: 'center',
    },
    // backgroundColor: 'red',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: pxToRem(40), md: pxTovW(270) },
    padding: { md: `${pxTovW(15)} ${pxTovW(240)}` },
    // margin: {
    //   xs: `${pxToRem(0)} ${pxToRem(20)} ${pxToRem(0)} ${pxToRem(20)}`,
    //   md: 0,
    // },
  },
  leftPanel: {
    flexBasis: { md: pxTovW(444) },
    display: 'flex',
    flexDirection: 'column',
    gap: { md: pxTovW(30) },
  },
  rightPanel: {
    height: '100%',
    overflow: 'auto',
    paddingX: pxTovW(20),
    flexBasis: { md: pxTovW(747) },
  },
  rightPanelHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardsContainer: {
    paddingTop: pxTovW(20),
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(20) },
    mb: { xs: pxToRem(100), md: pxTovW(0) },
  },
};

export default function TeachingFlowOffline() {
  const navigate = useNavigate();
  const { subject_id, chapter_id, topic_id, lesson_plan_id } = useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const topicId = Number(topic_id);
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const [downloadedSubject, setDownloadedSubject] = useState<
    PlainMessage<DownloadedSubject>
  >(new DownloadedSubject());

  const chaps = downloadedSubject.chapters || {};
  const selectedChapter = chaps[chapterId];
  const topics = selectedChapter?.downloadedTopics || {};
  const selectedTopic = topics[topicId];
  const lessonPlans = selectedTopic?.downloadedLessonPlans || {};
  const selectedLessonPlan = lesson_plan_id
    ? lessonPlans[lesson_plan_id]
    : undefined;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setSelectedFunction } = useGlobalContext();

  const user_info = deserify(useAppSelector((state) => state.auth.user_info));

  const backButtonClick = () => {
    navigate(
      `${OFFLINE_LESSON_PLAN_LISTS}/${subjectId}/${chapterId}/${topicId}`
    );
  };

  useEffect(() => {
    (async () => {
      const indexDbData = await findDownloadedSubjectByParams({
        subjectId: subjectId,
      });
      if (indexDbData.length > 0) {
        const data = indexDbData[0];
        setDownloadedSubject(data);
      }
    })();
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const [openIntructionsPopup, setOpenIntructionsPopup] = useState(false);

  const onLearnClicked = async (resId?: string) => {
    const firstResource =
      selectedLessonPlan &&
      Object.values(selectedLessonPlan.downloadedResources).sort(
        (a, b) => a.order - b.order
      )[0];
    const resourceId = resId || firstResource?.resourceId;
    console.log(resourceId);
    if (resourceId) {
      navigate(
        `${OFFLINE_RESOURCE}/${subjectId}/${chapterId}/${topicId}/${lesson_plan_id}/${resourceId}`
      );
    }
    console.log('on learn clicked');

    await interactionEvent({
      url: 'Teacher_teaching_flow',
      context: 'Teaching_flow',
      name: 'TEACH',
    });
  };

  return (
    <>
      {/* <Box sx={styles.root}>
        <ConfigSelector />
      </Box> */}
      <Box
        sx={{
          padding: {
            xs: pxToRem(20),
            md: 0,
          },
        }}
      >
        {selectedLessonPlan && (
          <InPageHeader
            title={
              <Heading
                downloadedSubject={downloadedSubject}
                chapterId={chapterId}
                topicId={topicId}
                selectedLessonInfo={selectedLessonPlan}
                isMobile={isMobile}
                onDeleteClick={async () => {
                  if (
                    isNaN(subjectId) ||
                    isNaN(chapterId) ||
                    isNaN(topicId) ||
                    !lesson_plan_id
                  ) {
                    return;
                  }
                  await deleteDownloadedLessonPlan({
                    subjectId,
                    chapterId,
                    topicId,
                    lessonId: lesson_plan_id,
                    sectionId: class_subject_info?.sectionId,
                    userId: user_info?.teacherProfileId.toString(),
                  });
                  navigate(
                    `${OFFLINE_LESSON_PLAN_LISTS}/${subjectId}/${chapterId}/${topicId}`
                  );
                }}
              />
            }
            buttonText="START"
            buttonClickHandler={() => onLearnClicked()}
          />
        )}
        <Box sx={styles.mainContainer}>
          <Box sx={styles.leftPanel}>
            {selectedLessonPlan?.learningOutcomes &&
              selectedLessonPlan.learningOutcomes.length !== 0 && (
                <Box
                  sx={{
                    margin: {
                      xs: `${pxToRem(26)} ${pxToRem(4)} ${pxToRem(0)} ${pxToRem(
                        4
                      )}`,
                      md: 0,
                    },
                  }}
                >
                  <SecondaryButton
                    variant="outlined"
                    witharrow
                    fullWidth
                    onClick={async () => {
                      setOpenIntructionsPopup(true);
                      await interactionEvent({
                        url: 'Teacher_teaching_flow',
                        context: 'Drop_down',
                        name: 'LEARNING_OUTCOME',
                      });
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      Learning Outcome
                    </Typography>
                  </SecondaryButton>
                </Box>
              )}
            {selectedLessonPlan && (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <InfoBar
                  contentList={[
                    {
                      iconName: 'questions',
                      heading: Object.values(selectedLessonPlan.downloadedResources).length || 0,
                      subHeading: 'Resources',
                    },
                    {
                      iconName: 'clock',
                      heading: Object.values(
                        selectedLessonPlan.downloadedResources
                      ).reduce(
                        (acc, resource) => acc + resource.estimatedTimeInMin,
                        0
                      ) || 0,
                      subHeading: 'Minutes',
                    },
                  ]}
                />
              </Box>
            )}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <PrimaryButton
                sx={{
                  boxShadow: `inset 0 0 ${pxTovW(8)} #00602B , 0 0 ${pxTovW(
                    7
                  )} #0AA34F63`,
                  height: { xs: pxToRem(52), md: pxTovW(70) },
                }}
                fullWidth
                onClick={() => onLearnClicked()}
              >
                <Typography
                  variant="bodyText"
                  fontWeight="bold"
                  color={'white'}
                  sx={{ letterSpacing: '1px' }}
                >
                  START
                </Typography>
              </PrimaryButton>
            </Box>
          </Box>
          <Box sx={styles.rightPanel}>
            <Box sx={styles.rightPanelHeading}>
              <Typography variant="h2">Teaching Flow</Typography>
            </Box>

            {selectedLessonPlan &&
              Object.values(selectedLessonPlan.downloadedResources).length > 0 ? (
              <Box sx={styles.cardsContainer}>
                {Object.values(selectedLessonPlan.downloadedResources)
                  .sort((a, b) => a.order - b.order)
                  .map((resource, resourceIndex: number) => (
                    <ContentDetailCard
                      key={resourceIndex}
                      variant="large"
                      tagName={getResourceCategory(
                        resource.resourceCategoryType
                      )}
                      image={getMediaBasePath(
                        resource.posterImageUrl,
                        'processedMediaBucket'
                      )}
                      heading={resource.title}
                      iconDetails={iconDetails(resource)}
                      rootStyle={{
                        width: '100%',
                        backgroundColor: 'common.white',
                      }}
                      showVideoIcon={
                        resource.resourceType ===
                        Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                      }
                      onClick={() => onLearnClicked(resource.resourceId)}
                    />
                  ))}
              </Box>
            ) : (
              <NoContentCard
                variant="info"
                icon="cards"
                text="No Content Available"
              />
            )}

            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                position: 'fixed',
                bottom: pxToRem(10),
                right: pxToRem(15),
              }}
              onClick={() => onLearnClicked()}
            >
              {selectedLessonPlan && (
                <HwProceedButton
                  buttonTitle="START"
                  clickHandler={() => onLearnClicked()}
                  tabs={[
                    {
                      quantity:
                        Object.keys(
                          selectedLessonPlan.downloadedResources
                        ).length.toString() || '0',
                      title: 'Resources',
                    },
                    {
                      quantity:
                        Object.values(selectedLessonPlan.downloadedResources)
                          .reduce(
                            (acc, resource) =>
                              acc + resource.estimatedTimeInMin,
                            0
                          )
                          .toString() || '0',
                      title: 'Mins',
                    },
                  ]}
                />
              )}
            </Box>
          </Box>
        </Box>
        <InstructionsPopup
          popupText={selectedLessonPlan?.learningOutcomes}
          open={openIntructionsPopup}
          handleClose={() => setOpenIntructionsPopup(false)}
        />
      </Box>
    </>
  );
}

interface HProps {
  downloadedSubject: PlainMessage<DownloadedSubject>;
  chapterId?: number;
  topicId?: number;
  selectedLessonInfo: PlainMessage<DownloadedLessonPlan>;
  isMobile?: boolean;
  onDeleteClick?: () => void;
}

const Heading = ({
  downloadedSubject,
  chapterId,
  topicId,
  selectedLessonInfo,
  isMobile,
  onDeleteClick,
}: HProps) => {
  const totalResourceTime = Object.values(
    selectedLessonInfo.downloadedResources
  ).reduce((acc, resource) => acc + resource.estimatedTimeInMin, 0);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  // console.log('selectedLessonInfo:', selectedLessonInfo);

  const getDownloadStats = () => {
    return (
      <Box
        sx={{
          textAlign: 'center',
          '@media (max-width: 380px)': {
            zoom: 0.8,
          },
          marginLeft: { xs: 'auto', md: 0 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: pxTovW(15),
            alignItems: 'center',
            marginTop: {
              xs: 0,
              md: pxTovW(10),
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: {
                xs: `${pxToRem(5)} ${pxToRem(6)}`,
                md: `${pxTovW(11)} ${pxTovW(19)}`,
              },
              backgroundColor: '#FFE3E7',
              border: '1px solid #FF6177',
              borderRadius: {
                xs: pxToRem(7),
                md: pxTovW(7),
              },
              justifyContent: 'center',
              alignItems: 'center',
              wordBreak: 'normal',
            }}
          >
            <Typography variant="h4" fontWeight="medium">
              {chapterId && topicId
                ? convertBytesToHumanReadable(
                  getSizeOfNode(
                    downloadedSubject,
                    chapterId,
                    topicId,
                    selectedLessonInfo.lessonPlanId,
                    user_info?.teacherProfileId
                  )
                )
                : undefined}
            </Typography>
          </Box>
          <DeleteButton
            title={selectedLessonInfo?.lessonPlanTitle}
            onClick={async () => {
              if (onDeleteClick) {
                await onDeleteClick();
              }

              await interactionEvent({
                url: 'Teacher_teaching_flow',
                context: 'Teaching_flow',
                name: 'DELETE',
              });
            }}
            rootStyle={{
              height: {
                xs: pxToRem(26),
                md: pxTovW(45),
              },
              width: {
                xs: pxToRem(26),
                md: pxTovW(45),
              },
              marginLeft: {
                xs: pxToRem(5),
                md: pxTovW(10),
              },
              marginRight: {
                xs: pxToRem(0),
                md: pxTovW(10),
              },
            }}
          />
        </Box>
        {/* {isMobile &&
      <Typography sx={{ marginRight: pxToRem(10), marginTop: pxToRem(6) }} variant='body1'>Expires in 19d</Typography>
    } */}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        wordBreak: 'break-word',
        alignItems: { xs: 'center', md: 'flex-start' },
        flexDirection: { xs: 'row', md: 'column' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(10), md: pxTovW(20) },
          alignItems: 'center',
        }}
      >
        <ImageWrapper
          name="chapterImage"
          type="png"
          styles={styles.headingImage}
          parentFolder="tempAssets"
          path={getMediaBasePath(
            selectedLessonInfo.image,
            'processedMediaBucket'
          )}
        />
        <Box
          sx={{
            display: 'flex',
            gap: { xs: pxToRem(5), md: pxTovW(0) },
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Typography
              variant="h2"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {selectedLessonInfo?.lessonPlanTitle}
            </Typography>
          </Box>
          {!isMobile && getDownloadStats()}

          <Box
            sx={{
              display: 'flex',
              // bgcolor: 'blue',
              width: '100%',
              gap: pxToRem(5),
              alignItems: 'center',
              wordBreak: 'normal',
            }}
          >
            <Box sx={styles.item}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconWrapper
                  name="clock"
                  size="md"
                  type="png"
                  parentFolder="icons"
                />
                <Typography variant="smallText">
                  {totalResourceTime || 0}
                </Typography>

                <Typography variant="smallText">Minutes</Typography>
              </Box>
            </Box>
            <Box sx={styles.item}>
              <Box
                sx={{
                  display: 'flex',

                  alignItems: 'center',
                }}
              >
                <IconWrapper
                  name="questions"
                  size="small"
                  type="png"
                  parentFolder="icons"
                />
                <Typography variant="smallText">
                  {Object.keys(selectedLessonInfo.downloadedResources).length ||
                    0}
                </Typography>

                <Typography variant="smallText">Resources</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {isMobile && getDownloadStats()}
    </Box>
  );
};

const iconDetails = (resource: PlainMessage<DownloadedResource>) => {
  const retValue = [
    {
      iconName: 'clock',
      text: `${resource.estimatedTimeInMin} Min`,
    },

    {
      iconName: resourceTypeName(resource.resourceType).icon,
      text: resourceTypeName(resource.resourceType).name,
    },
  ];

  return retValue;
};
