import {
  IStyles,
  ImageWrapper,
  Loader,
  MarksSubmitPopup,
  PrimaryButton,
  ScoreProgressBar,
  convertPercentageToMarks,
  deserify,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { SubmissionType } from '@protos/learning_management/lms.db_pb';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  StudentPerformanceInfo,
  StudentSubmissionInfo,
} from '@protos/learning_management/lms.hw.teacher.apis_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOMEWORK_MANAGE,
  HOMEWORK_PERFORMANCE,
  HOMEWORK_SUBMISSION_CUSTOM,
  HOMEWORK_VIEW,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import {
  aiHomeworkCloseEvent,
  aiHomeworkOpenEvent,
} from '../../Homework/homework.events';
import { setCurrentHomeworkClassStats } from '../reducer/manageHomework.slice';
import { StudentSection } from './components/StudentSection';
import { HwDetails } from './components/hwDetails';

const styles: IStyles = {
  root: {
    width: '100%',
    padding: {
      md: `${pxTovW(15)} ${pxTovW(20)}`,
      lg: `${pxTovW(15)} ${pxTovW(240)}`,
    },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',

    gap: { xs: pxToRem(10), md: pxTovW(22) },
    alignItems: 'center',
  },
  headImage: {
    width: { xs: pxToRem(74), md: pxToRem(50), lg: pxTovW(124) },
    height: { xs: pxToRem(74), md: pxToRem(50), lg: pxTovW(124) },
    borderRadius: { xs: pxToRem(6), md: pxToRem(6), lg: pxTovW(15) },
    objectFit: 'cover',
  },
};

export interface ISubmissionProps {
  studentData?: StudentPerformanceInfo;
  marks?: number;
  checkedValue?: boolean;
}
interface IStudentStatus {
  studentId: bigint;
  isSubmitted?: boolean;
  marks?: number;
}

export interface IStatusObj {
  [key: string]: IStudentStatus;
}
export const OngoingHomework = () => {
  // const { homework_id } = useParams();
  const navigate = useNavigate();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const [marksPopupDisplay, setMarksPopupDisplay] = useState(false);
  const { homework_list_data, current_homework_classStats, selected_hw_id } =
    deserify(useAppSelector((state) => state.manageHomework));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [studentInfo, setStudentInfo] = useState<
    StudentPerformanceInfo | undefined
  >();
  const { isIpadOnly } = useGlobalContext();

  //array to store isSubmitted values locally

  const dispatch = useAppDispatch();
  const [currentHomwork, setCurrentHomwork] = useState<HomeworkTask>();
  const { LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const teacher_id = getLocalStorage('userId');
  const { setSelectedFunction } = useGlobalContext();

  const [StatusObject, updateStatusObject] = useState<IStatusObj | undefined>();
  const backButtonClick = async () => {
    await aiHomeworkCloseEvent(selected_hw_id);
    navigate(HOMEWORK_MANAGE);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    if (selected_hw_id) {
      getHomeworkDetails(selected_hw_id);
      const hw = homework_list_data?.assigned.find(
        (e) => e.homeworkId === selected_hw_id
      );
      setCurrentHomwork(hw);
    }
  }, []);

  const getHomeworkDetails = async (homeworkId: number) => {
    try {
      setLoading(true);
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getHWClassStats(
          {
            homeworkId: homeworkId,
          }
        );
      if (response.data) {
        // console.log(response.data);
        const StatusObj: IStatusObj = {};
        response.data.studentPerformanceInfo.forEach((e) => {
          const id = e.studentId.toString();
          StatusObj[id] = {
            studentId: e.studentId,
            isSubmitted: e.isSubmitted || false,
            marks: convertPercentageToMarks(
              e.scorePercent,
              response.data?.homework?.maxMarks
            ),
          };
        });
        updateStatusObject(StatusObj);
        console.log(StatusObj);
        await aiHomeworkOpenEvent(selected_hw_id);
        dispatch(setCurrentHomeworkClassStats(response.data));
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  const physicalHwSubmitHandler = async (props: ISubmissionProps) => {
    const { checkedValue, marks, studentData } = props;
    //if max marks are there student data is taken from student info because in that case a pop up is needed which requires that data but if maxmarks are not present
    //then whole operation is to be done on radio button click , so data cannot be taken form local variable
    if (!studentInfo && !studentData) {
      return;
    }
    const studentPerformaceData = studentInfo || studentData;
    const isSubmittedStatus = marks !== undefined ? true : checkedValue;
    if (marks !== undefined) {
      const regex = /^\d+$/;
      if (!regex.test(marks.toString())) {
        dispatch(
          setToastInfo({
            label: 'Marks can be whole numbers only',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
    }
    try {
      setLoading(true);
      const submissionsInfo = new StudentSubmissionInfo({
        studentId: studentPerformaceData?.studentId,
        isSubmitted: isSubmittedStatus,
      });
      if (isSubmittedStatus && marks !== undefined) {
        submissionsInfo.obtainedMarks = marks;
      }
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.studentHWPhysicalSubmissionUpdate(
          {
            teacherId: BigInt(teacher_id),
            homeworkId: selected_hw_id,
            submissionsInfo: [submissionsInfo],
            maxMarks: current_homework_classStats?.homework?.maxMarks || 0,
          }
        );
      if (response) {
        console.log('response PhysicalHwSubmitHandler', response);
        const id = studentPerformaceData?.studentId.toString();
        //making changes in local status obj
        if (id && StatusObject && StatusObject[id]) {
          StatusObject[id].marks = marks;
          StatusObject[id].isSubmitted = isSubmittedStatus; //changing the status
        }

        updateStatusObject(StatusObject);
        selected_hw_id && getHomeworkDetails(selected_hw_id);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const cardOnClick = (studentId: string) => {
    // setMarksPopupDisplay(true);
    // navigate(`${HOMEWORK_SUBMISSION_CUSTOM}/${studentId}`);
    const student = StatusObject && StatusObject[studentId.toString()];
    switch (current_homework_classStats?.homework?.submissionType) {
      case SubmissionType.SUBMISSION_OFFLINE:
        // if (current_homework_classStats.homework.maxMarks) {
        //   setMarksPopupDisplay(true);
        // }
        //replaced with marksTextClickHandler as per new reqs
        break;
      case SubmissionType.SUBMISSION_ONLINE:
        if (student?.isSubmitted) {
          navigate(`${HOMEWORK_SUBMISSION_CUSTOM}/${studentId}`);
        }
        break;
      default:
        navigate(`${HOMEWORK_PERFORMANCE}/${studentId}`);
    }
  };
  const marksTextClickHandler = (student: StudentPerformanceInfo) => {
    setStudentInfo(student);
    if (
      current_homework_classStats?.homework?.submissionType ===
      SubmissionType.SUBMISSION_OFFLINE
    ) {
      if (current_homework_classStats.homework.maxMarks) {
        setMarksPopupDisplay(true);
      }
    }
  };
  return loading === true ? (
    <Loader />
  ) : error === true ? (
    <Typography>Error Occured</Typography>
  ) : (
    <Box sx={styles.root}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: pxToRem(10), md: pxTovW(22) },
          padding: {
            xs: `${pxToRem(23)} ${pxToRem(20)}`,
            md: `${pxTovW(25)} 0`,
          },
          // backgroundColor: 'red',
        }}
      >
        <Box sx={styles.header}>
          <ImageWrapper
            name="chapterImage"
            type="png"
            parentFolder="tempAssets"
            path={getMediaBasePath(
              current_homework_classStats?.homework?.homeworkPosterImgUrl,
              'processedMediaBucket'
            )}
            styles={styles.headImage}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // height: { xs: pxToRem(74), md: pxTovW(120) },
              boxSizing: 'border-box',
              gap: { xs: pxToRem(14), md: pxTovW(16) },
              // justifyContent: 'space-between',
              width: '100%',
              // backgroundColor: 'red',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: pxToRem(250), md: pxTovW(790) },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                }}
              >
                {current_homework_classStats?.homework?.homeworkTitle ||
                  'title'}
              </Typography>
              <Typography
                variant={isIpadOnly ? 'h4' : 'button'}
                color="primary"
                fontWeight="regular"
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  width: '90%',
                }}
              >
                {current_homework_classStats?.homework?.class}
                {current_homework_classStats?.homework?.section}{' '}
                {current_homework_classStats?.homework?.subject} |{' '}
                {current_homework_classStats?.homework?.moduleName}
              </Typography>
            </Box>
            <Box sx={{ width: { xs: pxToRem(178), md: pxTovW(300) } }}>
              {current_homework_classStats?.homework?.maxMarks !==
                undefined && (
                <ScoreProgressBar
                  variant={largeScreen ? 'lg' : 'md'}
                  score={
                    Math.round(
                      Number(
                        current_homework_classStats?.homework?.classScore?.toFixed(
                          2
                        )
                      )
                    ) || 0
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '90%', md: pxToRem(300), lg: 'max-content' },
            display: {
              xs: current_homework_classStats?.homework?.submissionType
                ? 'flex'
                : 'none',
              md: 'block',
            },
            margin: { xs: 'auto', md: 'unset' },
            marginBottom: 'auto',
          }}
        >
          <PrimaryButton
            fullWidth={isIpadOnly ? true : false}
            onClick={async () => {
              navigate(`${HOMEWORK_VIEW}`);
              await interactionEvent({
                url: '',
                context: 'homework',
                name: 'VIEW_HOMEWORK',
                pathSegments: pathSegments,
              });
            }}
          >
            <Typography variant="h3" color="#FFFFFF" fontWeight={600}>
              VIEW HOMEWORK
            </Typography>
          </PrimaryButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: '100vw', md: '100%' },
          // backgroundColor: 'red',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: { lg: pxTovW(40) },
        }}
      >
        <HwDetails
          currentHomework={current_homework_classStats?.homework}
          assigendInfo={current_homework_classStats?.assignedStudentsCount}
          submissonCount={current_homework_classStats?.studentsSubmissionCount}
        />
        <StudentSection
          Class={`${current_homework_classStats?.homework?.class}${current_homework_classStats?.homework?.section}`}
          studentPerformanceInfoList={
            current_homework_classStats?.studentPerformanceInfo
          }
          cardMaxMarks={current_homework_classStats?.homework?.maxMarks}
          cardSubmissionType={
            current_homework_classStats?.homework?.submissionType
          }
          cardOnClickHandler={cardOnClick}
          checkBoxClickHandler={physicalHwSubmitHandler}
          statusInfo={StatusObject}
          // saveStudentInfo={setStudentInfo}
          textClickHandler={marksTextClickHandler}
        />
      </Box>
      <MarksSubmitPopup
        open={marksPopupDisplay}
        totalMarks={current_homework_classStats?.homework?.maxMarks || 0}
        handleClose={() => {
          setMarksPopupDisplay(false);
          setStudentInfo(undefined);
        }}
        submitClickHandler={physicalHwSubmitHandler}
      />
    </Box>
  );
};
