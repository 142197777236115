import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';

//
import {
  Documentation,
  FAQElement,
  Notifications,
} from '@geneo2-web/shared-ui';
import AppLayout from '../../app/Layouts/AppLayout';
import { AnalyticsDashboard } from '../../pages/Analytics/AnalyticsDashboard';
// import { ClassChapterScorePage } from '../../pages/Analytics/ChapterScorePage';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { ServiceClientProvider } from '../../app/Context/ServiceClientsProvider';
import HalfSplitAuthLayout from '../../app/Layouts/HalfSplitAuthLayout';
import { ClassChapterScorePage } from '../../pages/Analytics/ChapterScorePage';
import { ClassScorePage } from '../../pages/Analytics/ClassScorePage';
import { StudentChapterScorePage } from '../../pages/Analytics/StudentScorePage';
import { ChooseProfile } from '../../pages/Auth/Login/ChooseProfile';
import EnterUserIdentification from '../../pages/Auth/Login/EnterUserIdentification';
import { LoginNew } from '../../pages/Auth/Login/LoginNew';
import { ResetPassword } from '../../pages/Auth/Login/ResetPassword';
import { ResetPasswordOtp } from '../../pages/Auth/Login/ResetPasswordOtp';
import { MCBAppLogin } from '../../pages/Auth/MCBAppLogin/MCBAppLogin';
import { MCBLogin } from '../../pages/Auth/MCBLogin/MCBLogin';
import EnterUserIdRegistration from '../../pages/Auth/Registration/EnterUid';
import { OtpScreen } from '../../pages/Auth/Registration/OtpScreen';
import { SetPassword } from '../../pages/Auth/Registration/SetPassword';
import { VerifyDetails } from '../../pages/Auth/Registration/VerifyDetails';
import { VerifyEmail } from '../../pages/Auth/Registration/VerifyEmail';
import { VerifyPhone } from '../../pages/Auth/Registration/VerifyPhone';
import { StartClassroom } from '../../pages/ConnectedClassRoom/StartClassroom';
import { SelfRegistration } from '../../pages/Auth/SelfRegistration/SelfRegistration';
import { SelfRegistrationForm } from '../../pages/Auth/SelfRegistration/SelfRegistrationForm';
import { UserNameRegistration } from '../../pages/Auth/SelfRegistration/UserNameRegistration';
import Home from '../../pages/Home';
import { SeeAllLessonPlanList } from '../../pages/Home/SeeAllLessonPlanList';
import { AssignHomework } from '../../pages/Homework/AssignHomework';
import { ChapterResourcePage } from '../../pages/Homework/ChapterResourcepage';
import { CongratulationsPage } from '../../pages/Homework/CongratulationsPage';
import { CreateCustomHw } from '../../pages/Homework/CreateCustomHw';
import { CreateHomework } from '../../pages/Homework/CreateHomework';
import CurrentHomework from '../../pages/Homework/CurrentHomework';
import HwChapterSelection from '../../pages/Homework/HwChapterSelection';
import TopicSelection from '../../pages/Homework/TopicSelection';
import { ViewHomework } from '../../pages/Homework/ViewHomework';
import { CompletedHomework } from '../../pages/ManageHomework/CompletedHomework';
import { HwPerformance } from '../../pages/ManageHomework/HwPerformance';
import { ManageHomework } from '../../pages/ManageHomework/ManageHomeworkHome';
import { OngoingHomework } from '../../pages/ManageHomework/OngoingHomework';
import { SubmissionCustomHw } from '../../pages/ManageHomework/SubmissionCustomHw';
import DownloadSettings from '../../pages/OfflineScreens/Teach/DownloadSettings';
import { LessonPlanListOffline } from '../../pages/OfflineScreens/Teach/LessonPlanLists';
import { ResourcePageOffline } from '../../pages/OfflineScreens/Teach/ResourcePage';
import OfflineTeachChapterSelection from '../../pages/OfflineScreens/Teach/TeachChapterSelection';
import TeachingFlowOffline from '../../pages/OfflineScreens/Teach/TeachingFlow';
import TeachTopicSelectionOffline from '../../pages/OfflineScreens/Teach/TeachTopicSelection';
import { ChangePassword } from '../../pages/Sidebar/ChangePassword';
import { HelpAndSupport } from '../../pages/Sidebar/HelpAndSupport';
import { Profile } from '../../pages/Sidebar/Profile';
import { ProfileOtpScreen } from '../../pages/Sidebar/ProfileOtpScreen';
import { TeacherDisclaimer } from '../../pages/Sidebar/TeacherDisclaimer';
import AddResources from '../../pages/Teach/AddResources';
import EditLessonPlan from '../../pages/Teach/EditLessonPlan';
import LessonPlanLists from '../../pages/Teach/LessonPlanLists';
import ReplaceResources from '../../pages/Teach/ReplaceResources';
import { ResourcePage } from '../../pages/Teach/ResourcePage';
import TeachChapterSelection from '../../pages/Teach/TeachChapterSelection';
import TeachingFlow from '../../pages/Teach/TeachingFlow';
import TeachTopicSelection from '../../pages/Teach/TeachTopicSelection';
import {
  ADD_RESOURCES,
  ANALYTICS_CHAPTER_SCORE,
  ANALYTICS_CLASS_SCORE,
  ANALYTICS_CLASS_SELECTION,
  ANALYTICS_STUDENT_SCORE,
  CHANGE_PASSWORD,
  CHOOSE_PROFILE,
  CONNECTED_CLASS_RESULTS,
  DISCLAIMER,
  DOCS,
  DOWNLOAD_SETTINGS,
  EDIT_LESSON_PLAN,
  ENTER_UID,
  FAQ,
  HELP,
  HOME,
  HOMEWORK_ASSIGN,
  HOMEWORK_CHAPTER_SELECTION,
  HOMEWORK_COMPLETED,
  HOMEWORK_CONGRATULATIONS,
  HOMEWORK_CREATE,
  HOMEWORK_CURRENT,
  HOMEWORK_CURRENT_CUSTOM,
  HOMEWORK_MANAGE,
  HOMEWORK_ONGOING,
  HOMEWORK_PERFORMANCE,
  HOMEWORK_SUBMISSION_CUSTOM,
  HOMEWORK_TOPIC_SELECTION,
  HOMEWORK_VIEW,
  LESSON_PLAN_LISTS,
  LOGIN,
  LOGIN_PASSWORD,
  MCB_APP_LOGIN,
  MCB_LOGIN,
  NOTIFICATION,
  OFFLINE_LESSON_PLAN_LISTS,
  OFFLINE_RESOURCE,
  OFFLINE_TEACH_CHAPTER_SELECTION,
  OFFLINE_TEACH_TOPIC_SELECTION,
  OFFLINE_TEACHING_FLOW,
  OTP_SCREEN,
  PROFILE,
  PROFILE_OTP_SCREEN,
  REGISTRATION,
  REPLACE_RESOURCE,
  RESET_PASSWORD,
  RESET_PASSWORD_OTP,
  RESOURCE_PAGE,
  SEEALL_LESSON_PLAN_LIST,
  SELF_REGISTRATION,
  SELF_REGISTRATION_FORM,
  SET_PASSWORD,
  START_CLASSROOM,
  TEACH_CHAPTER_SELECTION,
  TEACH_TOPIC_SELECTION,
  TEACHING_FLOW,
  USER_NAME_REGISTRATION,
  VERIFY_DETAILS,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../RoutesNomenclature';
import PrivateRouterWrapper from './PrivateRoutersWrapper';

import { ConnectedClassResults } from '../../pages/ConnectedClassRoom/ResultsPage';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<ServiceClientProvider />}>
      {/* //! ------------- A U T H ------------- */}
      <Route path="/" element={<HalfSplitAuthLayout />}>
        <Route path={'/'} element={<Navigate to={ENTER_UID} replace />} />
        <Route path={LOGIN} element={<Navigate to={ENTER_UID} replace />} />
        <Route path={ENTER_UID} element={<EnterUserIdentification />} />
        <Route path={VERIFY_PHONE} element={<VerifyPhone />} />
        <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
        <Route path={OTP_SCREEN} element={<OtpScreen />} />
        <Route path={LOGIN_PASSWORD} element={<LoginNew />} />
        <Route path={VERIFY_DETAILS} element={<VerifyDetails />} />
        <Route path={SET_PASSWORD} element={<SetPassword />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={RESET_PASSWORD_OTP} element={<ResetPasswordOtp />} />
        <Route path={REGISTRATION} element={<EnterUserIdRegistration />} />
        <Route path={SELF_REGISTRATION} element={<SelfRegistration />} />
        <Route
          path={USER_NAME_REGISTRATION}
          element={<UserNameRegistration />}
        />
        <Route
          path={SELF_REGISTRATION_FORM}
          element={<SelfRegistrationForm />}
        />
      </Route>
      <Route path={MCB_LOGIN} element={<MCBLogin />} />
      <Route path={MCB_APP_LOGIN} element={<MCBAppLogin />} />
      <Route path={CHOOSE_PROFILE} element={<ChooseProfile />} />

      {/*//! ---------------- A P P L I C A T I O N ----------------------- */}
      <Route path="/" element={<AppLayout />}>
        <Route element={<PrivateRouterWrapper />}>
          <Route path="/" element={<Navigate to={HOME} replace />} />
          <Route path={HOME} element={<Home />} />

          <Route
            path={SEEALL_LESSON_PLAN_LIST}
            element={<SeeAllLessonPlanList />}
          />
          {/* <Route path="/header" element={<HeaderMidLogo />} /> */}

          {/*//! ----------- N E W - H O M E W O R K--------------- */}
          <Route
            path={`${HOMEWORK_CHAPTER_SELECTION}/:subject_id`}
            element={<HwChapterSelection />}
          />
          <Route
            path={`${HOMEWORK_TOPIC_SELECTION}/:subject_id/:chapter_id`}
            element={<TopicSelection />}
          />
          <Route
            path={`${HOMEWORK_CREATE}/:module_id`}
            element={<CreateHomework />}
          />
          <Route path={`${HOMEWORK_ASSIGN}`} element={<AssignHomework />} />
          {/* <Route
            path={`${HOMEWORK_QUESTIONBANK}/:module_id`}
            element={<SearchQuestions />}
          />
          <Route
            path={`${HOMEWORK_REVIEW}/:module_id`}
            element={<ReviewHomeowork />}
          /> */}
          <Route
            path={`${HOMEWORK_CURRENT}/:module_id`}
            element={<CurrentHomework />}
          />
          <Route
            path={`${HOMEWORK_CONGRATULATIONS}/:homeworkId`}
            element={<CongratulationsPage />}
          />
          {/* current-custom-homework */}
          <Route
            path={`${HOMEWORK_CURRENT_CUSTOM}/:module_id`}
            element={<CreateCustomHw />}
          />
          {/* <Route
            path={`${HOMEWORK_CURRENT_CUSTOM}`}
            element={<CreateCustomHw />}
          /> */}

          {/* submission-custom-hw */}
          <Route
            path={`${HOMEWORK_SUBMISSION_CUSTOM}/:student_id`}
            element={<SubmissionCustomHw />}
          />

          {/*//! ----------- M A N A G E - H O M E W O R K--------------- */}

          <Route path={HOMEWORK_MANAGE} element={<ManageHomework />} />
          <Route path={`${HOMEWORK_ONGOING}`} element={<OngoingHomework />} />
          <Route
            path={`${HOMEWORK_COMPLETED}`}
            element={<CompletedHomework />}
          />
          <Route
            path={`${HOMEWORK_PERFORMANCE}/:student_id`}
            element={<HwPerformance />}
          />
          <Route path={HOMEWORK_VIEW} element={<ViewHomework />} />

          {/* //! ------------- A N A L Y T I C S ------------- */}
          <Route
            path={ANALYTICS_CLASS_SELECTION}
            element={<AnalyticsDashboard />}
          />
          <Route path={ANALYTICS_CLASS_SCORE} element={<ClassScorePage />} />
          <Route
            path={ANALYTICS_CHAPTER_SCORE}
            element={<ClassChapterScorePage />}
          />
          <Route
            path={ANALYTICS_STUDENT_SCORE}
            element={<StudentChapterScorePage />}
          />

          {/* //! ------------- T E A C H ------------- */}
          <Route
            path={`${TEACH_CHAPTER_SELECTION}/:subject_id`}
            element={<TeachChapterSelection />}
          />
          <Route
            path={`${TEACH_TOPIC_SELECTION}/:subject_id/:chapter_id`}
            element={<TeachTopicSelection />}
          />
          <Route
            path={`${LESSON_PLAN_LISTS}/:subject_id/:chapter_id/:topic_id`}
            element={<LessonPlanLists />}
          />
          {/*  ----------- Connected-classroom--------------- */}
          <Route
            path={`${START_CLASSROOM}/:subject_id/:topic_id/:lesson_id`}
            element={<StartClassroom />}
          />

          <Route
            path={CONNECTED_CLASS_RESULTS}
            element={<ConnectedClassResults />}
          />
          <Route
            // path={`${TEACHING_FLOW}/:lesson_id`}
            path={`${TEACHING_FLOW}/:subject_id/:chapter_id/:topic_id/:lesson_id`}
            element={<TeachingFlow />}
          />

          <Route
            // path={`${EDIT_LESSON_PLAN}/:lesson_id`}
            path={`${EDIT_LESSON_PLAN}/:subject_id/:chapter_id/:topic_id/:lesson_id`}
            element={<EditLessonPlan />}
          />

          <Route
            path={`${ADD_RESOURCES}/:subject_id/:chapter_id/:topic_id/:lesson_id/:resource_index`}
            element={<AddResources />}
          />

          <Route
            path={`${REPLACE_RESOURCE}/:subject_id/:chapter_id/:topic_id/:lesson_id/:resource_index`}
            element={<ReplaceResources />}
          />

          <Route path={`${RESOURCE_PAGE}`} element={<ResourcePage />} />

          <Route
            path={'/chapter-resource-page'}
            element={<ChapterResourcePage />}
          />
          {/* //!--------------OFFLINE SCREENS------------- */}
          <Route
            path={`${OFFLINE_TEACH_CHAPTER_SELECTION}/:subject_id`}
            element={<OfflineTeachChapterSelection />}
          />
          <Route
            path={`${OFFLINE_TEACH_TOPIC_SELECTION}/:subject_id/:chapter_id`}
            element={<TeachTopicSelectionOffline />}
          />
          <Route
            path={`${OFFLINE_LESSON_PLAN_LISTS}/:subject_id/:chapter_id/:topic_id`}
            element={<LessonPlanListOffline />}
          />
          <Route
            path={`${OFFLINE_TEACHING_FLOW}/:subject_id/:chapter_id/:topic_id/:lesson_plan_id`}
            element={<TeachingFlowOffline />}
          />
          <Route
            path={`${OFFLINE_RESOURCE}/:subject_id/:chapter_id/:topic_id/:lesson_plan_id/:resource_id`}
            element={<ResourcePageOffline />}
          />
          <Route path={`${DOWNLOAD_SETTINGS}`} element={<DownloadSettings />} />
          {/* //!--------------SIDEBAR------------- */}
          <Route path={PROFILE} element={<Profile />} />
          <Route path={PROFILE_OTP_SCREEN} element={<ProfileOtpScreen />} />
        </Route>
        <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={`${NOTIFICATION}`} element={<Notifications />} />
        <Route path={`${HELP}`} element={<HelpAndSupport />} />
        <Route
          path={`${FAQ}`}
          element={<FAQElement role={ProfileRolesEnum.PROFILE_ROLE_TEACHER} />}
        />
        <Route path={`${DISCLAIMER}`} element={<TeacherDisclaimer />} />
      </Route>

      <Route path={DOCS} element={<Documentation />} />
    </Route>
  )
);
