import {
  IStyles,
  ImageWrapper,
  StepsBadge,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Box, Modal, Slide, Typography } from '@mui/material';
interface Iprops {
  open: boolean;
  handleClose?: () => void;
}
const styles: IStyles = {
  modal: { boxSizing: 'border-box' },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },

  steps: {
    width: { xs: pxToRem(320), md: pxToRem(400), lg: pxTovW(634) },
    height: { xs: 'max-content', md: 'max-content' },
    paddingX: { xs: pxToRem(20), md: pxTovW(45) },
    paddingY: { xs: pxToRem(25), md: pxTovW(45) },
    boxSizing: 'border-box',
    background: '#FFFFFF',
    borderRadius: pxToRem(10),
    marginX: 'auto',
    marginTop: pxToRem(20),
    overflowY: 'auto',
    mt: { md: '8vw' },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(30), md: pxTovW(30) },
  },
  stepBox: {
    display: 'flex',
    gap: { xs: pxToRem(11), md: pxTovW(11) },
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: { xs: 'column', md: 'row' },
  },

  clickerBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(23) },
  },
  clicker: {
    width: { xs: pxToRem(112), md: pxTovW(187) },
    height: { xs: pxToRem(179), md: pxTovW(298) },
  },
};
export const Steps = (props: Iprops) => {
  const { open, handleClose } = props;
  return (
    <Modal open={open} onClose={handleClose && handleClose}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit timeout={500}>
        <Box sx={styles.root}>
          <Box sx={styles.steps}>
            <Box>
              <Typography variant="h2" textAlign="center" fontWeight="bold">
                Welcome To Your Connected Class.
              </Typography>
              <Typography variant="h3" textAlign="center">
                Follow these steps for the best experience.
              </Typography>
            </Box>

            <Box sx={styles.stepBox}>
              <StepsBadge
                number={1}
                backgroundColor="#FFF4CA"
                bordercolor="#F8C807"
              />
              <Typography variant="h4" fontWeight="bold">
                Distribute clickers to students as per the registered clicker
                numbers.
              </Typography>
            </Box>

            <Box sx={styles.clickerBox}>
              <ImageWrapper
                name="clicker_back_web"
                parentFolder="images"
                type="png"
                styles={styles.clicker}
              />
              <ImageWrapper
                name="clicker_front_web"
                parentFolder="images"
                type="png"
                styles={styles.clicker}
              />
            </Box>

            <Box sx={styles.stepBox}>
              <StepsBadge
                number={2}
                backgroundColor="#E8F5FF"
                bordercolor="#007CDC"
              />
              <Typography variant="h4" fontWeight="bold">
                Turn ON the clicker by pressing the middle button to join the
                connected class.
              </Typography>
            </Box>

            <Box sx={styles.stepBox}>
              <StepsBadge
                number={3}
                backgroundColor="#FFE4E4"
                bordercolor="#F54040"
              />
              <Typography variant="h4" fontWeight="bold">
                Check how many students have joined and start teaching when
                ready.
              </Typography>
            </Box>

            {/* <Box sx={styles.stepBox}>
                <StepsBadge
                  number={4}
                  backgroundColor="#E7FDF1"
                  bordercolor="#0AA34F"
                />
                <Box>
                  <Typography variant="h3">
                    To give correct answers with clickers,
                  </Typography>
                  <Typography variant="h3" fontWeight={'medium'}>
                    Please ‘’SELECT” the correct answer from“ A,B,C,D”.
                  </Typography>
                </Box>
              </Box> */}
          </Box>

          <Box
            sx={{ marginX: 'auto', marginY: pxToRem(20), cursor: 'pointer' }}
            onClick={handleClose}
          >
            <ImageWrapper
              name="close2"
              parentFolder="icons"
              type="png"
              styles={{ width: pxToRem(35), height: pxToRem(36) }}
            />
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};
