import { ConnectError } from '@bufbuild/connect';
import {
  Loader,
  callTeacherEventsApi,
  clearLocalStorageKeys,
  deleteOfflineAccessKeyFromCache,
  deserify,
  getDeviceType,
  getLocalStorage,
  setLocalStorage,
  storeOfflineAccessKeyInCache,
  useCommonServiceClientContext,
  useNotificationPermission,
} from '@geneo2-web/shared-ui';
import {
  DeviceEnum,
  ProfileRolesEnum,
} from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import { HOME } from '../../../routeHandling/RoutesNomenclature';
import { resetAnalyticsState } from '../../Analytics/reducer/analytics.slice';
import { aiBookCloseEvent } from '../../Home/home.events';
import { resetHomeDashboardState } from '../../Home/reducer/homeDashboard.slice';
import { aiHomeworkCloseEvent } from '../../Homework/homework.events';
import { resetHomeworkState } from '../../Homework/reducer/homework.slice';
import { resetManageHWState } from '../../ManageHomework/reducer/manageHomework.slice';
import {
  resetTeachState,
  setSelectedResourceSessionId,
} from '../../Teach/reducer/teach.slice';
import {
  aiChapterCloseEvent,
  aiLessonCloseEvent,
  aiTopicCloseEvent,
} from '../../Teach/teach.events';
import { aiLoginEvent, aiLogoutEvent, interactionEvent } from '../auth.events';
import {
  resetAuthState,
  setIsMCBUser,
  setSsoRedirectionUrl,
  setUserInfo,
} from '../reducer/auth.slice';
import { getSessionData } from '@events/teacher/eventsCreator/utils';
// import { useDownloadContext } from 'apps/teacher/src/app/Context/DownloadContextProviderV2';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { MCBErrorScreen } from '../MCBLogin/components/MCBErrorScreen';

export const MCBAppLogin = () => {
  const location = useLocation();
  const guid = new URLSearchParams(location.search).get('guid') || undefined;
  const token = new URLSearchParams(location.search).get('token') || undefined;

  const { isOffline } = useDownloadContext();
  console.log('isOffline', isOffline);
  const navigate = useNavigate();
  let auth = getLocalStorage('auth');
  if (auth === 'true' || auth === 'false') {
    auth = JSON.parse(auth);
  }
  const commonServiceClientContext = useCommonServiceClientContext();
  const {
    UmsSsoAPIServiceV1ClientWithStatusCodeHandler,
    UMSLoginAPIServiceV1ClientWithStatusCodeHandler,
    UmsUtilityAPIServiceV1ClientWithStatusCodeHandler,
  } = commonServiceClientContext;

  useEffect(() => {
    geneoLoginUsingMCBCode();
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const android = window.Android;
  const dispatch = useDispatch();
  const deviceType = getDeviceType();
  const userInfo = deserify(useAppSelector((state) => state.auth.user_info));
  const userId = getLocalStorage('userId');
  const fcmToken = getLocalStorage('fcmToken'); // Convert fcmToken to string
  const deviceId = getLocalStorage('deviceID');
  function pushUserDetailsToAndroid(uToken: string, userID: string): void {
    try {
      if (deviceType === 'android') {
        android.pushUserDetails(uToken, userID);
      }
    } catch (error) {
      console.error('Error pushing user details to Android', error);
    }
  }

  const { chapter_resources, selected_topic_info, selected_lessons_info } =
    deserify(useAppSelector((state) => state.teach));
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { selected_hw_id } = deserify(
    useAppSelector((state) => state.manageHomework)
  );

  const { permissionGranted, requestPermission } =
    useNotificationPermission('teacher');
  const closeAiFunctions = async () => {
    await aiLessonCloseEvent(selected_lessons_info?.lessonId);
    await aiTopicCloseEvent(selected_topic_info?.topicId);
    await aiChapterCloseEvent(chapter_resources?.chapterId);
    await aiBookCloseEvent(class_subject_info?.bookId);
    await aiHomeworkCloseEvent(selected_hw_id);
    await aiLogoutEvent();
    dispatch(setSelectedResourceSessionId(undefined));
    // a call to clear backlog of events stored in indexDb
    !isOffline && (await callTeacherEventsApi(commonServiceClientContext));
  };

  const notificationAPI = async () => {
    if (!userId) {
      console.log('User ID not found');
      return;
    }

    try {
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        profileId: BigInt(userId),
        role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        deviceType: DeviceEnum.DEVICE_TYPE_WEB,
        deviceToken: fcmToken?.toString(),
        isActive: false,
        deviceId: deviceId ? BigInt(deviceId) : undefined,
      });
      await UmsUtilityAPIServiceV1ClientWithStatusCodeHandler.saveDeviceInfo({
        deviceToken: fcmToken?.toString(),
        topic: `${String(userId)}_${ProfileRolesEnum.PROFILE_ROLE_TEACHER}`,
        subscribeTopic: false,
      });
    } catch (err) {
      console.error('Error in Notification API:', err);
    }
  };

  const handleLogout = async () => {
    try {
      closeAiFunctions();
      if (fcmToken) {
        await notificationAPI();
      }
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.logout({
          profileId: getLocalStorage('userId'),
          role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
        });
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(resetAuthState());
      dispatch(resetHomeDashboardState());
      dispatch(resetHomeworkState());
      dispatch(resetManageHWState());
      dispatch(resetTeachState());
      dispatch(resetAnalyticsState());
      // localStorage.clear();
      clearLocalStorageKeys();
      deleteOfflineAccessKeyFromCache();
      // navigate(LOGIN);
    }
  };

  const geneoLoginUsingMCBCode = async () => {
    try {
      if (token && guid) {
        const res =
          await UmsSsoAPIServiceV1ClientWithStatusCodeHandler.validateUserSsoLoginV2(
            {
              token,
              uniqueId: guid,
              role: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
              requestOrigin: 'MCB',
            }
          );
        const userId = res.userInfo?.profileId;
        const ssoRedirectionUrl = res.ssoRedirectionUrl;
        if (auth && userId) {
          if (userId !== userInfo?.teacherProfileId) {
            await handleLogout();
          } else {
            navigate(HOME);
            return;
          }
        }
        const resSession =
          await UmsSsoAPIServiceV1ClientWithStatusCodeHandler.createSsoLoginSession(
            {
              profileId: userId,
            }
          );
        if (ssoRedirectionUrl) {
          dispatch(setSsoRedirectionUrl(ssoRedirectionUrl));
        }
        if (resSession.activeSessionId) {
          setLocalStorage(
            'activeSession',
            resSession.activeSessionId.toString()
          );
        }
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchTeacherProfile(
            {
              teacherProfileId: userId,
            }
          );
        const user_info = response.data;
        if (!user_info) {
          console.log('User info not present');
          return;
        }
        dispatch(setUserInfo(user_info));
        dispatch(setIsMCBUser(true));
        setLocalStorage('userId', user_info.teacherProfileId.toString());
        setLocalStorage('role', 'student');
        setLocalStorage('auth', 'true');
        const accessKey = user_info.schoolDetails[0]?.schoolId.toString()
          ? 'school-' + user_info.schoolDetails[0].schoolId.toString()
          : 'geneo';
        await storeOfflineAccessKeyInCache(accessKey);
        setTimeout(async () => {
          await interactionEvent({
            url: 'teacher_login',
            context: 'mcb_sso',
            name: 'Login',
          });
        }, 3000);
        await aiLoginEvent({
          teacherId: Number(user_info.teacherProfileId),
          sessionId: getSessionData().sessionId,
        });
        pushUserDetailsToAndroid(
          user_info.token,
          user_info.teacherProfileId.toString()
        );
        navigate(HOME);
        await requestPermission();
      }
    } catch (err: any) {
      if (err instanceof ConnectError) {
        setErrorMessage(err.message.replace(/.*\]\s*/, '').trim());
        return;
      }
      setErrorMessage('Internal Error');
    }
  };
  if (errorMessage || !(guid || token)) {
    return (
      <MCBErrorScreen
        errorMessage={errorMessage || 'Invalid unique id or token'}
      />
    );
  }
  return <Loader />;
};
