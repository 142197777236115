import { Code } from '@bufbuild/connect';
import { PartialMessage } from '@bufbuild/protobuf';
import {
  IStyles,
  IconWrapper,
  InputField,
  PrimaryButton,
  SelectMenu,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import {
  ClassSectionSubject,
  SchoolData,
} from '@protos/user_management/ums.self_registration.apis_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { VERIFY_DETAILS } from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../auth.events';
import {
  SelectedClassSectionSubject,
  setSchoolData,
  setSelfRegistrationData,
  setToastInfo,
} from '../../reducer/auth.slice';
const styles: IStyles = {
  root: {
    // height: { xs: '100%', md: pxTovW(1045) },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: { xs: 'flex-start', md: 'center' },
    marginY: { md: 'auto' },
    gap: { xs: pxToRem(10), md: pxTovW(30) },
  },
  heading: {
    marginBottom: { xs: pxToRem(10), md: pxTovW(20) },
    textAlign: { xs: 'start', md: 'center' },
  },
  mainContainer: {
    width: { xs: '100%', md: pxTovW(774) },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(20), md: pxTovW(30) },
  },
  textContainer: {
    background: '#EEEEEE',
    borderRadius: { xs: pxToRem(5), md: pxTovW(15) },
    padding: { xs: pxToRem(15), md: `${pxTovW(20)}` },
  },
  ButtonContainer: {
    display: 'flex',
    alignSelf: 'center',
    width: { xs: '100%', md: pxTovW(517) },
    height: { xs: pxToRem(55), md: pxTovW(77) },
    marginTop: { xs: pxToRem(10), md: pxTovW(10) },
  },
  OptionsContainer: {
    width: { xs: '100%', md: pxTovW(774) },
    display: 'flex',
    justifyContent: 'space-between',
    gap: { xs: pxToRem(10), md: pxTovW(15) },
  },
  optionBox: {
    width: { xs: '100%', md: pxTovW(230) },
    height: { xs: pxToRem(35), md: pxTovW(75) },
    alignContent: 'center',
    background: '#DAF5FF',
    borderRadius: { xs: pxToRem(5), md: pxTovW(15) },
    border: '1px solid #61BAFF',
  },
  optionText: {
    width: { xs: pxToRem(85), md: pxTovW(200) },
    display: '-webkit-box',
    WebkitLineClamp: { xs: 1, md: 2 },
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'center',
    alignSelf: 'center',
    marginX: 'auto',
    fontWeight: '500',
  },
  InputFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(7), md: pxTovW(12) },
  },
  AddButton: {
    width: { xs: pxToRem(69) },
    height: { xs: pxToRem(32) },
    display: { xs: 'flex', md: 'none' },
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'secondary.main',
    borderRadius: { xs: pxToRem(5), md: pxTovW(10) },
  },
};

interface IOptionList {
  name: string | number;
  id: number;
  nonEditableItem?: boolean;
}

export const SelfRegistrationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const { UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler } =
    useCommonServiceClientContext();
  const { self_registration_data, school_data } = deserify(
    useAppSelector((state) => state.auth)
  );

  const [code, setCode] = useState('');
  const [currentRole, setCurrentRole] = useState('Teacher');
  const [addClass, setAddClass] = useState(true);
  const [currentGrade, setCurrentGrade] = useState<IOptionList | undefined>();
  const [gradeList, setGradeList] = useState<IOptionList[]>([]);
  const [currentSection, setCurrentSection] = useState<
    IOptionList | undefined
  >();
  const [sectionList, setSectionList] = useState<IOptionList[]>([]);
  const [currentSubject, setCurrentSubject] = useState<
    IOptionList | undefined
  >();
  const [currentClassList, setCurrentClassList] = useState<
    SelectedClassSectionSubject[]
  >([]);
  const [subjectList, setSubjectList] = useState<IOptionList[]>([]);

  const transformAndSortClassDetails = (data: SchoolData) => {
    return Array.from(
      new Map(
        data?.schoolClassSectionDetails.map((detail) => [
          detail.className,
          { name: detail.className, id: detail.classId },
        ])
      ).values()
    )
      .map((data) => ({
        name: data.name,
        id: data.id,
        nonEditableItem: false,
      }))
      .sort((a, b) => Number(a.name) - Number(b.name));
  };

  const handleAdd = async () => {
    const payload: SelectedClassSectionSubject = {
      class_id: currentGrade?.id || 0,
      class_name: currentGrade?.name.toString() || '',
      section_id: currentSection?.id || 0,
      section_name: currentSection?.name.toString() || '',
      subject_id: currentSubject?.id || 0,
      subject_name: currentSubject?.name.toString() || '',
    };

    const duplicate = currentClassList.find(
      (e) =>
        e.class_name === payload.class_name &&
        e.section_name === payload.section_name &&
        e.subject_name === payload.subject_name
    );

    if (duplicate) {
      dispatch(
        setToastInfo({
          label: 'Class and Subject Already Selected',
          variant: 'error',
          open: true,
        })
      );
    } else {
      setCurrentClassList([...(currentClassList || []), payload]);
      setCurrentGrade(undefined);
      setCurrentSection(undefined);
      setCurrentSubject(undefined);
      setAddClass(false);
    }

    setGradeList(
      gradeList.map((listData) => {
        return { ...listData, nonEditableItem: false };
      })
    );
    setSectionList(
      sectionList.map((listData) => {
        return { ...listData, nonEditableItem: false };
      })
    );
    setSubjectList(
      subjectList.map((listData) => {
        return { ...listData, nonEditableItem: false };
      })
    );
    await interactionEvent({
      url: '',
      context: 'class_subject_data',
      name: 'ADD',
      pathSegments: pathSegments,
    });
  };
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const schoolCodeValidator = async () => {
    try {
      const response =
        await UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler.validateSchoolCode(
          { schoolCode: code.trim() }
        );

      if (response.status === 200) {
        dispatch(setSchoolData(response.data));

        response.data &&
          setGradeList(transformAndSortClassDetails(response.data));

        dispatch(
          setSelfRegistrationData({
            ...self_registration_data,
            school_code: response.data?.schoolCode,
            school_name: response.data?.schoolName,
          })
        );
        await interactionEvent({
          url: '',
          context: 'school_code',
          name: 'VALIDATE',
          pathSegments: pathSegments,
        });
      } else {
        dispatch(
          setSelfRegistrationData({
            ...self_registration_data,
            school_code: undefined,
            school_name: undefined,
          })
        );
        dispatch(setSchoolData(undefined));
      }
    } catch (error: any) {
      console.log('error:', error);

      if (error.code === Code.NotFound) {
        dispatch(
          setToastInfo({
            label: 'School Code is incorrect.',
            variant: 'error',
            open: true,
          })
        );
      } else {
        dispatch(
          setToastInfo({
            label: 'School Code verification failed.',
            variant: 'error',
            open: true,
          })
        );
      }

      dispatch(
        setSelfRegistrationData({
          ...self_registration_data,
          school_code: undefined,
          school_name: undefined,
        })
      );
      dispatch(setSchoolData(undefined));
    }
  };

  const nextHandle = async () => {
    const classSectionSubjects: PartialMessage<ClassSectionSubject>[] =
      currentClassList.map((item) => ({
        classId: item.class_id,
        sectionId: item.section_id,
        subjectId: item.subject_id,
      }));

    try {
      const response =
        await UmsSelfRegistrationAPIServiceV1ClientWithLStatusCodeHandler.selfRegisterTeacher(
          {
            firstName: self_registration_data?.first_name,
            lastName: self_registration_data?.last_name,
            phoneNumber: self_registration_data?.phone_number,
            role: self_registration_data?.role,
            schoolId: school_data?.schoolId,
            classSectionSubjects: classSectionSubjects,
          }
        );

      if (response.status === 200) {
        dispatch(
          setSelfRegistrationData({
            ...self_registration_data,
            user_profile_id: response.teacherProfileId.toString(),
            detailed_class_section_subjects: currentClassList,
          })
        );

        navigate(VERIFY_DETAILS);

        await interactionEvent({
          url: '',
          context: 'form_submit',
          name: 'NEXT',
          pathSegments: pathSegments,
        });
      }
    } catch (error: any) {
      console.log('error:', error);

      dispatch(
        setToastInfo({
          label: 'Self Registration failed.',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  const handleDelete = async (classListItem: SelectedClassSectionSubject) => {
    const updatedList: SelectedClassSectionSubject[] = currentClassList.filter(
      (item) => item !== classListItem
    );
    setCurrentClassList(updatedList);
    await interactionEvent({
      url: '',
      context: 'class_subject_data',
      name: 'REMOVE',
      pathSegments: pathSegments,
    });
  };

  useEffect(() => {
    if (self_registration_data?.school_code) {
      setCode(self_registration_data?.school_code);
    }
    if (self_registration_data?.detailed_class_section_subjects) {
      setCurrentClassList(
        self_registration_data?.detailed_class_section_subjects
      );
    }

    if (school_data) {
      setGradeList(transformAndSortClassDetails(school_data));
    }
  }, []);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.heading}>
        <Typography variant="h1">Start Your</Typography>
        <Typography variant="h1" color="primary.main">
          Teaching Journey
        </Typography>
      </Box>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.InputFieldContainer}>
          <Typography variant="cardText">School Code</Typography>
          <InputField
            // topLabel="School Code"
            value={code}
            onChange={handleCodeChange}
            variant="outlined"
            fullWidth
            nonCircular
            borderColor="#61BAFF"
            fontColor="#1D1D1D"
            sx={{
              background: '#DAF5FF',
            }}
            // onEnter={() => schoolCodeValidator()}
            // nonEditable={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    sx={{
                      width: { xs: pxToRem(80), md: pxTovW(119) },
                      height: { xs: pxToRem(30), md: pxTovW(43) },
                      background: code.trim() === '' ? '#a0dbbb' : '#0AA34F',
                      '&:hover': {
                        backgroundColor: '#0AA34F',
                      },
                    }}
                    disabled={code.trim() === ''}
                    onClick={() => schoolCodeValidator()}
                  >
                    <Typography
                      variant="h4"
                      fontWeight={'semiBold'}
                      color="success.light"
                    >
                      VALIDATE
                    </Typography>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {self_registration_data?.school_code &&
          self_registration_data?.school_name && (
            <Box sx={styles.textContainer}>
              <Typography variant="bodyText">
                {self_registration_data?.school_name}
              </Typography>
            </Box>
          )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(10), md: pxTovW(12) },
          }}
        >
          <Typography variant="cardText">Select Role</Typography>
          <SelectMenu
            value={currentRole}
            // todo: based on role selected change role ion setSelfRegistrationData
            onChange={async (role: string) => {
              setCurrentRole(role);
              await interactionEvent({
                url: '',
                context: 'select_role',
                name: role.toUpperCase(),
                pathSegments: pathSegments,
              });
            }}
            optionList={[
              {
                name: 'Teacher',
                id: 1,
              },
              {
                name: 'Coordinator',
                nonEditableItem: true,
                id: 2,
              },
              {
                name: 'Principal',
                nonEditableItem: true,
                id: 3,
              },
            ]}
            rootStyles={{ width: '100%' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: pxToRem(11), md: pxTovW(20) },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(10), md: pxTovW(20) },
            }}
          >
            <Typography variant="cardText">Choose Your Classes</Typography>
            {currentClassList.length !== 0 && (
              <Box
                sx={{
                  width: { xs: `calc(100%-${pxToRem(5)})`, md: pxTovW(820) },
                  display: 'flex',
                  flexDirection: 'column',
                  // maxHeight: { md: pxTovW(280) },
                  // minHeight: { md: 'max-content' },
                  gap: { xs: pxToRem(10), md: pxTovW(20) },
                  // overflowY: { md: 'auto' },
                  // scrollbarWidth: 'thin',
                  border: '1px solid #CFCFCF',
                  background: '#F8F8F8',
                  p: {
                    xs: `${pxToRem(15)} ${pxToRem(10)}`,
                    md: `${pxTovW(15)} ${pxTovW(10)}`,
                  },
                  borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
                }}
              >
                {currentClassList.map((classListItem, index) => (
                  <Box
                    sx={{
                      width: { xs: '100%', md: pxTovW(820) },
                      display: 'flex',
                      gap: { xs: pxToRem(8), md: pxTovW(10) },
                    }}
                  >
                    <Box key={index} sx={styles.OptionsContainer}>
                      <Box sx={styles.optionBox}>
                        <Typography variant="bodyText" sx={styles.optionText}>
                          {classListItem.class_name}
                        </Typography>
                      </Box>
                      <Box sx={styles.optionBox}>
                        <Typography variant="bodyText" sx={styles.optionText}>
                          {classListItem.section_name}
                        </Typography>
                      </Box>
                      <Box sx={styles.optionBox}>
                        <Typography variant="bodyText" sx={styles.optionText}>
                          {classListItem.subject_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <IconWrapper
                        name="delete2"
                        size="md"
                        parentFolder="icons"
                        type="png"
                        onClick={() => {
                          handleDelete(classListItem);
                        }}
                        customSx={{
                          width: { xs: pxToRem(20), md: pxTovW(35) },
                          height: { xs: pxToRem(20), md: pxTovW(35) },
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {(addClass || currentClassList.length === 0) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: { xs: pxToRem(10), md: pxTovW(10) },
                  width: { md: pxTovW(820) },
                  p: {
                    xs: `${pxToRem(15)} ${pxToRem(10)}`,
                    md: `${pxTovW(15)} ${pxTovW(10)}`,
                  },
                }}
              >
                <Box
                  sx={{
                    width: { md: pxTovW(774) },
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: pxToRem(10) },
                    justifyContent: 'space-between',
                  }}
                >
                  <SelectMenu
                    disabled={!school_data}
                    placeholder={'Select Grade'}
                    value={currentGrade?.name.toString() || ''}
                    optionList={gradeList}
                    onChange={async (chosenGrade: string) => {
                      setCurrentGrade({
                        name: chosenGrade,
                        id:
                          gradeList.find((item) => item.name === chosenGrade)
                            ?.id || 0,
                      });
                      setCurrentSection(undefined);
                      setCurrentSubject(undefined);

                      setGradeList(
                        gradeList.map((item) => ({
                          ...item,
                          nonEditableItem: item.name === chosenGrade,
                        }))
                      );
                      setSectionList(
                        school_data?.schoolClassSectionDetails
                          .filter((detail) => detail.className === chosenGrade)
                          .map((detail) => ({
                            name: detail.sectionName,
                            id: detail.sectionId,
                            nonEditableItem: false,
                          })) || []
                      );
                      await interactionEvent({
                        url: '',
                        context: 'select_grade',
                        name: chosenGrade,
                        pathSegments: pathSegments,
                      });
                    }}
                    rootStyles={{ textAlign: 'center' }}
                  />
                  <SelectMenu
                    disabled={!currentGrade}
                    placeholder="Select Section"
                    value={currentSection?.name.toString() || ''}
                    optionList={sectionList}
                    onChange={async (chosenSection: string) => {
                      setCurrentSection({
                        name: chosenSection,
                        id:
                          sectionList.find(
                            (item) => item.name === chosenSection
                          )?.id || 0,
                      });
                      setCurrentSubject(undefined);

                      setSectionList(
                        sectionList.map((item) => ({
                          ...item,
                          nonEditableItem: item.name === chosenSection,
                        }))
                      );
                      const filteredData =
                        school_data?.schoolClassSectionDetails.filter(
                          (detail) =>
                            detail.className === currentGrade?.name &&
                            detail.sectionName === chosenSection
                        );
                      const subjects = filteredData?.flatMap((detail) =>
                        detail.subjects.map((subject) => ({
                          name: subject.subjectName,
                          id: subject.subjectId,
                          nonEditableItem: false,
                        }))
                      );
                      setSubjectList(subjects || []);
                      await interactionEvent({
                        url: '',
                        context: 'select_section',
                        name: chosenSection.toUpperCase(),
                        pathSegments: pathSegments,
                      });
                    }}
                    rootStyles={{ textAlign: 'center' }}
                  />
                  <SelectMenu
                    disabled={!currentSection}
                    placeholder="Select Subject"
                    value={currentSubject?.name.toString() || ''}
                    optionList={subjectList}
                    onChange={async (chosenSubject: string) => {
                      setCurrentSubject({
                        name: chosenSubject,
                        id:
                          subjectList.find(
                            (item) => item.name === chosenSubject
                          )?.id || 0,
                      });
                      setSubjectList(
                        subjectList.map((item) => ({
                          ...item,
                          nonEditableItem: item.name === chosenSubject,
                        }))
                      );
                      await interactionEvent({
                        url: '',
                        context: 'select_subjects',
                        name: chosenSubject.toUpperCase(),
                        pathSegments: pathSegments,
                      });
                    }}
                    rootStyles={{ textAlign: 'center' }}
                  />
                </Box>

                {currentSubject && (
                  <Box
                    sx={{
                      justifyContent: 'center',
                      alignSelf: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <IconWrapper
                      name="plus"
                      size="md"
                      parentFolder="icons"
                      type="png"
                      onClick={() => {
                        handleAdd();
                      }}
                      customSx={{
                        display: { xs: 'none', md: 'flex' },
                        width: { xs: pxToRem(20), md: pxTovW(35) },
                        height: { xs: pxToRem(20), md: pxTovW(35) },
                      }}
                    />
                    <Button
                      sx={styles.AddButton}
                      onClick={() => {
                        handleAdd();
                      }}
                    >
                      <Typography variant="bodyText" color="success.light">
                        + Add
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {!addClass && (
            <Box
              onClick={async () => {
                setAddClass(true);
                await interactionEvent({
                  url: '',
                  context: 'class_subject_data',
                  name: 'ADD_CLASS',
                  pathSegments: pathSegments,
                });
              }}
            >
              <Typography variant="linkText" color={'primary'}>
                +Add Class
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={styles.ButtonContainer}>
        <PrimaryButton
          fullWidth
          onClick={nextHandle}
          disabled={
            code === '' || !school_data || currentClassList.length === 0
          }
        >
          <Typography variant="h3" fontWeight={'bold'} color="success.light">
            NEXT
          </Typography>
        </PrimaryButton>
      </Box>
    </Box>
  );
};
