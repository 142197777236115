import {
  IStyles,
  InputFieldContainer,
  LinkButton,
  Loader,
  OtpInputs,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OTPEnum } from '@protos/user_management/ums.db_pb';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import {
  HOME,
  SELF_REGISTRATION,
  USER_NAME_REGISTRATION,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../auth.events';
import { setOtpInfo, setToastInfo } from '../../reducer/auth.slice';
import { setToastInfo as EmailToast } from '../../../Home/reducer/homeDashboard.slice'
const styles: IStyles = {
  root: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: { xs: 'start', md: 'center' },
    // backgroundColor: 'red',
    // mt: { xs: pxToRem(20), md: pxTovW(20) },
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(5),
    textAlign: { xs: 'left', md: 'center' },
    flexGrow: 1,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(32), md: pxTovW(41) },
  },
  inputContainer: {
    mt: { xs: pxToRem(50), md: pxTovW(60) },
  },
  skip: {
    width: { xs: pxToRem(75), md: pxTovW(140) },
    height: { xs: pxToRem(42), md: pxTovW(82) },
    '& > span': {
      fontSize: pxToRem(16),
      color: '#007CDC',
      // backgroundColor: 'blue',
      padding: '0px',
    },
  },
  forgotAndOtpBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(25), md: pxTovW(22) },
  },

  secondaryButtonBox: {
    width: { xs: '100%', md: pxTovW(517) },
    margin: 'auto',
    mt: { xs: pxToRem(100), md: pxTovW(40) },
  },
};
export const OtpScreen = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const [otp, setotp] = useState('');
  const [seconds, setSeconds] = useState(30);
  const verifyPath = useAppSelector((state) => state.auth.verify_path);

  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const { selectedFunction, setSelectedFunction } = useGlobalContext();

  const backClick = () => {
    if (self_registration_flow) navigate(SELF_REGISTRATION);
    else navigate(verifyPath);
  };
  useEffect(() => {
    setSelectedFunction(() => backClick);
  }, []);
  const {
    user_info,
    otp_info,
    self_registration_flow,
    self_registration_data,
  } = deserify(useAppSelector((state) => state.auth));
  const [helperText, setHelperText] = useState<string>(
    verifyPath === VERIFY_EMAIL
      ? 'Otp sent to your email'
      : 'Otp sent to your phone number'
  );
  const [helperTextVariant, setHelperTextVariant] = useState<
    'success' | 'error'
  >('success');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nextClickHandler = async () => {
    setLoading('loading');
    try {
      const response =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.verifyOTP({
          otp: otp,
          verificationCode: otp_info?.verification_code,
          check: self_registration_flow
            ? self_registration_data?.phone_number
            : verifyPath === VERIFY_EMAIL
              ? user_info?.email
              : user_info?.phoneNumber,
        });

      if (response.status === 200) {
        setLoading('completed');
        if (self_registration_flow) navigate(USER_NAME_REGISTRATION);
        else if (verifyPath === VERIFY_EMAIL) {
          dispatch(
            EmailToast({
              label: ' Email Id verified successfully ',
              variant: 'success',
              open: true,
            })
          );
          navigate(HOME);
        } else {
          dispatch(
            setToastInfo({
              label: ' Phone Number verified successfully ',
              variant: 'success',
              open: true,
            })
          );
          navigate(VERIFY_EMAIL);
        }
        await interactionEvent({
          url: '',
          context: 'navigation_bar',
          name: 'NEXT',
          pathSegments: pathSegments,
        });
      } else {
        console.log(response.message);
        setLoading('error');
        dispatch(
          setToastInfo({
            label: response.message
              ? `${response.message}`
              : `${verifyPath === VERIFY_EMAIL ? 'Email Id' : 'Phone Number'
              } verification failed`,
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error: any) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : `${verifyPath === VERIFY_EMAIL ? 'Email Id' : 'Phone Number'
            } verification failed`,
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    }
  };
  const sendOtp = async () => {
    setSeconds(30);
    setLoading('loading');
    try {
      if (verifyPath === VERIFY_EMAIL) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getEmailOTP({
            email: user_info?.email,
            otpType: OTPEnum.TEACHER_VERIFICATION,
          });
        if (response.status === 200) {
          setLoading('completed');
          // dispatch(
          //   setToastInfo({
          //     label: 'otp sent to your email',
          //     variant: 'success',
          //     open: true,
          //   })
          // );
          setHelperText('Otp resent to your email');
          setHelperTextVariant('success');
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.TEACHER_VERIFICATION,
            })
          );
        } else {
          setLoading('error');
          setHelperText('Otp resent to your email failed');
          setHelperTextVariant('error');
          // dispatch(
          //   setToastInfo({
          //     label: 'invalid email id',
          //     variant: 'error',
          //     open: true,
          //   })
          // );
        }
      } else if (verifyPath === VERIFY_PHONE) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
            phoneNumber: user_info?.phoneNumber,
            otpType: OTPEnum.TEACHER_VERIFICATION,
          });
        if (response.status === 200) {
          setLoading('completed');
          // dispatch(
          //   setToastInfo({
          //     label: 'otp sent to your phone',
          //     variant: 'success',
          //     open: true,
          //   })
          // );
          setHelperText('Otp resent to your phone ');
          setHelperTextVariant('success');
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.TEACHER_VERIFICATION,
            })
          );
        } else {
          console.log(response.message);
          setLoading('error');
          setHelperText('Otp resent to your phone failed');
          setHelperTextVariant('error');
          // dispatch(
          //   setToastInfo({
          //     label: 'invalid Phone Number',
          //     variant: 'error',
          //     open: true,
          //   })
          // );
        }
      } else if (self_registration_flow) {
        const response =
          await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
            phoneNumber: self_registration_data?.phone_number,
            otpType: OTPEnum.TEACHER_SELF_REGISTRATION,
          });
        if (response.status === 200) {
          setLoading('completed');
          setHelperText('Otp resent to your phone ');
          setHelperTextVariant('success');
          dispatch(
            setOtpInfo({
              verification_code: response.verificationCode,
              otp_type: OTPEnum.TEACHER_SELF_REGISTRATION,
            })
          );
        } else {
          console.log(response.message);
          setLoading('error');
          setHelperText('Otp resent to your phone failed');
          setHelperTextVariant('error');
        }
      }
    } catch (error) {
      setLoading('error');
      console.log(error);
    }
  };
  const handleNavigation = () => {
    if (verifyPath === VERIFY_PHONE) {
      navigate(VERIFY_EMAIL);
    } else {
      navigate(HOME);
    }
  };
  return loading === 'loading' ? (
    <Loader />
  ) : (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          ...styles.root,
          width: { xs: '100%', md: '50%' },
          display: 'flex',
        }}
      >
        <Box>
          {!self_registration_flow && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                // position: { md: 'fixed' },
                right: { md: pxTovW(100) },
                // backgroundColor: 'blue',
              }}
            >
              <LinkButton
                sx={{ ...styles.skip, justifySelf: 'right' }}
                onClick={handleNavigation}
              >
                Skip
              </LinkButton>
            </Box>
          )}
          <Box sx={styles.mainContainer}>
            <Box
              sx={{
                display: 'flex',
                // backgroundColor: 'red',
                alignItems: 'flex-end',
                justifyContent: { xs: 'space-between', md: 'center' },
              }}
            >
              <Box sx={styles.heading}>
                <Typography variant="h1" fontWeight="bold">
                  Connect Your
                </Typography>
                <Typography variant="h1" color="primary" fontWeight="bold">
                  {verifyPath === VERIFY_EMAIL ? 'Email ID' : 'Phone Number'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <InputFieldContainer
                helperTextvariant={helperTextVariant}
                topLabel={
                  verifyPath === VERIFY_EMAIL
                    ? 'Enter Email ID'
                    : 'Enter 10 Digit Phone Number'
                }
                value={
                  (self_registration_flow
                    ? self_registration_data?.phone_number
                    : verifyPath === VERIFY_EMAIL
                      ? user_info?.email
                      : user_info?.phoneNumber) || ''
                }
                // onChange={handleCredentialChange}
                helperText={helperText}
                nonEditable={true}
                editFunction={async () => {
                  if (self_registration_flow) navigate(SELF_REGISTRATION);
                  else navigate(verifyPath);
                  await interactionEvent({
                    url: '',
                    context:
                      verifyPath === VERIFY_EMAIL ? 'email' : 'Phone_number',
                    name: 'EDIT',
                    pathSegments: pathSegments,
                  });
                }}
              />
            </Box>
          </Box>

          <Box sx={styles.forgotAndOtpBox}>
            <OtpInputs
              enteredOtp={otp}
              otpWrong={false}
              setEnteredOtp={setotp}
              otpLength={5}
              onPressEnter={nextClickHandler}
            />
            {seconds === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  alignSelf: 'start',
                }}
              >
                <LinkButton onClick={sendOtp}>Resend otp</LinkButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignSelf: { xs: 'center', md: 'start' },
                  gap: { xs: pxToRem(4), md: pxTovW(4) },
                }}
              >
                <Typography variant="cardText" sx={{ color: '#007CDC' }}>
                  Resend otp in {'  '}
                </Typography>
                <Typography
                  sx={{ color: '#0AA34F' }}
                  variant="bodyText"
                  fontWeight="bold"
                >
                  <Timer seconds={seconds} setSeconds={setSeconds} />
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={styles.secondaryButtonBox}>
            <PrimaryButton fullWidth onClick={nextClickHandler}>
              <Typography variant="h1" color="success.light">
                Next
              </Typography>
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
interface TimerProps {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
}
const Timer = (props: TimerProps) => {
  const { seconds, setSeconds } = props;
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <Typography sx={{ color: '#0AA34F' }} variant="bodyText" fontWeight="bold">
      {seconds}
    </Typography>
  );
};
