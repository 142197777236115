import { Box, Typography, useMediaQuery } from '@mui/material';

import {
  IStyles,
  ImageWrapper,
  ScoreProgressBar,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
const styles: IStyles = {
  root: {
    width: { xs: '90vw', md: '90%', lg: pxTovW(360) },
    height: { xs: pxToRem(56), md: pxTovW(80) },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // backgroundColor: 'red',
  },
  headImage: {
    width: { xs: pxToRem(62), lg: pxTovW(100) },
    height: { xs: pxToRem(62), lg: pxTovW(100) },
    borderRadius: '50%',
  },
};
interface IProps {
  path: string;
  className: string;
  studentName: string;
  score: number;
}

export const StudentScoreCard = (props: IProps) => {
  const { score, studentName, className, path } = props;
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const { isIpadOnly } = useGlobalContext();
  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(20), md: pxTovW(19) },
          width: '100%',
        }}
      >
        <Box>
          <ImageWrapper
            path={path}
            name="student1"
            type="png"
            parentFolder="tempAssets"
            styles={styles.headImage}
          />
        </Box>
        <Box
          sx={{
            height: { xs: pxToRem(62), md: pxTovW(100) },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: { xs: pxToRem(10), md: pxTovW(19) },
            width: { xs: '80vw', md: '100%', lg: '80%' },
          }}
        >
          <Typography variant={isIpadOnly ? 'h2' : 'h3'} fontWeight="bold">
            {studentName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { md: 'column' },
              gap: { xs: pxToRem(10), md: pxTovW(10) },
            }}
          >
            <Typography
              variant={isIpadOnly ? 'h3' : 'h4'}
              fontWeight="bold"
              sx={{ color: '#007CDC', whiteSpace: 'nowrap' }}
            >
              {className}
            </Typography>
            <ScoreProgressBar
              variant={isIpad ? 'lg' : 'md'}
              score={Math.round(score)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
