import { Timestamp } from '@bufbuild/protobuf';
import {
  ChipBadge,
  ContentLockPopup,
  IStyles,
  ImageWrapper,
  InfoDisplayPanel,
  InfoPopup,
  LockToggleButton,
  PrimaryButton,
  cloneObject,
  deserify,
  getEnumKeyByEnumValue,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  useFeatureEnabled,
} from '@geneo2-web/shared-ui';
import { Box, Pagination, Typography } from '@mui/material';
import {
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { TeacherLessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import { HOME, TEACHING_FLOW } from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import { setBaseStationDetails } from '../../Teach/reducer/connectedClass.slice';
import {
  setLessonPlanRedirectionPath,
  setSelectedLessonInfo,
  setSessionMode,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../../Teach/reducer/teach.slice';
import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import {
  ContentLockStatusType,
  Feature,
  LessonNodeLock,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { setToastInfo } from '../reducer/homeDashboard.slice';

const styles: IStyles = {
  root: {
    paddingX: { xs: pxToRem(0), md: pxToRem(20), lg: pxTovW(240) },
    pb: { xs: pxToRem(20), md: pxTovW(10) },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(10) },
    marginY: { xs: pxToRem(21), md: pxTovW(16) },
    marginLeft: { xs: pxToRem(30), md: 0 },
    '&  .MuiChip-label': {
      fontSize: { xs: pxToRem(16), md: pxTovW(20) },
      fontWeight: 'medium',
    },
  },
  contentBox: {
    boxSizing: 'border-box',
    p: { xs: pxToRem(20), md: pxTovW(20) },
    width: { xs: '100%', md: '100%' },
    marginX: 'auto',
    height: { xs: 'max-content' },
    overflow: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    background: {
      xs: 'white',
      md: 'linear-gradient(to bottom, #FDFBFB 20%,#EAF4FC 20% 80%)',
    },
  },
  grid: {
    height: '100%',
    width: { xs: '100%', md: '100%' },
    justifyContent: { xs: 'center', md: 'center' },
    display: 'flex',
    flexWrap: 'wrap',
    gap: { xs: pxToRem(25), md: pxTovW(0) },
  },
  boxItem: {
    justifyContent: 'center',
    marginX: { md: pxTovW(20) },
  },
  pagination: {
    width: 'max-content',
    margin: 'auto',
    mt: { xs: pxToRem(20), md: pxTovW(30) },
  },
};

interface IlockedPopupData {
  lessonId?: string;
  image: string;
  noofTopics?: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockedStatus: ContentLockStatusType;
  subjectId?: number;
  sectionId?: number;
}

export const SeeAllLessonPlanList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getBaseStationConnectionStatus, authenticateAnonymously } =
    useConnectedClassContext();
  const { isIpadOnly } = useGlobalContext();
  const { setSelectedFunction } = useGlobalContext();
  const teacher_profile_id = getLocalStorage('userId');
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const [selectedLP, setSelectedLP] = useState<TeacherLessonInfo | undefined>();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [completedResourceId, setCompletedResourceId] = useState<string[]>([]);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const [lessonPlanList, setLessonPlanList] = useState<TeacherLessonInfo[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLesson, setTotalLesson] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const {
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  const teacher_id = getLocalStorage('userId');
  const backButtonClick = async () => {
    navigate(HOME);
  };

  const [contentLockData, setContentLockData] = useState<LessonNodeLock[]>();
  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  useEffect(() => {
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  useEffect(() => {
    getTeacherLessonList(teacher_profile_id);
  }, [class_subject_info, pageNumber]);

  const getTeacherLessonList = async (teacherId: string) => {
    try {
      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getTeacherLessonList(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            sectionId: class_subject_info?.sectionId,
            pageNumber: pageNumber,
            itemsPerPage: ITEMS_PER_PAGE,
          }
        );
      const data = response.data;
      if (data) {
        // console.log('getTeacherLessonList:', data.lessonList);
        const lpList = data.lessonList.sort(
          (a, b) =>
            (b.lastSessionTime?.toDate().getTime() ?? 0) -
            (a.lastSessionTime?.toDate().getTime() ?? 0)
        );
        const lessonPlanIds = lpList?.map((lesson) => lesson.lessonId);
        if (isContentLockFeature && teacherId) {
          await getLessonLockedStatus(teacherId, lessonPlanIds);
        }
        setLessonPlanList(lpList);
        setTotalPages(data.paginationData?.totalPages || 1);
        setTotalLesson(data.paginationData?.totalLessons || lpList.length || 0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const count = Math.ceil(lessonPlanList.length / ITEMS_PER_PAGE);
  // const _DATA = UsePagination(lessonPlanList, ITEMS_PER_PAGE);

  const handleChange = async (e: React.ChangeEvent<unknown>, p: number) => {
    // _DATA.jump(p);
    setPageNumber(p);
    if (pageNumber !== p) {
      await interactionEvent({
        url: 'Teacher_See_All_Lesson_Plan',
        context: 'navigation_bar',
        name: 'PAGE_CHANGE',
      });
    }
  };

  const iconDetails = (lesson: TeacherLessonInfo) => {
    const time = `${
      lesson.estimatedTimeInMin
        ? lesson.estimatedTimeInMin
        : 8 * lesson.resourceIds.length
    } Min`;
    // ${lesson.estimatedTimeInMin > 1 ? 's' : ''};
    return [
      { iconName: 'clock', text: time },
      { iconName: 'questions', text: `${lesson.resourceIds.length} resources` },
    ];
  };
  const lessonPlanCardClickHandler = async (
    lessonInfo: TeacherLessonInfo | undefined,
    pathname: string,
    sessionMode: SessionModeEnum
  ) => {
    if (
      lessonInfo &&
      lessonInfo?.teachClassSubjects?.classId &&
      lessonInfo?.teachClassSubjects?.sectionId
    ) {
      dispatch(setSessionMode(sessionMode));
      if (sessionMode === SessionModeEnum.SESSION_MODE_TEACH) {
        const stationDetails = await getBaseStationConnectionStatus({
          teacherId: teacher_id,
          classId: lessonInfo?.teachClassSubjects?.classId,
          sectionId: lessonInfo.teachClassSubjects?.sectionId,
        });
        if (stationDetails) {
          //deciding the connected classroom flow
          dispatch(setBaseStationDetails(stationDetails));
          authenticateAnonymously();
        }
      }
      dispatch(setSelectedLessonInfo(lessonInfo));
      dispatch(setLessonPlanRedirectionPath(pathname));
      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
          {
            teacherId: BigInt(teacher_id),
            lessonId: lessonInfo.lessonId,
          }
        );
      setCompletedResourceId(response.data?.completedResourceIds || []);
      const newSessionInfo =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.createTeacherLessonSession(
          {
            teacherId: BigInt(teacher_id),
            lessonId: lessonInfo.lessonId,
            schoolId: user_info?.schoolDetails[0]?.schoolId,
            academicYear: 0,
            classId: class_subject_info?.classId,
            section: lessonInfo?.teachClassSubjects?.section,
            subject:
              lessonInfo.teachClassSubjects?.subjectId &&
              !isNaN(Number(lessonInfo.teachClassSubjects?.subjectId))
                ? getTeacherSubjectEnum(
                    Number(lessonInfo.teachClassSubjects?.subjectId),
                    user_info?.teachClassSubjects
                  )
                : undefined,
            // teacherLessonSessionId: ,
            sessionResourceIds: completedResourceId || [],
            startTime: Timestamp.fromDate(new Date()),
            sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            sessionMode: sessionMode,
          }
        );
      if (newSessionInfo.teacherLessonSessionId) {
        dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      }
      navigate({
        pathname: `${TEACHING_FLOW}/${lessonInfo.teachClassSubjects?.subjectId}/${lessonInfo.parentModuleId}/${lessonInfo.moduleId}/${lessonInfo.lessonId}`,
        search: `?${createSearchParams({
          classId: lessonInfo.teachClassSubjects?.classId?.toString() || '',
          section: lessonInfo.teachClassSubjects?.section || '',
          lessonSessionId: newSessionInfo.teacherLessonSessionId.toString(),
          sectionId: lessonInfo.teachClassSubjects?.sectionId.toString() || '',
        })}`,
      });
    }
  };

  const getLessonLockedStatus = async (
    teacherId: string,
    lessonPlanIds: string[]
  ) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
          {
            personId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            lessonIds: lessonPlanIds,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          }
        );
      if (response) {
        setContentLockData(response.lessonInfo);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  const handleOpenPopup = (
    ev?: React.MouseEvent,
    lesson?: TeacherLessonInfo
  ) => {
    ev?.stopPropagation();
    if (lesson) {
      setLockedPopData({
        image: lesson?.posterImageUrl
          ? getMediaBasePath(lesson?.posterImageUrl, 'processedMediaBucket')
          : '',
        noofTopics: lesson?.resourceIds
          ? lesson?.resourceIds?.length.toString()
          : '',
        title: lesson?.title || '',
        moduleId: Number(lesson?.moduleId),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        lessonId: lesson?.lessonId || '',
        lockedStatus:
          getLessonContentLockInfo(lesson.lessonId, contentLockData)
            ?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
        subjectId: lesson.teachClassSubjects?.subjectId,
        sectionId: lesson.teachClassSubjects?.sectionId,
      });
    }
    setIsContentLockPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsContentLockPopupOpen(false);
    setLockedPopData(null);
  };
  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = cloneObject(lockedPopupData);
    if (val && currentData) {
      currentData.lockedStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      // Ensure essential values are present before making the API call
      if (!lockedPopupData?.moduleId) {
        console.log('error');
        throw new Error('Module ID must be provided');
      }
      // Build the moduleInfo object dynamically, including resourceId only if it exists
      const moduleInfo = {
        moduleId: lockedPopupData?.moduleId,
        category: lockedPopupData?.category,
        lockStatus: lockedPopupData.lockedStatus,
        ...(lockedPopupData.lessonId && {
          lessonId: String(lockedPopupData.lessonId),
        }),
      };
      // Perform the API call
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_profile_id),
            subjectId: lockedPopupData?.subjectId,
            schoolClassSectionId: lockedPopupData?.sectionId,
            moduleInfo,
          }
        );

      // Check if the response is valid and handle accordingly
      if (response) {
        await getTeacherLessonList(teacher_profile_id);
        handleClosePopup(); // Close the popup upon successful submission
        setLockedPopData(null); // Clear the lockedPopupData state
        // Refresh lesson lock status
      } else {
        dispatch(
          setToastInfo({
            label: 'Something went wrong',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      // Enhanced error handling
      console.error('Submission error:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleBox}>
        <Typography variant="h1" fontWeight="bold">
          Lesson Plans
        </Typography>
        <ChipBadge label={totalLesson} color="primary" size="small" />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          columnGap: { xs: pxToRem(20), md: pxTovW(100), lg: pxTovW(40) },
          rowGap: { xs: pxToRem(20), md: pxTovW(40) },
          background: {
            md: 'linear-gradient(to bottom, white 8%, #EAF4FC 8%, #EAF4FC 92%, white 92%)',
          },
        }}
      >
        {lessonPlanList.map((lesson, index) => (
          // <Grid item xs={12} sm={6} md={4} lg={3} sx={{}}>
          <Box component={'div'}>
            <InfoDisplayPanel
              defaultImage="lessonplan-v1"
              variant="large"
              blueSubText={`${lesson.teachClassSubjects?.class}${lesson.teachClassSubjects?.section} - ${lesson.teachClassSubjects?.subject}`}
              mainHeading={`${lesson.title}`}
              image={getMediaBasePath(
                lesson.posterImageUrl,
                'processedMediaBucket'
              )}
              cardClickHandler={() => {
                setSelectedLP(lesson);
                setOpenPopUp(true);
              }}
              rootStyle={{
                backgroundColor: 'common.white',
                width: {
                  md: isIpadOnly ? pxTovW(350) : pxTovW(277),
                },
                height: {
                  md: isIpadOnly ? pxTovW(420) : pxTovW(314),
                },
              }}
              iconDetails={iconDetails(lesson)}
              isLocked={
                isContentLockFeature &&
                contentLockData &&
                getLessonContentLockInfo(lesson.lessonId, contentLockData)
                  ?.lockStatus ===
                  ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                  ? true
                  : false
              }
              status={<InfoDisplayPanelStatus lesson={lesson} />}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: { xs: pxToRem(20), md: pxTovW(20) },
                  right: { xs: pxToRem(20), md: pxTovW(20) },
                }}
              >
                {isContentLockFeature && (
                  <LockToggleButton
                    isLocked={
                      contentLockData
                        ? getLessonContentLockInfo(
                            lesson.lessonId,
                            contentLockData
                          )?.lockStatus
                        : false
                    }
                    handleOpenPopup={(ev) => {
                      handleOpenPopup(ev, lesson);
                    }}
                  />
                )}
              </Box>
            </InfoDisplayPanel>
          </Box>
        ))}
        <InfoPopup
          iconName="homework2"
          popupText={[
            <Typography variant="h2">Do you want to select?</Typography>,
            <Box
              sx={{
                marginTop: { xs: pxToRem(41), lg: pxTovW(64) },
                display: 'flex',
                gap: { xs: pxToRem(20), lg: pxTovW(20) },
              }}
            >
              <PrimaryButton
                sx={{
                  width: { xs: pxToRem(140), lg: pxTovW(226) },
                  height: { xs: pxToRem(53), lg: pxTovW(85) },
                }}
                onClick={async () => {
                  lessonPlanCardClickHandler(
                    selectedLP,
                    location.pathname,
                    SessionModeEnum.SESSION_MODE_TEACH
                  );
                  await interactionEvent({
                    url: 'Teacher_See_All_Lesson_Plan',
                    context: 'ready_lesson_plan',
                    name: 'TEACH',
                  });
                }}
              >
                <Typography variant="h2" color="white">
                  TEACH
                </Typography>
              </PrimaryButton>
              <PrimaryButton
                sx={{
                  width: { xs: pxToRem(140), lg: pxTovW(226) },
                  height: { xs: pxToRem(53), lg: pxTovW(85) },
                  backgroundColor: '#007CDC',
                }}
                onClick={async () => {
                  lessonPlanCardClickHandler(
                    selectedLP,
                    location.pathname,
                    SessionModeEnum.SESSION_MODE_PREPARE_LESSON
                  );
                  await interactionEvent({
                    url: 'Teacher_See_All_Lesson_Plan',
                    context: 'ready_lesson_plan',
                    name: 'PREPARE',
                  });
                }}
              >
                <Typography variant="h2" color="white">
                  PREPARE
                </Typography>
              </PrimaryButton>
            </Box>,
          ]}
          background="#007CDC"
          handleClose={() => {
            setSelectedLP(undefined);
            setOpenPopUp(false);
          }}
          open={openPopUp}
        />
        {lockedPopupData && (
          <ContentLockPopup
            open={isContentLockPopupOpen}
            onClose={handleClosePopup}
            onSubmit={(ev?: React.MouseEvent) =>
              lockedPopupData && handleSubmit(ev)
            }
            selectedValue={lockedPopupData.lockedStatus}
            onRadioChange={handleLockedRadioChange}
            lockDataObj={lockedPopupData}
          />
        )}
      </Box>

      {lessonPlanList.length > 0 && (
        <Pagination
          page={pageNumber}
          count={totalPages}
          color="secondary"
          onChange={handleChange}
          sx={styles.pagination}
        />
      )}
    </Box>
  );
};

interface InfoDisplayPanelStatus {
  lesson: TeacherLessonInfo;
}
const InfoDisplayPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');
  const editTime = getHumanReadableTimestampString(lesson.modifiedOn)?.split(
    ' '
  );

  if (!lesson.teacherName) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: { xs: pxToRem(6), md: pxTovW(6) },
        paddingTop: { xs: pxToRem(5), md: pxTovW(5) },
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
      }}
    >
      {/* //! If Geneo created the Lesson it wont show Edited only Taught */}
      <ImageWrapper
        name={
          lesson.lastSessionTime
            ? 'book-black'
            : lesson.teacherName
            ? 'edit-pencil-black'
            : ''
        }
        type="png"
        parentFolder="icons"
        styles={{
          width: { xs: pxToRem(12), md: pxTovW(12) },
          height: { xs: pxToRem(12), md: pxTovW(12) },
        }}
      />
      {lesson.lastSessionTime ? (
        <Typography variant="smallText">
          {taughtTime && Number(taughtTime[0]) < 2
            ? 'Taught Today'
            : 'Taught ' + taughtTime?.join(' ')}
        </Typography>
      ) : (
        lesson.teacherName && (
          <Typography variant="smallText">
            {editTime && Number(editTime[0]) < 2
              ? 'Recently Edited'
              : 'Edited ' + editTime?.join(' ')}
          </Typography>
        )
      )}
    </Box>
  );
};

const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: LessonNodeLock[]
) => {
  return contentLockData?.find((val) => val.lessonId === lessonId);
};
